"use client";
import React, { Suspense, useEffect, useState } from "react";
import type { PaginationProps } from "@britishredcross/component-library/src/components/molecules/pagination/pagination.types";
import { PageSkeleton } from "@britishredcross/component-library/src/components/atoms/page-skeleton/page-skeleton";
import { Pagination } from "@britishredcross/component-library/src/components/molecules/pagination/pagination";
import { SearchForm } from "@britishredcross/component-library/src/components/molecules/search-form/search-form";
import { VadSearchResults } from "@britishredcross/component-library/src/components/organisms/vad-search-results/vad-search-results";
import { useRouter, useSearchParams, usePathname } from "next/navigation";
import type { VadSearchModel } from "@britishredcross/kontent-integration/dist/esm/models";
import { useStore } from "../../hooks/use-store";

function VadSearch(props: VadSearchModel) {
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathName = usePathname();

	const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm"));

	const { vadApi } = useStore();

	const [page, setPage] = useState(1);

	// TODO: add this to a useDisplayHook or something later
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [perPage, _setPerPage] = useState(5);
	// Filter passed in via query string
	const [filter, setFilter] = useState(
		searchParams.get("filter") ??
			props.elements.filters.linkedItems[0]?.elements.default.linkedItems[0]
				?.elements.value.value!
	);

	useEffect(() => {
		if (searchTerm) {
			vadApi.search(searchTerm, filter !== "" ? filter : undefined);
		}
	}, [searchTerm, pathName, filter]);

	const getPaginationProps = (): PaginationProps => {
		const totalResults = vadApi.searchResults.length;
		const hasRemainder = totalResults % perPage;

		let totalPages = parseInt(
			(totalResults / perPage).toString().split(".")[0] || "",
			10
		);

		if (hasRemainder) {
			totalPages += 1;
		}
		const paginationProps = {
			currentPage: page,
			totalPages,
			onClick: (pageNumber) => {
				setPage(pageNumber);
			},
		} as PaginationProps;

		return paginationProps;
	};

	const onSearch = (
		searchTerm: string,
		filter: { name: string; value: string }
	) => {
		setFilter(filter.value);
		setSearchTerm(searchTerm);
		const filterQuery = filter ? `&filter=${filter.value}` : "";
		router.replace(`/search?searchTerm=${searchTerm}${filterQuery}`, {
			scroll: false,
		});
	};

	return (
		<>
			<SearchForm
				{...{
					title: "Search our records",
					onSearch: (searchTerm, filter) => {
						onSearch(searchTerm as string, {
							name: filter?.[0]?.name!,
							value: filter?.[0]?.value!,
						});
					},
					searchValue: searchTerm ?? undefined,
					buttonText: props.elements.button_text.value,
					inputLabel: props.elements.input_label.value,
					filterList: props.elements.filters.linkedItems[0]?.elements.filters
						? [
								{
									name: "Filter",
									label: "Filter",

									options:
										props.elements.filters.linkedItems[0]?.elements.filters.linkedItems.map(
											(filter) => ({
												title: filter.elements.key.value,
												value: filter.elements.value.value,
											})
										),
									selectedValue: filter,
								},
							]
						: undefined,
				}}
			/>
			{vadApi.resultsLoading ? (
				<div className="mx-auto my-10 max-content">
					<PageSkeleton />
				</div>
			) : (
				<>
					{vadApi.searchResults.length ? (
						<VadSearchResults
							totalRecords={vadApi.searchResults.length}
							vadRecords={vadApi.searchResults
								.slice((page - 1) * perPage, page * perPage)
								.map((result) => ({
									link: `/record?rowKey=${result.RowKey}`,
									heading: `${result.christian_names} ${result.surname}`,
									county: result.county,
									dateOfEngagement: result.date_of_engagement,
									endOfEngagement: result.date_of_termination,
								}))}
						/>
					) : null}
					{vadApi.searchResults.length ? (
						<Pagination {...getPaginationProps()} />
					) : null}
				</>
			)}
		</>
	);
}

export default function VadSearchComponent(props: VadSearchModel) {
	return (
		<Suspense>
			<VadSearch {...props} />
		</Suspense>
	);
}
