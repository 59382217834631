"use client";
import React from "react";
import { Image } from "../../atoms/image/image";
import { Link } from "../../atoms/link/link";
import { LinkType } from "../../atoms/link/link.types";
import { useDeviceInfo } from "../../../hooks/use-device-info";
import { type MapStaticProps } from "./map-static.types";

export function MapStatic({
	address,
	mapWidth,
	mapHeight,
}: MapStaticProps): JSX.Element {
	const encodedAddress = encodeURIComponent(address);

	const deviceInfo = useDeviceInfo();

	let imageSize = "1080x608"; // default size lg
	if (deviceInfo.width >= 1024) {
		imageSize = "1080x608"; // lg 16:9
	} else if (deviceInfo.width >= 768) {
		imageSize = "1024x768"; // md 4:3
	} else if (deviceInfo.width >= 450) {
		imageSize = "768x512"; // sm 3:2
	} else if (deviceInfo.width < 450) {
		imageSize = "450x300"; // xs 3:2
	}

	const [imageWidth, imageHeight] = imageSize
		.split("x")
		.map((dim) => parseInt(dim, 10));

	// Use the props mapWidth and mapHeight if provided, otherwise fallback to calculated values
	const finalMapWidth = mapWidth ? mapWidth.toString() : imageWidth?.toString();
	const finalMapHeight = mapHeight
		? mapHeight.toString()
		: imageHeight?.toString();

	return (
		<div className="brc-map-static w-full max-content mx-auto mb-8 px-4 md:mb-12 lg:mb-16 lg:px-0">
			<div className="prose-img:object-cover prose-img:aspect-[3/2] md:prose-img:aspect-[4/3] lg:prose-img:aspect-video">
				<Image
					src={`https://maps.googleapis.com/maps/api/staticmap?markers=color:0xD0021B%7C${encodedAddress}&zoom=15&scale=2&size=540x540&maptype=roadmap&key=${process.env.NEXT_PUBLIC_GOOGLE_STATIC_MAPS_API_KEY}&`}
					alt={`address map of ${address}`}
					width={finalMapWidth}
					height={finalMapHeight}
					loading="lazy"
				/>
			</div>
			<div className="bg-grey-10 p-3 md:p-4">
				<Link
					destination={`https://www.google.com/maps/place/${encodedAddress}`}
					linkType={LinkType.Default}
					target="_blank"
				>
					View on Google Maps (opens in a new window)
				</Link>
			</div>
		</div>
	);
}
