import React, { forwardRef } from "react";
import { PlainText } from "../plain-text/plain-text";
import { PlainTextSize } from "../plain-text/plain-text.types";
import { type TextInputProps, FieldType } from "./text-input.types";
import { FieldClasses } from "./text-input.style";

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	({ label, fieldType, required, disabled, error, ...rest }, ref) => {
		const classes = FieldClasses({ fieldType, label });
		const labelCleaned = label.replace(/\s/g, "");

		switch (fieldType) {
			case FieldType.Text:
				return (
					<div>
						<label htmlFor={labelCleaned} className="block text-sm">
							{label} {required ? "*" : null}
						</label>
						<input
							className={` ${classes}  ${
								disabled
									? "bg-grey-20 border-grey-30 hover:border-b cursor-not-allowed"
									: ""
							} ${error ? "border-red-primary" : ""} `}
							disabled={disabled}
							id={labelCleaned}
							name={labelCleaned}
							ref={ref}
							required={required}
							type={fieldType}
							{...rest}
						/>
						{error ? (
							<div className="mt-1 prose-p:text-xs prose-p:text-red-primary">
								<PlainText size={PlainTextSize.SmallPrint}>
									This field is required.
								</PlainText>
							</div>
						) : null}
					</div>
				);
		}
	}
);
