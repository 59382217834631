"use client";
import React, { useRef } from "react";
import { Image as ImageBrc } from "@britishredcross/component-library/src/components/atoms/image/image";
import {
	IMAGE_CONTEXT_HOME_HERO,
	IMAGE_CONTEXT_CARD,
	IMAGE_CONTEXT_MULTICOLUMN,
	IMAGE_CONTEXT_STANDALONE,
	useResizeImage,
} from "@/hooks/use-resize-image";

interface ImageSource {
	description: string;
	url: string;
}

interface ImageElement {
	value: string;
}

interface ImageElements {
	caption: ImageElement;
	credit: ImageElement;
	source: {
		value: ImageSource[];
	};
}

export interface ImageProps {
	elements: ImageElements;
}

export type ImageContext =
	| typeof IMAGE_CONTEXT_STANDALONE
	| typeof IMAGE_CONTEXT_MULTICOLUMN
	| typeof IMAGE_CONTEXT_CARD
	| typeof IMAGE_CONTEXT_HOME_HERO;

export const getImageContext = (
	imageRef: React.MutableRefObject<HTMLImageElement | null>
):
	| typeof IMAGE_CONTEXT_STANDALONE
	| typeof IMAGE_CONTEXT_MULTICOLUMN
	| typeof IMAGE_CONTEXT_CARD
	| typeof IMAGE_CONTEXT_HOME_HERO => {
	const isMulticolumn = Boolean(
		imageRef.current?.closest(".brc-column-layout")
	);
	if (isMulticolumn) return IMAGE_CONTEXT_MULTICOLUMN;
	if (imageRef.current?.closest(".brc-hero-homepage"))
		return IMAGE_CONTEXT_HOME_HERO;
	const isCard = Boolean(imageRef.current?.closest(".group"));
	if (isCard) return IMAGE_CONTEXT_CARD;
	return IMAGE_CONTEXT_STANDALONE;
};

function Image(props: ImageProps): JSX.Element | null {
	const imageUrl = props.elements.source.value[0]?.url ?? "";
	const imageDescription = props.elements.source.value[0]?.description ?? "";
	const imageRef = useRef<HTMLImageElement>(null);
	const imageContext = getImageContext(imageRef);

	const resizedImage = useResizeImage(imageUrl, imageContext);

	return imageUrl ? (
		<div ref={imageRef as React.MutableRefObject<HTMLDivElement | null>}>
			<ImageBrc
				alt={imageDescription}
				caption={props.elements.caption.value}
				context={imageContext}
				credit={props.elements.credit.value}
				height={resizedImage.height}
				src={resizedImage.url}
				width={resizedImage.width}
			/>
		</div>
	) : null;
}

export default Image;
