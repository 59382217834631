/**
 * Generated by '@kontent-ai/model-generator@7.4.0'
 */
export const taxonomies = {
	/**
	 * Language
	 */
	language: {
		codename: "language",
		id: "ad9ec0b8-0103-4b4e-9a10-636192990e30",
		externalId: undefined,
		name: "Language",
		terms: {
			arabic: {
				codename: "arabic",
				id: "049ffa32-cef7-4a31-9307-3168750e13a0",
				externalId: undefined,
				name: "Arabic",
				terms: {},
			},
			welsh: {
				codename: "welsh",
				id: "3ab5319b-a189-45a8-841f-8afa66c39736",
				externalId: undefined,
				name: "Welsh",
				terms: {},
			},
		},
	},

	/**
	 * Review date
	 */
	review_date: {
		codename: "review_date",
		id: "6dd8abcd-b719-4823-af9e-87aaad06f55f",
		externalId: undefined,
		name: "Review date",
		terms: {
			n2024_08: {
				codename: "n2024_08",
				id: "7f4c3bc5-f145-4a4f-83c1-f8191c2b7246",
				externalId: undefined,
				name: "2024-08",
				terms: {},
			},
			n2024_09: {
				codename: "n2024_09",
				id: "222aea31-a6d2-42e5-850c-2c9306a9f01a",
				externalId: undefined,
				name: "2024-09",
				terms: {},
			},
			n2024_10: {
				codename: "n2024_10",
				id: "c6636a33-e874-40f6-9af0-0b79db993ef3",
				externalId: undefined,
				name: "2024-10",
				terms: {},
			},
			n2024_11: {
				codename: "n2024_11",
				id: "1309833d-ab39-4737-bf8b-270ba675240a",
				externalId: undefined,
				name: "2024-11",
				terms: {},
			},
			n2024_12: {
				codename: "n2024_12",
				id: "edb34cd2-5121-45df-99c3-4eb4d6e932a2",
				externalId: undefined,
				name: "2024-12",
				terms: {},
			},
			n2025_01: {
				codename: "n2025_01",
				id: "47f425e4-466e-4b74-9832-119d2fed760b",
				externalId: undefined,
				name: "2025-01",
				terms: {},
			},
			n2025_02: {
				codename: "n2025_02",
				id: "4d874232-b0db-4f82-92c3-154ef9d8ed66",
				externalId: undefined,
				name: "2025-02",
				terms: {},
			},
			n2025_03: {
				codename: "n2025_03",
				id: "2a1c6eb4-9e6a-4f2a-b87c-b61827a4775d",
				externalId: undefined,
				name: "2025-03",
				terms: {},
			},
			n2025_04: {
				codename: "n2025_04",
				id: "9014c8c4-bad1-4af0-9af3-fe5f1398df35",
				externalId: undefined,
				name: "2025-04",
				terms: {},
			},
			n2025_05: {
				codename: "n2025_05",
				id: "2bd2b9f4-c4f4-4d67-89a0-fbe484a0fdbe",
				externalId: undefined,
				name: "2025-05",
				terms: {},
			},
			n2025_06: {
				codename: "n2025_06",
				id: "f750d219-6ec9-4166-bcf0-a3804c5792ca",
				externalId: undefined,
				name: "2025-06",
				terms: {},
			},
			n2025_07: {
				codename: "n2025_07",
				id: "8233309d-91c5-4c6f-a421-4bde4cd1443a",
				externalId: undefined,
				name: "2025-07",
				terms: {},
			},
			n2025_08: {
				codename: "n2025_08",
				id: "98c61c1f-5c51-4857-942c-8ae2afffa88b",
				externalId: undefined,
				name: "2025-08",
				terms: {},
			},
			n2025_09: {
				codename: "n2025_09",
				id: "ade74c32-6563-4f4c-a215-b69eeb709575",
				externalId: undefined,
				name: "2025-09",
				terms: {},
			},
			n2025_10: {
				codename: "n2025_10",
				id: "ad5675b5-5936-441e-9ab6-e731697d0d63",
				externalId: undefined,
				name: "2025-10",
				terms: {},
			},
			n2025_11: {
				codename: "n2025_11",
				id: "b483ffb3-0de0-4c17-9d9a-5aec965c15c5",
				externalId: undefined,
				name: "2025-11",
				terms: {},
			},
			n2025_12: {
				codename: "n2025_12",
				id: "86a9e6f7-adb6-4043-85d1-a85e4cd6f3a9",
				externalId: undefined,
				name: "2025-12",
				terms: {},
			},
			n2026_01: {
				codename: "n2026_01",
				id: "fbc467e6-1b02-425d-8bd5-a7be226fd25f",
				externalId: undefined,
				name: "2026-01",
				terms: {},
			},
			n2026_02: {
				codename: "n2026_02",
				id: "67c05fe7-30f9-4687-a3e0-ce55cd2dfdc3",
				externalId: undefined,
				name: "2026-02",
				terms: {},
			},
			n2026_03: {
				codename: "n2026_03",
				id: "e1f75c2d-6749-492b-82ee-cb7bc6f5c0d8",
				externalId: undefined,
				name: "2026-03",
				terms: {},
			},
			n2026_04: {
				codename: "n2026_04",
				id: "3e2593ee-1996-4cd6-924e-b40f4c585d30",
				externalId: undefined,
				name: "2026-04",
				terms: {},
			},
			n2026_05: {
				codename: "n2026_05",
				id: "650866cc-5fb2-4626-a866-4aa67ffd78ea",
				externalId: undefined,
				name: "2026-05",
				terms: {},
			},
			n2026_06: {
				codename: "n2026_06",
				id: "16d29792-16f1-49a8-aa57-7676ce56c319",
				externalId: undefined,
				name: "2026-06",
				terms: {},
			},
			n2026_07: {
				codename: "n2026_07",
				id: "9337051f-5f74-4b85-a3af-024d0ec78eef",
				externalId: undefined,
				name: "2026-07",
				terms: {},
			},
			n2026_08: {
				codename: "n2026_08",
				id: "e35b3f93-6f42-410b-9997-e8d69a57a9f4",
				externalId: undefined,
				name: "2026-08",
				terms: {},
			},
			n2026_09: {
				codename: "n2026_09",
				id: "86c7efaf-e01c-4840-b7d5-a1bd03c6a7ec",
				externalId: undefined,
				name: "2026-09",
				terms: {},
			},
			n2026_10: {
				codename: "n2026_10",
				id: "9ff5af22-ca5e-4907-b3db-b2bb07db7251",
				externalId: undefined,
				name: "2026-10",
				terms: {},
			},
			n2026_11: {
				codename: "n2026_11",
				id: "2a47ab51-3051-4a13-b6af-e82548ac5024",
				externalId: undefined,
				name: "2026-11",
				terms: {},
			},
			n2026_12: {
				codename: "n2026_12",
				id: "1b7254ff-f997-4bda-9ac5-930ea38aaef5",
				externalId: undefined,
				name: "2026-12",
				terms: {},
			},
			n2027_01: {
				codename: "n2027_01",
				id: "81907c5a-6f16-43ed-9dad-33bd5772ce31",
				externalId: undefined,
				name: "2027-01",
				terms: {},
			},
			n2027_02: {
				codename: "n2027_02",
				id: "489783e0-02a3-4d44-a26a-47c79aa9e260",
				externalId: undefined,
				name: "2027-02",
				terms: {},
			},
			n2027_03: {
				codename: "n2027_03",
				id: "3f0f325d-4b36-4c06-a8b1-30114a0a7a4b",
				externalId: undefined,
				name: "2027-03",
				terms: {},
			},
			n2027_04: {
				codename: "n2027_04",
				id: "5994a98e-c9e6-473b-8f08-5c55172d6686",
				externalId: undefined,
				name: "2027-04",
				terms: {},
			},
			n2027_05: {
				codename: "n2027_05",
				id: "8083c56e-ed70-4ad0-8739-d046c99ab1eb",
				externalId: undefined,
				name: "2027-05",
				terms: {},
			},
			n2027_06: {
				codename: "n2027_06",
				id: "1dc96646-8993-461b-a578-63870aa8253f",
				externalId: undefined,
				name: "2027-06",
				terms: {},
			},
			n2027_07: {
				codename: "n2027_07",
				id: "21c16c15-63be-4213-af02-421b556bca48",
				externalId: undefined,
				name: "2027-07",
				terms: {},
			},
			n2027_08: {
				codename: "n2027_08",
				id: "951251fb-5b21-46bb-a11a-79c2bc8486c8",
				externalId: undefined,
				name: "2027-08",
				terms: {},
			},
			n2027_09: {
				codename: "n2027_09",
				id: "ac86e853-a16b-4a7a-a573-cc9d6142936a",
				externalId: undefined,
				name: "2027-09",
				terms: {},
			},
			n2027_10: {
				codename: "n2027_10",
				id: "635aea53-75a8-405a-b37d-bbdb1e5894fc",
				externalId: undefined,
				name: "2027-10",
				terms: {},
			},
			n2027_11: {
				codename: "n2027_11",
				id: "9e876bb8-f456-422b-ae85-aaaee98b5a3e",
				externalId: undefined,
				name: "2027-11",
				terms: {},
			},
			n2027_12: {
				codename: "n2027_12",
				id: "0d4a8502-b63b-45e5-8ec2-ef379b9619a4",
				externalId: undefined,
				name: "2027-12",
				terms: {},
			},
			n2028_01: {
				codename: "n2028_01",
				id: "35465d5b-4b9a-4b66-b80e-22199293b066",
				externalId: undefined,
				name: "2028-01",
				terms: {},
			},
			n2028_02: {
				codename: "n2028_02",
				id: "ebc9430f-7b94-463a-a3a9-97ba72aa9356",
				externalId: undefined,
				name: "2028-02",
				terms: {},
			},
			n2028_03: {
				codename: "n2028_03",
				id: "56740285-564a-4b32-a652-8ff4f02008ed",
				externalId: undefined,
				name: "2028-03",
				terms: {},
			},
			n2028_04: {
				codename: "n2028_04",
				id: "dfd7da9b-07e3-4bf6-9a5d-fcc4efd7807f",
				externalId: undefined,
				name: "2028-04",
				terms: {},
			},
			n2028_05: {
				codename: "n2028_05",
				id: "90d0b0b6-5af6-4307-97e6-5ca9e26391e5",
				externalId: undefined,
				name: "2028-05",
				terms: {},
			},
			n2028_06: {
				codename: "n2028_06",
				id: "e7f6c154-02ca-4377-ae5e-3e9a684f3a35",
				externalId: undefined,
				name: "2028-06",
				terms: {},
			},
			n2028_07: {
				codename: "n2028_07",
				id: "a123507f-1710-418b-b629-b5412d2986ba",
				externalId: undefined,
				name: "2028-07",
				terms: {},
			},
			n2028_08: {
				codename: "n2028_08",
				id: "8f89f7b7-a5b5-41d9-9b5b-0ff823e1c31d",
				externalId: undefined,
				name: "2028-08",
				terms: {},
			},
			n2028_09: {
				codename: "n2028_09",
				id: "81e3de78-1ae0-4836-9f86-09d69d38ab1d",
				externalId: undefined,
				name: "2028-09",
				terms: {},
			},
			n2028_10: {
				codename: "n2028_10",
				id: "c06f8a84-1b09-4c81-b162-6b7d225af8e9",
				externalId: undefined,
				name: "2028-10",
				terms: {},
			},
			n2028_11: {
				codename: "n2028_11",
				id: "28d268ab-3858-4577-a747-b6a09229c7d2",
				externalId: undefined,
				name: "2028-11",
				terms: {},
			},
			n2028_12: {
				codename: "n2028_12",
				id: "d7b59250-2689-48ea-8a3d-ec13bcf5d4af",
				externalId: undefined,
				name: "2028-12",
				terms: {},
			},
			n2029_01: {
				codename: "n2029_01",
				id: "b664d9e7-bec5-4b78-a532-9e3f86d112ff",
				externalId: undefined,
				name: "2029-01",
				terms: {},
			},
			n2029_02: {
				codename: "n2029_02",
				id: "43625f8a-07fe-43e3-86d5-097d73a717b5",
				externalId: undefined,
				name: "2029-02",
				terms: {},
			},
			n2029_03: {
				codename: "n2029_03",
				id: "109e9026-7cf9-46f2-81e2-5591b4252e92",
				externalId: undefined,
				name: "2029-03",
				terms: {},
			},
			n2029_04: {
				codename: "n2029_04",
				id: "0568b86e-fadb-4824-bf36-b6bea0476442",
				externalId: undefined,
				name: "2029-04",
				terms: {},
			},
			n2029_05: {
				codename: "n2029_05",
				id: "d9998b6b-cba1-41a0-a452-2bafa1d89a5e",
				externalId: undefined,
				name: "2029-05",
				terms: {},
			},
			n2029_06: {
				codename: "n2029_06",
				id: "9a7bb98b-556a-4654-a19f-f8a9d8d7fe0f",
				externalId: undefined,
				name: "2029-06",
				terms: {},
			},
			n2029_07: {
				codename: "n2029_07",
				id: "ac50a4d1-a09f-4d0b-80ce-2bb5ccb70538",
				externalId: undefined,
				name: "2029-07",
				terms: {},
			},
			n2029_08: {
				codename: "n2029_08",
				id: "ad404654-13cc-41da-a7f1-099b7f5230af",
				externalId: undefined,
				name: "2029-08",
				terms: {},
			},
			n2029_09: {
				codename: "n2029_09",
				id: "f357c966-69ae-45b0-a9ca-846a2dbc3665",
				externalId: undefined,
				name: "2029-09",
				terms: {},
			},
			n2029_10: {
				codename: "n2029_10",
				id: "0b7fff9b-e820-47c3-9b47-68d0ddab6ee6",
				externalId: undefined,
				name: "2029-10",
				terms: {},
			},
			n2029_11: {
				codename: "n2029_11",
				id: "a232aa87-6f4d-4330-8b54-83c836f66c24",
				externalId: undefined,
				name: "2029-11",
				terms: {},
			},
			n2029_12: {
				codename: "n2029_12",
				id: "d50fb38c-9fc6-4020-9949-9fa38bb8634c",
				externalId: undefined,
				name: "2029-12",
				terms: {},
			},
		},
	},

	/**
	 * Shop Type
	 */
	shop_type: {
		codename: "shop_type",
		id: "251a5be4-ba1e-46c6-9a4a-d017e7317a23",
		externalId: undefined,
		name: "Shop Type",
		terms: {
			books: {
				codename: "books",
				id: "dceb3bb3-85a3-49af-9674-170fb4f3b31e",
				externalId: undefined,
				name: "Books",
				terms: {},
			},
			bridal: {
				codename: "bridal",
				id: "6d8580ea-6491-45e2-973d-9d628d7d64c4",
				externalId: undefined,
				name: "Bridal",
				terms: {},
			},
			discount: {
				codename: "discount",
				id: "4cfae9db-4c7e-454f-9034-c7a70984f76d",
				externalId: undefined,
				name: "Discount",
				terms: {},
			},
			furniture_and_electrical: {
				codename: "furniture_and_electrical",
				id: "302de2e8-ba02-4648-8417-a9b930861db3",
				externalId: undefined,
				name: "Furniture and electrical",
				terms: {},
			},
			vintage_and_designer: {
				codename: "vintage_and_designer",
				id: "f2c4d09e-01cb-4002-90b0-383acba58530",
				externalId: undefined,
				name: "Vintage and designer",
				terms: {},
			},
		},
	},

	/**
	 * Teams
	 */
	teams: {
		codename: "teams",
		id: "3424adb9-3c63-4382-9c6c-66982583a9e7",
		externalId: undefined,
		name: "Teams",
		terms: {
			digital: {
				codename: "digital",
				id: "5a5ed5de-d337-40bc-af00-030c6d924527",
				externalId: undefined,
				name: "Digital",
				terms: {},
			},
			web_content: {
				codename: "web_content",
				id: "e6ee6957-bf95-445c-a585-f6316f332f9d",
				externalId: undefined,
				name: "Web Content",
				terms: {},
			},
		},
	},

	/**
	 * Topics
	 */
	topics: {
		codename: "topics",
		id: "9bdc5be2-be93-46a5-a572-13304d75e23c",
		externalId: undefined,
		name: "Topics",
		terms: {
			about_us: {
				codename: "about_us",
				id: "a85e36c0-5cfe-47a7-91a6-57dd140cefea",
				externalId: undefined,
				name: "About us",
				terms: {
					governance: {
						codename: "governance",
						id: "106fc91b-ecc3-4fd8-9c57-41a21a3e8090",
						externalId: undefined,
						name: "Governance",
						terms: {},
					},
					history: {
						codename: "history",
						id: "4319a87f-78b5-47b4-826a-2021bbacc879",
						externalId: undefined,
						name: "History",
						terms: {},
					},
					staff: {
						codename: "staff",
						id: "5d35e66c-39c7-4257-a6f5-ab5fedbe8c90",
						externalId: undefined,
						name: "Staff",
						terms: {},
					},
					trustees: {
						codename: "trustees",
						id: "d44a859d-ee87-4644-ae7d-af95561c841b",
						externalId: undefined,
						name: "Trustees",
						terms: {},
					},
					volunteers: {
						codename: "volunteers",
						id: "14048131-3f25-45f1-b05c-c324de638b50",
						externalId: undefined,
						name: "Volunteers",
						terms: {},
					},
				},
			},
			community_education: {
				codename: "community_education",
				id: "703933b0-c3a9-430a-a672-0846020229ad",
				externalId: undefined,
				name: "Community education",
				terms: {
					first_aid: {
						codename: "first_aid",
						id: "e0ad4e4f-b264-4593-acc4-36799b6ee426",
						externalId: undefined,
						name: "First aid",
						terms: {},
					},
					humanitarian_education: {
						codename: "humanitarian_education",
						id: "d2a43553-81c5-47ff-933f-02a09f256d5d",
						externalId: undefined,
						name: "Humanitarian education",
						terms: {},
					},
				},
			},
			international_d1f3bf0: {
				codename: "international_d1f3bf0",
				id: "d1f3bf05-0fc4-4aa8-8ea5-c9b6cd80ddb3",
				externalId: undefined,
				name: "International",
				terms: {
					americas: {
						codename: "americas",
						id: "3826036a-869e-40d7-a084-ef4ef02646e2",
						externalId: undefined,
						name: "Americas",
						terms: {},
					},
					asia: {
						codename: "asia",
						id: "ab70392c-ce84-4025-838c-4d4342121997",
						externalId: undefined,
						name: "Asia",
						terms: {},
					},
					east_and_southern_africa: {
						codename: "east_and_southern_africa",
						id: "8b6fcb31-f8cb-4b1b-9184-eb20deef221d",
						externalId: undefined,
						name: "East and Southern Africa",
						terms: {},
					},
					emblem: {
						codename: "emblem",
						id: "40b5e465-2c13-4670-9ad0-7b4715351626",
						externalId: undefined,
						name: "Emblem",
						terms: {},
					},
					europe: {
						codename: "europe",
						id: "95696aef-3936-4f5a-b5b9-bc41f52674ab",
						externalId: undefined,
						name: "Europe",
						terms: {},
					},
					food_and_agriculture: {
						codename: "food_and_agriculture",
						id: "93be430e-a1f3-4cd6-b376-fd54b851ea76",
						externalId: undefined,
						name: "Food and Agriculture",
						terms: {},
					},
					international: {
						codename: "international",
						id: "0028cf7e-0f10-45c1-9149-d113d64826a0",
						externalId: undefined,
						name: "International",
						terms: {},
					},
					international_emergencies_and_global_surge: {
						codename: "international_emergencies_and_global_surge",
						id: "056a4956-80f1-46f3-81a4-5f34cbd258f8",
						externalId: undefined,
						name: "International emergencies and global surge",
						terms: {},
					},
					international_emergency: {
						codename: "international_emergency",
						id: "479590f7-f64d-4092-a5be-cfa8ddf8fc36",
						externalId: undefined,
						name: "International emergency",
						terms: {},
					},
					international_humanitarian_law: {
						codename: "international_humanitarian_law",
						id: "81672bb7-9dcc-4178-875d-c4974fd60e6b",
						externalId: undefined,
						name: "International humanitarian law",
						terms: {},
					},
					middle_east_and_north_africa__mena_: {
						codename: "middle_east_and_north_africa__mena_",
						id: "95502ae2-a39e-41fa-878d-03f8f1d8b7f2",
						externalId: undefined,
						name: "Middle East and North Africa (MENA)",
						terms: {},
					},
					west_and_central_africa: {
						codename: "west_and_central_africa",
						id: "21601c75-024a-45bd-a96b-0ceaad70009a",
						externalId: undefined,
						name: "West and Central Africa",
						terms: {},
					},
				},
			},
			marketing_and_fundraising: {
				codename: "marketing_and_fundraising",
				id: "bb7dcffe-f6ea-408c-bc3b-eafb046adacf",
				externalId: undefined,
				name: "Marketing and fundraising",
				terms: {
					buckets_and_collecting_tins: {
						codename: "buckets_and_collecting_tins",
						id: "0f51c79e-cbad-41d5-af3c-e90da4b22fdf",
						externalId: undefined,
						name: "Buckets and collecting tins",
						terms: {},
					},
					corporate_partnerships: {
						codename: "corporate_partnerships",
						id: "f8eb098f-0755-4793-9713-01f318b7992b",
						externalId: undefined,
						name: "Corporate partnerships",
						terms: {},
					},
					fundraisers: {
						codename: "fundraisers",
						id: "15bb0c93-a992-4dcb-83a9-a2f22534a25b",
						externalId: undefined,
						name: "Fundraisers",
						terms: {},
					},
					fundraising_events: {
						codename: "fundraising_events",
						id: "c12bbc15-46f8-4890-8a3e-156b099c7662",
						externalId: undefined,
						name: "Fundraising events",
						terms: {},
					},
					open_gardens: {
						codename: "open_gardens",
						id: "99ccd1de-48e4-4720-942d-77efbb10b95f",
						externalId: undefined,
						name: "Open Gardens",
						terms: {},
					},
				},
			},
			refugee_services: {
				codename: "refugee_services",
				id: "53cbc64c-4c39-44a9-be17-9d19003dfc2e",
				externalId: undefined,
				name: "Refugee services",
				terms: {
					family_reunion: {
						codename: "family_reunion",
						id: "58a1cae0-8484-476b-9298-b937d77feeea",
						externalId: undefined,
						name: "Family reunion",
						terms: {},
					},
					family_tracing: {
						codename: "family_tracing",
						id: "033a8a60-2808-4a03-8618-87a66a34378c",
						externalId: undefined,
						name: "Family tracing",
						terms: {},
					},
					refugees: {
						codename: "refugees",
						id: "f3f4c465-e58e-42f9-8a7e-cf667f135cec",
						externalId: undefined,
						name: "Refugees",
						terms: {},
					},
					trafficking: {
						codename: "trafficking",
						id: "a80bafae-fe72-494d-bce8-76f7557acc62",
						externalId: undefined,
						name: "Trafficking",
						terms: {},
					},
				},
			},
			uk_operations: {
				codename: "uk_operations",
				id: "03aa75e0-d773-49ea-aeee-ccff1844e48c",
				externalId: undefined,
				name: "UK operations",
				terms: {
					ambulance: {
						codename: "ambulance",
						id: "eaa3e562-2e81-4084-bff1-f5ec0154474a",
						externalId: undefined,
						name: "Ambulance",
						terms: {},
					},
					community_support: {
						codename: "community_support",
						id: "56c2c790-225d-413e-a35a-49846a02d994",
						externalId: undefined,
						name: "Community support",
						terms: {},
					},
					covid: {
						codename: "covid",
						id: "05d0e46b-b484-4c51-8caf-5b9da3427fb4",
						externalId: undefined,
						name: "Covid",
						terms: {},
					},
					emergency_response: {
						codename: "emergency_response",
						id: "c59db740-824b-4858-b405-bd94c560e0c3",
						externalId: undefined,
						name: "Emergency response",
						terms: {},
					},
					health: {
						codename: "health",
						id: "c42db584-101b-421c-861b-c78cde147b76",
						externalId: undefined,
						name: "Health",
						terms: {},
					},
					home_from_hospital: {
						codename: "home_from_hospital",
						id: "13c5259e-66e4-4f37-86bb-15b023c02484",
						externalId: undefined,
						name: "Home from hospital",
						terms: {},
					},
					prepare_for_emergencies: {
						codename: "prepare_for_emergencies",
						id: "bed6de6a-ee52-4b02-b260-637dfd3ef4e0",
						externalId: undefined,
						name: "Prepare for emergencies",
						terms: {},
					},
					support_at_home: {
						codename: "support_at_home",
						id: "4a7c6970-cf2b-41c7-9c2c-f07ffb577e25",
						externalId: undefined,
						name: "Support at home",
						terms: {},
					},
					uk: {
						codename: "uk",
						id: "1b8c6f52-7af5-42d9-9653-a870f60e24e8",
						externalId: undefined,
						name: "UK",
						terms: {},
					},
					wellbeing: {
						codename: "wellbeing",
						id: "7c95f404-a459-47f4-a869-a65331c08809",
						externalId: undefined,
						name: "Wellbeing",
						terms: {},
					},
				},
			},
		},
	},
} as const;
