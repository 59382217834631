import React from "react";
import { PlainText } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text";
import { PlainTextSize } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text.types";

export function Loading(): JSX.Element {
	return (
		<div className="w-full md:max-content md:mx-auto py-3 px-4 md:px-0">
			<div className="py-3">
				<PlainText size={PlainTextSize.Default}>Loading&hellip;</PlainText>
			</div>
		</div>
	);
}
