"use client";

import React from "react";
import { CardContainer as CardContainerBrc } from "@britishredcross/component-library/src/components/organisms/card-container/card-container";
import { Heading as HeadingBrc } from "@britishredcross/component-library/src/components/atoms/heading/heading";
import { HeadingLevel } from "@britishredcross/component-library/src/components/atoms/heading/heading.types";
import { PlainText as PlainTextBrc } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text";
import { type CardContainerModel } from "@britishredcross/kontent-integration/src/models";
import Card from "./cards/card";

export function CardContainer(props: CardContainerModel): JSX.Element {
	const cards = props.elements.cards.linkedItems.map((item) => {
		const cardProps = Card(item);
		return <Card key={item.system.id} {...cardProps?.props} />;
	});

	return (
		<CardContainerBrc
			cards={cards}
			subtitle={<PlainTextBrc>{props.elements.text.value}</PlainTextBrc>}
			title={
				<HeadingBrc level={HeadingLevel.H2}>
					{props.elements.title.value}
				</HeadingBrc>
			}
		/>
	);
}
