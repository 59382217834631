import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type SearchResultsErrorProps } from "./search-results-error.types";

export function SearchResultsError({
	title,
	message,
}: SearchResultsErrorProps): React.JSX.Element {
	return (
		<div className="w-full md:max-content md:mx-auto py-3 px-4 md:px-0">
			<Heading level={HeadingLevel.H2}>
				<span className="text-red-primary">{title}</span>
			</Heading>
			{message ? (
				<div className="py-3">
					<PlainText size={PlainTextSize.Default}>{message}</PlainText>
				</div>
			) : null}
		</div>
	);
}
