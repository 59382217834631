"use client";
import React, { useEffect } from "react";
import { type ContactBannerProps } from "./contact-banner.types";

export function ContactBanner({
	title,
	subtitle,
	bgColour,
	contactDetail,
}: ContactBannerProps) {
	useEffect(() => {
		// Check if the Footer is present right after to remove margin bottom of the this component
		function sibling() {
			const getContactBanner = document.getElementById("brc-contact-banner");
			if (getContactBanner) {
				const isFooterSibling = getContactBanner.nextElementSibling;
				if (isFooterSibling) {
					if (isFooterSibling.tagName === "FOOTER") {
						getContactBanner.classList.add("mb-0", "md:mb-0");
						getContactBanner.classList.remove("mb-8", "md:mb-16");
					}
				}
			}
		}
		sibling();
	});

	return (
		<section
			id="brc-contact-banner"
			className={` ${
				bgColour === "darkRed"
					? "bg-red-secondary"
					: bgColour === "green"
						? "bg-green-secondary"
						: bgColour === "grey"
							? "bg-grey-secondary"
							: bgColour === "darkBlue"
								? "bg-blue-secondary"
								: "bg-teal-primary"
			} w-full mb-8 md:mb-16`}
		>
			<div className="w-full max-content mx-auto px-4 py-12 lg:px-0">
				<div className="grid grid-cols-12 gap-6 text-center">
					<div className="col-start-1 col-span-12 prose-h2:text-white prose-p:text-white md:col-start-2 md:col-span-10 lg:col-start-3 mb-8 lg:col-span-8">
						{title}
						{subtitle ? subtitle : ""}
					</div>
				</div>

				<div className="grid grid-cols-1 gap-6 content-between md:grid-cols-12">
					{React.Children.map(contactDetail, (contact, key: number) => (
						<div
							className="col-start-1 col-span-12 md:odd:col-start-2 md:even:col-start-7 md:col-span-5"
							key={key}
						>
							{contact}
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
