const sanitizeRecordForUx = (
	record: { [key: string]: string },
	config: { [key: string]: string }
) => {
	const newRecord: { [key: string]: any } = {};

	const configKeys = Object.keys(config);
	const configVals = Object.values(config);

	configKeys.forEach((key) => {
		const val = config[key] || "";
		newRecord[key] = record[val];
	});

	Object.keys(record)
		.filter((key) => !configVals.includes(key))
		.forEach((key) => {
			if (
				["string", "number"].includes(typeof record[key]) &&
				key[0] !== key[0]?.toUpperCase()
			) {
				const newKey = key
					.split("_")
					.map((k) => `${k[0]?.toUpperCase()}${k.substring(1)}`)
					.join(" ");

				newRecord[newKey] = record[key]
					?.replace("[", "")
					.replace("]", "")
					.replace(`"`, "")
					.replace(`"`, "")
					.replace(`"`, "")
					.replace(`"`, "");
			}
		});

	return newRecord;
};

export { sanitizeRecordForUx };
