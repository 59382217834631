"use client";
import React, { useEffect } from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type XProfileProps } from "./x-profile.types";

export function XProfile({ title, description, xusername }: XProfileProps) {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://platform.twitter.com/widgets.js";
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<section className="bg-grey-10 mb-8 md:mb-12 lg:mb-16">
			<div className="w-full max-content mx-auto grid grid-cols-12 px-4 py-8 md:py-12 lg:py-16 lg:px-0">
				<div className="col-span-12 col-start-1 md:col-span-10 lg:col-span-8">
					<div className="mb-2 md:mb-4">
						<Heading level={HeadingLevel.H2}>{title}</Heading>
					</div>

					{description ? (
						<div className="mb-4">
							<PlainText size={PlainTextSize.Default} bold={false}>
								{description}
							</PlainText>
						</div>
					) : (
						""
					)}

					<div>
						<a
							className="sr-only block w-full text-center focus:not-sr-only"
							href="#after-x-feed-embed"
						>
							Skip embedded X timeline
						</a>
						<a
							className="twitter-timeline"
							data-height="400"
							href={`https://twitter.com/${xusername}?ref_src=twsrc%5Etfw`}
							data-tweet-limit="10"
						>
							Tweets by {xusername}
						</a>
						<div id="after-x-feed-embed" tabIndex={-1} />
					</div>
				</div>
			</div>
		</section>
	);
}
