"use client";
import React from "react";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { type PaginationProps } from "./pagination.types";
import { createLinks } from "./shared";
import { useLanguageDirection } from "../../../hooks/use-language-direction";

export function Pagination({
	currentPage,
	totalPages,
	onClick,
	nextLabel,
	previousLabel,
}: PaginationProps) {
	const isRTL = useLanguageDirection();

	return (
		<div className="w-full max-content mx-auto grid grid-cols-12 gap-4 text-center mb-8 md:mb-12 lg:mb-16">
			<div className="col-start-2 col-span-10 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
				<div className="block w-full text-center mb-5 md:hidden">
					<p>
						Page {currentPage} of {totalPages}
					</p>
				</div>
				<nav
					className="flex items-center justify-center"
					role="navigation"
					aria-label="Pagination"
				>
					{currentPage === 1 ? (
						<span
							className="w-full max-w-[160px] flex h-12 justify-center items-center text-base no-underline font-bold tracking-wide bg-white border border-grey-20 text-grey-20 mr-2 md:mr-1 prose-span:text-grey-20"
							aria-label="Previous Page Disabled"
						>
							<Icon
								name={isRTL ? "chevron_right" : "chevron_left"}
								size={24}
								colour={IconColour.NeutralGrey20}
							/>
							{previousLabel}
						</span>
					) : (
						<a
							href="#"
							className="w-full max-w-[160px] flex h-12 justify-center items-center text-base no-underline font-bold tracking-wide bg-white border border-grey-20  text-black-primary mr-2 md:mr-1 hover:bg-grey-20 hover:underline hover:border-red-primary focus:bg-grey-20 focus:underline focus:border-red-primary"
							onClick={() => {
								onClick(currentPage - 1);
							}}
							aria-label="Go To Previous Page"
						>
							<Icon
								name={isRTL ? "chevron_right" : "chevron_left"}
								size={24}
								colour={IconColour.PrimaryBlack}
							/>
							{previousLabel}
						</a>
					)}

					<div className="hidden md:flex">
						{createLinks({
							currentPage,
							totalPages,
							onClick,
							nextLabel,
							previousLabel,
						})}
					</div>
					{currentPage === totalPages ? (
						<span
							className="w-full max-w-[160px] flex h-12 justify-center items-center text-base no-underline font-bold tracking-wide bg-white border border-grey-20 text-grey-20 ml-2 md:ml-1"
							aria-label="Next Page Disabled"
						>
							{nextLabel}
							<Icon
								name={isRTL ? "chevron_left" : "chevron_right"}
								size={24}
								colour={IconColour.NeutralGrey20}
							/>
						</span>
					) : (
						<a
							href="#"
							className="w-full max-w-[160px] flex h-12 justify-center items-center text-base no-underline font-bold tracking-wide bg-white border border-grey-20  text-black-primary ml-2 md:ml-1 hover:bg-grey-20 hover:underline hover:border-red-primary focus:bg-grey-20 focus:underline focus:border-red-primary"
							onClick={() => {
								onClick(currentPage + 1);
							}}
							aria-label="Go To Next Page"
						>
							{nextLabel}
							<Icon
								name={isRTL ? "chevron_left" : "chevron_right"}
								size={24}
								colour={IconColour.PrimaryBlack}
							/>
						</a>
					)}
				</nav>
			</div>
		</div>
	);
}
