import { useState } from "react";
import type { VadApi, VadApiProps } from "./store.types";

export const useVadApi = ({ searchUrl, recordUrl }: VadApiProps): VadApi => {
	const [searchResults, setSearchResults] = useState<any[]>([]);
	const [record, setRecord] = useState<any>();
	const [resultsLoading, setResultsLoading] = useState<boolean>(false);
	const [recordLoading, setRecordLoading] = useState<boolean>(false);
	const [lastSearchTerm, setLastSearchTerm] = useState<string>();

	const clearResults = () => {
		setSearchResults([]);
	};

	const search = async (searchTerm: string, filter?: string) => {
		setResultsLoading(true);
		if (searchTerm !== lastSearchTerm) {
			setLastSearchTerm(searchTerm);
			setSearchResults([]);
		}
		const filterPair = filter ? `&filter=${filter}` : "";

		const API_URL = `${searchUrl}?$count=true&$top=500&searchTerm=${searchTerm}${filterPair}`;

		const apiResponse = await fetch(API_URL);

		const apiJson = await apiResponse.json();
		if (apiJson.error !== undefined) {
			setResultsLoading(false);
			setSearchResults([]);
			return;
		}
		setResultsLoading(false);
		setSearchResults([...apiJson.value]);
	};

	const clearRecord = () => {
		setRecord(undefined);
	};

	const getRecord = async (rowKey: string) => {
		setRecordLoading(true);
		const API_URL = `${recordUrl}?rowKey=${rowKey}`;

		const apiResponse = await fetch(API_URL);

		const apiJson = await apiResponse.json();

		setRecordLoading(false);
		setRecord(apiJson.value[0]);
	};

	return {
		search,
		searchResults,
		clearResults,
		resultsLoading,

		record,
		getRecord,
		clearRecord,
		recordLoading,
	};
};
