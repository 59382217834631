"use client";

import React, { useEffect, useState, useRef } from "react";
import { useRouter, useSearchParams, usePathname } from "next/navigation";
import type { SearchModel } from "@britishredcross/kontent-integration/dist/esm/models";
import { SearchForm } from "@britishredcross/component-library/src/components/molecules/search-form/search-form";
import { DEFAULT_LOCALE } from "@britishredcross/kontent-integration/src/constants/localisation";
import { Pagination } from "@britishredcross/component-library/src/components/molecules/pagination/pagination";
import {
	type TypesenseSearchResponseHit,
	type WebSearchError,
	isWebSearchError,
} from "@/actions/search/types";
import { webSearch, type SearchOptions } from "@/actions/search/search";
import {
	DEFAULT_SEARCH_RESULTS_PER_PAGE,
	SEARCH_QUERY_TO_RETURN_ALL_DOCUMENTS,
} from "@/actions/search/constants";
import { getLocaleInUrlPath } from "@/utils/get-locale-in-url-path";
import { useDictionary } from "@/app/context/dictionary.context";
import { Results } from "./results";
import { NoResults } from "./no-results";
import { Error } from "./error";
import { ResultsCount } from "./results-count";
import { Loading } from "./loading";

interface PaginationState {
	currentPage: number;
	perPage: number;
	totalPages: number;
}

interface ResultsCountState {
	rangeMin: number;
	rangeMax: number;
	totalResults: number;
}

const queryIsUserInput = (
	query: Record<string, unknown> | undefined
): boolean => {
	const queryHasMultipleParams = Boolean(
		query && Object.keys(query).length > 1
	);
	return (
		queryHasMultipleParams ||
		(Boolean(query?.q) && query?.q !== SEARCH_QUERY_TO_RETURN_ALL_DOCUMENTS)
	);
};

const updateUrl = (query, router): void => {
	if (!queryIsUserInput(query)) return;

	const queryParams = { ...query };
	// Delete wildcard query so it doesn't show up in the URL
	if (queryParams.q === SEARCH_QUERY_TO_RETURN_ALL_DOCUMENTS)
		delete queryParams.q;

	const querystring = new URLSearchParams(queryParams).toString();
	router.replace(
		`${window.location.origin}${window.location.pathname}?${querystring}`,
		{ scroll: false }
	);
};

const getQueryFromSearchParams = (searchParams): Record<string, unknown> => {
	const queryObj = {};
	for (const [key, value] of searchParams.entries()) {
		queryObj[key] = value;
	}
	return queryObj;
};

export function SearchClient({
	searchContentItem,
}: {
	searchContentItem: SearchModel;
}): JSX.Element {
	const resultsPerPage: number = parseInt(
		process.env.NEXT_PUBLIC_SEARCH_RESULTS_PER_PAGE ??
			`${DEFAULT_SEARCH_RESULTS_PER_PAGE}`,
		10
	);

	const dictionaryData = useDictionary();

	const router = useRouter();
	const pathName = usePathname();
	const searchParams = useSearchParams();

	const [query, setQuery] = useState<Record<string, unknown> | undefined>(
		getQueryFromSearchParams(searchParams)
	);
	const [error, setError] = useState<boolean | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [results, setResults] = useState<
		TypesenseSearchResponseHit[] | undefined
	>();
	const [pagination, setPagination] = useState<PaginationState>({
		currentPage: 0,
		totalPages: 0,
		perPage: resultsPerPage,
	});

	const [resultsCount, setResultsCount] = useState<ResultsCountState>({
		rangeMin: 0,
		rangeMax: 0,
		totalResults: 0,
	});

	const searchOutcomeRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		updateUrl(query, router);
	}, [query, router]);

	const submitSearch = async (
		searchData: Record<string, unknown> | undefined,
		options?: SearchOptions
	): Promise<void> => {
		if (!searchData) searchData = {};
		if (!searchData.q) searchData.q = SEARCH_QUERY_TO_RETURN_ALL_DOCUMENTS;

		const locale = getLocaleInUrlPath(pathName) ?? DEFAULT_LOCALE;

		// construct the index name
		const typesenseCollectionName = `${process.env.NEXT_PUBLIC_COLLECTION_CODENAME ?? ""}_content_${locale}_${process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID ?? ""}`;

		setIsLoading(true);
		setQuery(searchData);
		const response: any | WebSearchError = await webSearch(
			searchData,
			typesenseCollectionName,
			{
				contentType: [
					searchContentItem.elements.content_type_search.value[0]?.codename ??
						"",
				],
				page: options?.page ?? 1,
			}
		);
		const hits: TypesenseSearchResponseHit[] = response.grouped_hits?.flatMap(
			(group) => group.hits
		);

		if (isWebSearchError(response)) {
			setError(true);
		} else if (hits.length) {
			setError(false);
			setResults(hits);
			setPagination({
				currentPage:
					options?.page ?? Math.floor((response.page - 1) * resultsPerPage + 1),
				totalPages: Math.ceil(response.found / resultsPerPage),
				perPage: hits.length > resultsPerPage ? resultsPerPage : hits.length,
			});

			setResultsCount({
				rangeMin: Math.floor((response.page - 1) * resultsPerPage + 1),
				rangeMax:
					Math.floor((response.page - 1) * resultsPerPage + 1) +
					(hits.length > resultsPerPage ? resultsPerPage : hits.length) -
					1,
				totalResults: response.found,
			});
		} else {
			setError(false);
			setResults([]);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			await submitSearch(query);
		})();
	}, []);

	const setFocusToResults = (): void => {
		if (searchOutcomeRef.current && queryIsUserInput(query)) {
			searchOutcomeRef.current.focus();
		}
	};
	setFocusToResults();

	const title = searchContentItem.elements.title.value;
	const buttonText = searchContentItem.elements.search_button_text.value;
	const inputLabel = searchContentItem.elements.description.value;

	return isLoading ? (
		<Loading />
	) : (
		<>
			<SearchForm
				buttonText={buttonText}
				inputLabel={inputLabel}
				onSearch={async (searchData) => {
					await submitSearch(searchData as Record<string, unknown>);
				}}
				title={title}
				filterElements={searchContentItem.elements.search_filters.linkedItems}
				searchQuery={queryIsUserInput(query) ? query : undefined}
			/>

			<div ref={searchOutcomeRef} tabIndex={-1} data-testid="search-outcome">
				{error ? <Error /> : null}
				{!error && results?.length === 0 ? <NoResults /> : null}
				{!error && results?.length ? (
					<>
						{resultsCount ? (
							<ResultsCount
								rangeMin={resultsCount.rangeMin}
								rangeMax={resultsCount.rangeMax}
								total={resultsCount.totalResults}
							/>
						) : null}
						<Results
							results={results}
							searchContentType={
								searchContentItem.elements.content_type_search.value[0]
									?.codename ?? ""
							}
						/>
						<Pagination
							currentPage={pagination.currentPage}
							totalPages={pagination.totalPages}
							onClick={async (page) => {
								await submitSearch(query, { page });
							}}
							previousLabel={
								dictionaryData?.elements.pagination_button_back.value ?? ""
							}
							nextLabel={
								dictionaryData?.elements.pagination_button_next.value ?? ""
							}
						/>
					</>
				) : null}
			</div>
		</>
	);
}
