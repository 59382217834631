import React from "react";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { type VadRecordSearchResultProps } from "./vad-record-search-result.types";

export function VadRecordSearchResult({
	link,
	heading,
	county,
	dateOfEngagement,
	endOfEngagement,
}: VadRecordSearchResultProps) {
	return (
		<div>
			<div className="group relative block border-b border-grey-20 bg-white py-6 px-4 md:mb-6 md:px-0 md:border md:shadow-md md:hover:border-green-secondary md:hover:shadow-lg md:mx-auto md:max-content">
				<div className="w-full md:prose-h3:text-center prose-h3:mb-6">
					<h3>
						<a href={link} className="no-underline">
							<span aria-hidden="true" className="absolute inset-0" />
							<span className="sr-only">View record for</span>
							{heading}
						</a>
					</h3>

					<dl className="grid grid-col-12 col-span-12 text-left rtl:px-4">
						<dt className="col-span-12 md:col-span-5 md:col-start-2 lg:col-span-4 lg:col-start-3 prose-p:font-bold">
							<PlainText>County:</PlainText>
						</dt>
						<dd className="col-span-12 mb-2 md:mb-0 md:col-span-6 md:col-start-7">
							<PlainText>{county ? county : "N/A"}</PlainText>
						</dd>

						<dt className="col-span-12 md:col-span-5 md:col-start-2 lg:col-span-4 lg:col-start-3 prose-p:font-bold">
							<PlainText>Date of engagement:</PlainText>
						</dt>
						<dd className="col-span-12 mb-2 md:mb-0 md:col-span-6 md:col-start-7">
							<PlainText>
								{dateOfEngagement ? dateOfEngagement : "N/A"}
							</PlainText>
						</dd>

						<dt className="col-span-12 md:col-span-5 md:col-start-2 lg:col-span-4 lg:col-start-3 prose-p:font-bold">
							<PlainText>End of engagement:</PlainText>
						</dt>
						<dd className="col-span-12 mb-2 md:mb-0 md:col-span-6 md:col-start-7">
							<PlainText>{endOfEngagement ? endOfEngagement : "N/A"}</PlainText>
						</dd>
					</dl>
				</div>
			</div>
		</div>
	);
}
