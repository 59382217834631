import { type IContentItem } from "@kontent-ai/delivery-sdk";
import { contentTypes } from "../models";
import {
	type RedirectModel,
	type ShopModel,
	type HomepageModel,
	type LinkModel,
	type PageModel,
	type PressReleaseModel,
} from "../models/content-types";

export function isHomePage(
	contentItem: IContentItem | undefined
): contentItem is HomepageModel {
	return contentItem?.system.type === contentTypes.homepage.codename;
}

export function isPage(
	contentItem: IContentItem | undefined
): contentItem is PageModel {
	return contentItem?.system.type === contentTypes.page.codename;
}

export function isLink(
	contentItem: IContentItem | undefined
): contentItem is LinkModel {
	return contentItem?.system.type === contentTypes.link.codename;
}

export function isRedirectPage(
	contentItem: IContentItem | undefined
): contentItem is RedirectModel {
	return contentItem?.system.type === contentTypes.redirect.codename;
}

export function isShopPage(
	contentItem: IContentItem | undefined
): contentItem is ShopModel {
	return contentItem?.system.type === contentTypes.shop.codename;
}

export function isPressReleasePage(
	contentItem: IContentItem | undefined
): contentItem is PressReleaseModel {
	return contentItem?.system.type === contentTypes.press_release.codename;
}
