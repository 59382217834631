"use client";

import React from "react";
import { ShopListItem } from "../../molecules/shop-list-item/shop-list-item";
import { type ShopListProps } from "./shop-list.types";

export const getTodaysOpeningHours = (
	openingHours: { day: string; hours: string }[]
): string => {
	if (!openingHours.length) return "";
	const now = new Date();
	const today = now
		.toLocaleDateString(undefined, { weekday: "long" })
		.toLowerCase();
	const todaysOpeningHours = openingHours.find(
		(item) => item.day === today.toString()
	);
	return todaysOpeningHours?.hours ?? "";
};

export function ShopList({ shopListItems }: ShopListProps): React.JSX.Element {
	return (
		<ul>
			{shopListItems.length > 0
				? shopListItems.map((item, index) => (
						<ShopListItem
							key={`${item.title}-${index}`}
							title={item.title}
							distance={item.distance}
							shopType={item.shopType}
							isTemporarilyClosed={item.isTemporarilyClosed}
							temporarilyClosedLabel={item.temporarilyClosedLabel}
							shopDetailsLabel={item.shopDetailsLabel}
							todaysOpeningHours={
								item.openingHours
									? getTodaysOpeningHours(item.openingHours)
									: ""
							}
							shopUrl={item.shopUrl}
							milesLabel={item.milesLabel}
							closedLabel={item.closedLabel}
						/>
					))
				: null}
		</ul>
	);
}
