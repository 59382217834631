/**
 * Generated by '@kontent-ai/model-generator@7.4.0'
 */
export const contentTypes = {
	/**
	 * Accordion
	 */
	accordion: {
		codename: "accordion",
		id: "a0532af7-3c26-402c-b528-ca64372c51f7",
		externalId: undefined,
		name: "Accordion",
		elements: {
			/**
			 * Sections (modular_content)
			 *
			 * The Accordion Sections you want to include in this Accordion. They will be displayed in the order they are in here; you can drag-and-drop to rearrange them.
			 */
			sections: {
				codename: "sections",
				id: "c48ab6f3-386b-401f-aa84-4310a481ea6c",
				externalId: undefined,
				name: "Sections",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * An optional title for the whole Accordion
			 */
			title: {
				codename: "title",
				id: "f51cf0e6-3595-4c07-bdd3-3b85fb589198",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Accordion Section
	 */
	accordion_section: {
		codename: "accordion_section",
		id: "7fdafc1c-b4e6-4e72-9a8d-5af7e9f9fd23",
		externalId: undefined,
		name: "Accordion Section",
		elements: {
			/**
			 * Body (rich_text)
			 *
			 * This will be hidden while the section is collapsed, and shown when it is expanded.
			 */
			body: {
				codename: "body",
				id: "aa635981-bd76-4f63-93df-20a952894390",
				externalId: undefined,
				name: "Body",
				required: true,
				type: "rich_text",
			},

			/**
			 * Title (text)
			 *
			 * This will be shown while the section is collapsed. Visitors can click/select this space to expand the section.Make sure the title is clear and descriptive, letting visitors determine if the contained content is relevant to their needs.
			 */
			title: {
				codename: "title",
				id: "ae80453b-004a-4b7b-a788-099f82d631f6",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * API
	 */
	api: {
		codename: "api",
		id: "e75f9f6e-0f61-4fe0-8264-229efbe39296",
		externalId: undefined,
		name: "API",
		elements: {
			/**
			 * Authentication (modular_content)
			 */
			authentication: {
				codename: "authentication",
				id: "9a09543d-a8b5-4f5b-b5e8-75571471397e",
				externalId: undefined,
				name: "Authentication",
				required: true,
				type: "modular_content",
			},

			/**
			 * URL (text)
			 */
			url: {
				codename: "url",
				id: "b390e7a1-47bf-4d7f-87d8-b7a64eec882d",
				externalId: undefined,
				name: "URL",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * API Query Authentication
	 */
	api_query_authentication: {
		codename: "api_query_authentication",
		id: "c881b937-f1e3-4e04-b4d5-dcaddc16d0b7",
		externalId: undefined,
		name: "API Query Authentication",
		elements: {
			/**
			 * Key (text)
			 */
			key: {
				codename: "key",
				id: "4e7b15f9-1edd-46c4-8141-67993e23c3e8",
				externalId: undefined,
				name: "Key",
				required: true,
				type: "text",
			},

			/**
			 * Value (text)
			 */
			value: {
				codename: "value",
				id: "ea5b78cf-4ab5-4073-b2af-17cef09ba3e4",
				externalId: undefined,
				name: "Value",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Basic Hero
	 */
	basic_page_hero: {
		codename: "basic_page_hero",
		id: "5b4335ac-568f-4fb6-959b-773c101ce0cf",
		externalId: undefined,
		name: "Basic Hero",
		elements: {
			/**
			 * Subtitle (text)
			 *
			 * The Subtitle shows in smaller type under the title and is known as an <h2>. You have room for slightly more text here, but briefer is better. If it's too long, consider using the Text element instead.
			 */
			subtitle: {
				codename: "subtitle",
				id: "a447826e-25f8-4653-9664-4b3b5fd2d30b",
				externalId: undefined,
				name: "Subtitle",
				required: false,
				type: "text",
			},

			/**
			 * Text (text)
			 *
			 * Use this section for some more short text, if needed. It is OK to leave this blank if you said enough in the title and subtitle. It will show as a slightly-larger-than-normal text, known as a <p> element. If your Subtitle is too long, consider putting it here instead.
			 */
			text: {
				codename: "text",
				id: "be05fba1-1992-4f68-acf5-d7ae4ad81a60",
				externalId: undefined,
				name: "Text",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Enter the page headline here, which will show as a <h1> heading (the first and largest heading on a page). A page should always have exactly one <h1>, and this is it. It doesn't have to be the same as the page title, you can be creative - as long as it stays short, relevant and helpful!
			 */
			title: {
				codename: "title",
				id: "199de78d-8359-4c84-b814-eba755327188",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Card
	 */
	card: {
		codename: "card",
		id: "7709fc70-e55c-4acc-ac1c-957f498bb8a2",
		externalId: undefined,
		name: "Card",
		elements: {
			/**
			 * Image (asset)
			 *
			 * Upload or choose an image from the Asset library that is at least 735px wide by 551px tall. We will scale it (keeping the same aspect ratio) for different screen sizes.
			 */
			image: {
				codename: "image",
				id: "20b2aea0-fbed-4af3-943a-4f39c8484bc8",
				externalId: undefined,
				name: "Image",
				required: true,
				type: "asset",
			},

			/**
			 * Link (modular_content)
			 *
			 * Add a link to another page or external URL if needed. This won't be displayed as text on the Card, but the whole Card will act as a clickable link that takes the user to this destination.
			 */
			link: {
				codename: "link",
				id: "299f5fdb-b713-4cd1-8f31-8fe6ad556edd",
				externalId: undefined,
				name: "Link",
				required: true,
				type: "modular_content",
			},

			/**
			 * Text (text)
			 *
			 * The Card will only show four lines of text, maximum. We currently haven't set a character limit as how many characters are in a line will vary depend on the width of the characters (e.g. 'i' vs. 'm'), and the viewer's browser zoom and text size settings.We recommend checking any Cards in a Page in preview-mode before publishing.
			 */
			text: {
				codename: "text",
				id: "85e8811e-d6c7-4120-8fff-a3d43c20a198",
				externalId: undefined,
				name: "Text",
				required: true,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Enter the card title here, which will be shown as an <h3> heading (slightly larger than the main text). Cards are small, so you should write very brief text about what the user needs to know.
			 */
			title: {
				codename: "title",
				id: "f1e75234-2ba6-483d-8ee7-f79d81daa30a",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Card Container
	 */
	card_container: {
		codename: "card_container",
		id: "f4383066-151f-4c3f-85c2-bc686816c991",
		externalId: undefined,
		name: "Card Container",
		elements: {
			/**
			 * Cards (modular_content)
			 *
			 * Create or choose some existing Card items here. Items are shown in the order you put them in; drag-and-drop to rearrange them.
			 */
			cards: {
				codename: "cards",
				id: "ea6c24b8-d9d1-4bcc-8364-bd92db7154d2",
				externalId: undefined,
				name: "Cards",
				required: true,
				type: "modular_content",
			},

			/**
			 * Text (text)
			 *
			 * If needed, add a few words of supporting text here. This will be shown as a <p>, which is the same size as the body text on the page.
			 */
			text: {
				codename: "text",
				id: "7eb142b1-0a88-4493-b50c-0c200b024836",
				externalId: undefined,
				name: "Text",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Enter a brief title for this set of Card items that describes the set of pages they link to. This is shown as an <h2> - bigger than standard text size and smaller than the page heading.
			 */
			title: {
				codename: "title",
				id: "01b8e77c-de7d-4208-8785-a993184a16f8",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Contact Banner
	 */
	contact_banner: {
		codename: "contact_banner",
		id: "31a86512-10ff-475e-8e9f-ed38d859a88a",
		externalId: undefined,
		name: "Contact Banner",
		elements: {
			/**
			 * Background colour (multiple_choice)
			 *
			 * Choose a background colour for this banner
			 */
			background_colour: {
				codename: "background_colour",
				id: "5e39217f-9454-4bd1-b1f9-6f6f7a213c04",
				externalId: undefined,
				name: "Background colour",
				required: true,
				type: "multiple_choice",
				options: {
					blue: {
						name: "Blue",
						id: "93b0e301-8f2a-41aa-96de-e6eba3486de7",
						codename: "blue",
						externalId: undefined,
					},
					green: {
						name: "Green",
						id: "6c659f81-66f0-4711-8746-f9b8032e9e75",
						codename: "green",
						externalId: undefined,
					},
					grey: {
						name: "Grey",
						id: "fbcb746b-fd7f-4c6d-8777-23ebc2b6fffc",
						codename: "grey",
						externalId: undefined,
					},
					red: {
						name: "Red",
						id: "75055fa4-16f1-40cf-bda6-65050b51ac56",
						codename: "red",
						externalId: undefined,
					},
					teal: {
						name: "Teal",
						id: "8fbb2bf4-b750-405f-8767-56afee478b49",
						codename: "teal",
						externalId: undefined,
					},
				},
			},

			/**
			 * Contact details (modular_content)
			 *
			 * Choose or create some Contact Detail items to be included. They'll be presented in the order shown here, and you can drag-and-drop them to rearrange them. On larger screens they will be displayed two-to-a-row, left-to-right.
			 */
			contact_details: {
				codename: "contact_details",
				id: "be31423d-73f8-4c90-996f-925524ce869b",
				externalId: undefined,
				name: "Contact details",
				required: true,
				type: "modular_content",
			},

			/**
			 * Subtitle (text)
			 *
			 * Describe why the visitor might want to contact us (or whoever)
			 */
			subtitle: {
				codename: "subtitle",
				id: "f8d9f425-74b7-4bc9-8cab-7fc066ab8b87",
				externalId: undefined,
				name: "Subtitle",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Describe who or what the contact information is for
			 */
			title: {
				codename: "title",
				id: "e8e26c03-b8a6-4549-9113-988f04dc95db",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Contact Detail
	 */
	contact_detail: {
		codename: "contact_detail",
		id: "7d10c172-d0c0-4368-94ff-5acc86b5663e",
		externalId: undefined,
		name: "Contact Detail",
		elements: {
			/**
			 * Detail (text)
			 *
			 * The contact information itself. This can be a building address, an email address, a phone number, or a URL.
			 */
			detail: {
				codename: "detail",
				id: "89f4a3ad-c5e0-4633-88dc-0594c4ee50bc",
				externalId: undefined,
				name: "Detail",
				required: true,
				type: "text",
			},

			/**
			 * Label (text)
			 *
			 * A short, descriptive label for the contact information. Please include the colon, when appropriate. E.g. "Email us:"
			 */
			label: {
				codename: "label",
				id: "90d37e65-a692-4899-82a6-0ac82a76b6b2",
				externalId: undefined,
				name: "Label",
				required: true,
				type: "text",
			},

			/**
			 * Supporting text (text)
			 *
			 * Is there anything else the visitor needs to know? E.g. working hours
			 */
			supporting_text: {
				codename: "supporting_text",
				id: "c668e5e1-afb6-4c2d-894c-b74a539ca14f",
				externalId: undefined,
				name: "Supporting text",
				required: false,
				type: "text",
			},

			/**
			 * Type (multiple_choice)
			 *
			 * What type of contact information is this? We need to know this so we can present the information the right way.
			 */
			type: {
				codename: "type",
				id: "6a7b3ded-ff3b-4671-ba87-eb2fde0f294d",
				externalId: undefined,
				name: "Type",
				required: true,
				type: "multiple_choice",
				options: {
					address: {
						name: "Address",
						id: "21ffed46-dec6-4a67-8348-85911b27d0f1",
						codename: "address",
						externalId: undefined,
					},
					email: {
						name: "Email",
						id: "7f9d512c-d4f1-41c3-b55f-3cd9a09515a6",
						codename: "email",
						externalId: undefined,
					},
					link: {
						name: "Link",
						id: "4aaff32b-f737-4623-aef3-ceffef47b131",
						codename: "link",
						externalId: undefined,
					},
					phone: {
						name: "Phone",
						id: "00c96649-65fc-4225-9d11-efeb36f221de",
						codename: "phone",
						externalId: undefined,
					},
				},
			},
		},
	},

	/**
	 * CTA Banner
	 */
	cta_banner: {
		codename: "cta_banner",
		id: "2e31803c-6185-4b1d-aeac-479cbb03ad76",
		externalId: undefined,
		name: "CTA Banner",
		elements: {
			/**
			 * Description (text)
			 *
			 * Supporting text inside the banner, displayed as normal paragraph text.
			 */
			description: {
				codename: "description",
				id: "25099cf6-a10e-4706-bc87-19e80787c99c",
				externalId: undefined,
				name: "Description",
				required: true,
				type: "text",
			},

			/**
			 * Link (modular_content)
			 *
			 * The link that takes the visitor to the action itself.
			 */
			link: {
				codename: "link",
				id: "d40745fe-aa4a-4f62-b937-b9156ad1f91c",
				externalId: undefined,
				name: "Link",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * The title inside the banner, displayed as an H2.
			 */
			title: {
				codename: "title",
				id: "40436e3a-51e0-4c7f-8a47-7e7c05e0bd96",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Dictionary
	 */
	dictionary: {
		codename: "dictionary",
		id: "6839e58f-1ef2-491d-a245-133891c371a3",
		externalId: undefined,
		name: "Dictionary",
		elements: {
			/**
			 * Address label (text)
			 */
			address_label: {
				codename: "address_label",
				id: "18e65e19-0ecf-4df7-b3ec-c3e529f17296",
				externalId: undefined,
				name: "Address label",
				required: false,
				type: "text",
			},

			/**
			 * Banner Closed text (text)
			 *
			 * This message is shown when a shop does not have any open hours on the current day
			 */
			banner_closed_text: {
				codename: "banner_closed_text",
				id: "788388fa-59da-475c-b788-701c76bcd99d",
				externalId: undefined,
				name: "Banner Closed text",
				required: false,
				type: "text",
			},

			/**
			 * Banner Open text (text)
			 *
			 * This label precedes the shop's open hours on the current day
			 */
			banner_open_text: {
				codename: "banner_open_text",
				id: "0ad8c8f0-950e-4ba3-b3d0-d3d4d3f33466",
				externalId: undefined,
				name: "Banner Open text",
				required: false,
				type: "text",
			},

			/**
			 * Banner Temporarily Closed text (text)
			 *
			 * This message is shown when the "Shop status override" checkbox is checked to indicate that a shop is closed and it's not part of their normal schedule
			 */
			banner_temporarily_closed_text: {
				codename: "banner_temporarily_closed_text",
				id: "37a0f409-76fe-465d-9952-acff528d83c7",
				externalId: undefined,
				name: "Banner Temporarily Closed text",
				required: false,
				type: "text",
			},

			/**
			 * Email label (text)
			 */
			email_label: {
				codename: "email_label",
				id: "ae288c5c-421e-42ee-8caf-a1dcad8c2ff3",
				externalId: undefined,
				name: "Email label",
				required: false,
				type: "text",
			},

			/**
			 * Facebook Link label (text)
			 */
			facebook_link_label: {
				codename: "facebook_link_label",
				id: "ac579ca1-7fb9-43c2-b602-5214c58ed242",
				externalId: undefined,
				name: "Facebook Link label",
				required: false,
				type: "text",
			},

			/**
			 * Facebook Link text (text)
			 */
			facebook_link_text: {
				codename: "facebook_link_text",
				id: "8c3ae2b8-a576-44bb-aaab-8a761df5a6ba",
				externalId: undefined,
				name: "Facebook Link text",
				required: false,
				type: "text",
			},

			/**
			 * Friday label (text)
			 */
			friday_label: {
				codename: "friday_label",
				id: "c2ec4752-9444-49f8-b6f2-70579166e733",
				externalId: undefined,
				name: "Friday label",
				required: false,
				type: "text",
			},

			/**
			 * Language Selector label (text)
			 */
			language_selector_label: {
				codename: "language_selector_label",
				id: "b7151385-5e47-48ca-bb3f-5dee454251de",
				externalId: undefined,
				name: "Language Selector label",
				required: false,
				type: "text",
			},

			/**
			 * List Miles label (text)
			 *
			 * This label is used in the 'distance from you' indicator on each Shop item in the Shops searchable list
			 */
			list_miles_label: {
				codename: "list_miles_label",
				id: "04d00e7e-2587-4732-ab59-d0dc4f2b3596",
				externalId: undefined,
				name: "List Miles label",
				required: false,
				type: "text",
			},

			/**
			 * List See Details label (text)
			 *
			 * This label is appended immediately after the shop name, e.g. "[Canterbury] shop details"
			 */
			list_see_details_label: {
				codename: "list_see_details_label",
				id: "eaad173f-1d3f-4726-8816-6b0411582fd0",
				externalId: undefined,
				name: "List See Details label",
				required: false,
				type: "text",
			},

			/**
			 * Mobile nav label Back (text)
			 */
			mobile_nav_label_back: {
				codename: "mobile_nav_label_back",
				id: "1580a230-b4ad-4095-b978-423cf18ab5ab",
				externalId: undefined,
				name: "Mobile nav label Back",
				required: false,
				type: "text",
			},

			/**
			 * Mobile nav label Close (text)
			 */
			mobile_nav_label_close: {
				codename: "mobile_nav_label_close",
				id: "d2ffe87b-820b-407c-ab74-50dab8cdaa8a",
				externalId: undefined,
				name: "Mobile nav label Close",
				required: false,
				type: "text",
			},

			/**
			 * Mobile nav label Menu (text)
			 */
			mobile_nav_label_menu: {
				codename: "mobile_nav_label_menu",
				id: "34a329f6-5254-4134-8ad1-30b4a7db3b6a",
				externalId: undefined,
				name: "Mobile nav label Menu",
				required: false,
				type: "text",
			},

			/**
			 * Mobile nav label Search (text)
			 */
			mobile_nav_label_search: {
				codename: "mobile_nav_label_search",
				id: "c05530ed-f602-4825-9fbf-6638b907d00f",
				externalId: undefined,
				name: "Mobile nav label Search",
				required: false,
				type: "text",
			},

			/**
			 * Monday label (text)
			 */
			monday_label: {
				codename: "monday_label",
				id: "4e794971-1000-4d1f-9eae-94a7eb152646",
				externalId: undefined,
				name: "Monday label",
				required: false,
				type: "text",
			},

			/**
			 * Opening Hours Close (text)
			 *
			 * This label is used when a shop does not have any open hours in a day
			 */
			opening_hours_close: {
				codename: "opening_hours_close",
				id: "6b408752-1950-4802-b463-985a7bc42398",
				externalId: undefined,
				name: "Opening Hours Close",
				required: false,
				type: "text",
			},

			/**
			 * Opening Hours label (text)
			 *
			 * This label precedes the opening hours table
			 */
			opening_hours_label: {
				codename: "opening_hours_label",
				id: "b0337eb8-15f2-46f4-a651-77329015a591",
				externalId: undefined,
				name: "Opening Hours label",
				required: false,
				type: "text",
			},

			/**
			 * Pagination button Back (text)
			 */
			pagination_button_back: {
				codename: "pagination_button_back",
				id: "47c61f47-ea51-4381-a582-9a77884927ab",
				externalId: undefined,
				name: "Pagination button Back",
				required: false,
				type: "text",
			},

			/**
			 * Pagination button Next (text)
			 */
			pagination_button_next: {
				codename: "pagination_button_next",
				id: "16424deb-847b-4ea7-b4ff-ef0eb5c8994b",
				externalId: undefined,
				name: "Pagination button Next",
				required: false,
				type: "text",
			},

			/**
			 * Phone Number label (text)
			 */
			phone_number_label: {
				codename: "phone_number_label",
				id: "2a334945-4dea-4fef-8524-4bb7b6cf1de4",
				externalId: undefined,
				name: "Phone Number label",
				required: false,
				type: "text",
			},

			/**
			 * Saturday label (text)
			 */
			saturday_label: {
				codename: "saturday_label",
				id: "26f0bf0f-7ec1-4c89-bd7e-5acb4cfa0f38",
				externalId: undefined,
				name: "Saturday label",
				required: false,
				type: "text",
			},

			/**
			 * Search Results Count label (text)
			 *
			 * This label uses a token - {{results_count}} - to dynamically insert the total number of results
			 */
			search_results_count_label: {
				codename: "search_results_count_label",
				id: "d2468461-59b0-45bd-b8b7-0b9d59e38ab1",
				externalId: undefined,
				name: "Search Results Count label",
				required: false,
				type: "text",
			},

			/**
			 * Shop Contact Details label (text)
			 *
			 * This label precedes the shop's contact details section
			 */
			shop_contact_details_label: {
				codename: "shop_contact_details_label",
				id: "f36b3e12-bcad-4986-a93e-767743a7c7ea",
				externalId: undefined,
				name: "Shop Contact Details label",
				required: false,
				type: "text",
			},

			/**
			 * Skip Link label (text)
			 */
			skip_link_label: {
				codename: "skip_link_label",
				id: "18558114-0217-41cb-a8a8-a3eb6f3ad0ca",
				externalId: undefined,
				name: "Skip Link label",
				required: false,
				type: "text",
			},

			/**
			 * Sunday label (text)
			 */
			sunday_label: {
				codename: "sunday_label",
				id: "e89c6b17-b874-43ef-bf7f-b161fa3df3a0",
				externalId: undefined,
				name: "Sunday label",
				required: false,
				type: "text",
			},

			/**
			 * Thursday label (text)
			 */
			thursday_label: {
				codename: "thursday_label",
				id: "7832365b-716c-425d-9e6b-97ed3c570479",
				externalId: undefined,
				name: "Thursday label",
				required: false,
				type: "text",
			},

			/**
			 * Tuesday label (text)
			 */
			tuesday_label: {
				codename: "tuesday_label",
				id: "53468011-4201-490d-a00b-6af5dbe9526b",
				externalId: undefined,
				name: "Tuesday label",
				required: false,
				type: "text",
			},

			/**
			 * Wednesday label (text)
			 */
			wednesday_label: {
				codename: "wednesday_label",
				id: "149f7763-00dc-49b3-b7bc-09932ef59a05",
				externalId: undefined,
				name: "Wednesday label",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Donate CTA
	 */
	donate_cta: {
		codename: "donate_cta",
		id: "be91a547-c19e-40d3-ab30-c799cc73ba3a",
		externalId: undefined,
		name: "Donate CTA",
		elements: {
			/**
			 * Display (text)
			 *
			 * Enter the text you want to show for the link. It may be the same as the title of the page being linked to, or can be shorter and more direct if appropriate.
			 */
			display: {
				codename: "display",
				id: "f235c933-9452-4f79-8bbb-5b4c51294c11",
				externalId: undefined,
				name: "Display",
				required: true,
				type: "text",
			},

			/**
			 * External Link (text)
			 *
			 * Enter the full URL you want to link to, including the "https://" part.
			 */
			external_link: {
				codename: "external_link",
				id: "2cbdeb0d-5791-4969-83ca-78b24a0e9b12",
				externalId: undefined,
				name: "External Link",
				required: false,
				type: "text",
			},

			/**
			 * Internal Link (modular_content)
			 *
			 * ⚠️ This element is a work-in-progress, and currently has no effect.Choose or create a Page (or the Homepage) you want to link to.
			 */
			internal_link: {
				codename: "internal_link",
				id: "fe7e0421-7154-4e51-8c78-fc8cb141a61c",
				externalId: undefined,
				name: "Internal Link",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * File
	 */
	file: {
		codename: "file",
		id: "b40ebad4-385e-4cb5-8aec-294a826e4659",
		externalId: undefined,
		name: "File",
		elements: {
			/**
			 * File (asset)
			 */
			file: {
				codename: "file",
				id: "868b61c3-8057-456a-bd8b-116430a23d66",
				externalId: undefined,
				name: "File",
				required: true,
				type: "asset",
			},
		},
	},

	/**
	 * File List
	 */
	file_list: {
		codename: "file_list",
		id: "4f4ffe28-5248-40e5-895e-2f47bdd3c1c2",
		externalId: undefined,
		name: "File List",
		elements: {
			/**
			 * Files (modular_content)
			 */
			files: {
				codename: "files",
				id: "b49228f1-f3d3-4de8-967e-a631f198257b",
				externalId: undefined,
				name: "Files",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * An optional H2 title for this File List
			 */
			title: {
				codename: "title",
				id: "75f5cd4e-af05-458c-854d-8a8019c61c32",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Filter
	 */
	filter: {
		codename: "filter",
		id: "ed782d8d-6d40-41e0-928c-6b0ae41dd7bc",
		externalId: undefined,
		name: "Filter",
		elements: {
			/**
			 * Default (modular_content)
			 */
			default: {
				codename: "default",
				id: "dc53739b-688d-4a03-9f8d-105038455a0c",
				externalId: undefined,
				name: "Default",
				required: false,
				type: "modular_content",
			},

			/**
			 * Filters (modular_content)
			 */
			filters: {
				codename: "filters",
				id: "32e982ca-4813-48f3-8a1b-5bbb325552e0",
				externalId: undefined,
				name: "Filters",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * Footer
	 */
	footer: {
		codename: "footer",
		id: "89355c15-c3fb-4641-ba9b-0d4adea76510",
		externalId: undefined,
		name: "Footer",
		elements: {
			/**
			 * Copyright Text (text)
			 *
			 * This shows in the footer under the Copyright Title and includes our Royal Charter and registered charity details. We set this by default, out-of-the-box, and it's unlikely that you will need to change it.
			 */
			copyright_text: {
				codename: "copyright_text",
				id: "d8b84b82-6790-4b31-a9d9-436f8e166a31",
				externalId: undefined,
				name: "Copyright Text",
				required: false,
				type: "text",
			},

			/**
			 * Copyright Title (text)
			 *
			 * Copyright indicates who has legal control of the content on the website, which in our case is the British Red Cross. It shows as a bold line towards the bottom of the footer and the year will need to be changed each January.
			 */
			copyright_title: {
				codename: "copyright_title",
				id: "247e13a1-8118-49b7-8d8f-f7229141002f",
				externalId: undefined,
				name: "Copyright Title",
				required: false,
				type: "text",
			},

			/**
			 * Fundraising Regulator logo (asset)
			 *
			 * The Fundraising Regulator logo must be included to show that the British Red Cross follows the Code of Fundraising Practice. We set this by default, out-of-the-box, and it's unlikely that you will need to change it.
			 */
			icon: {
				codename: "icon",
				id: "d464efc4-13f0-4ca1-99e0-1c44a53caf82",
				externalId: undefined,
				name: "Fundraising Regulator logo",
				required: true,
				type: "asset",
			},

			/**
			 * Primary Links (modular_content)
			 *
			 * The Primary Links are the first row of links in the Footer that show in bold.
			 */
			primary_links: {
				codename: "primary_links",
				id: "3a84cea2-cae2-4261-a10a-17c47b13cd61",
				externalId: undefined,
				name: "Primary Links",
				required: false,
				type: "modular_content",
			},

			/**
			 * Secondary Links (modular_content)
			 *
			 * The Secondary Links are the second row of links in the Footer and are not in bold.
			 */
			secondary_links: {
				codename: "secondary_links",
				id: "307f161a-5b09-4ecc-a87c-fee4361fe00e",
				externalId: undefined,
				name: "Secondary Links",
				required: false,
				type: "modular_content",
			},

			/**
			 * Social media (multiple_choice)
			 *
			 * Tick the boxes below to show social media icons (with links) in the footer.
			 */
			social_media: {
				codename: "social_media",
				id: "d3f905e9-d5eb-48eb-9d98-1211b5eac501",
				externalId: undefined,
				name: "Social media",
				required: false,
				type: "multiple_choice",
				options: {
					facebook: {
						name: "Facebook",
						id: "19bf9917-5da4-4a9b-9e53-a3634cd358a0",
						codename: "facebook",
						externalId: undefined,
					},
					tiktok: {
						name: "Tiktok",
						id: "813c8a8e-e622-4d26-8664-57abb543c866",
						codename: "tiktok",
						externalId: undefined,
					},
					twitter: {
						name: "Twitter",
						id: "3440b4ca-a7fe-4086-86e3-327356017c20",
						codename: "twitter",
						externalId: undefined,
					},
					instagram: {
						name: "Instagram",
						id: "9c216ffa-1725-40c6-b35a-3adc6da21f12",
						codename: "instagram",
						externalId: undefined,
					},
					youtube: {
						name: "YouTube",
						id: "ae8b78b5-135c-4b3f-a0b0-93892217fa94",
						codename: "youtube",
						externalId: undefined,
					},
					linkedin: {
						name: "LinkedIn",
						id: "1aab3fea-a41c-45a3-a9fd-312221167a39",
						codename: "linkedin",
						externalId: undefined,
					},
				},
			},
		},
	},

	/**
	 * Header
	 */
	header: {
		codename: "header",
		id: "6f48233a-5e27-4e23-8f27-675f684ab1a4",
		externalId: undefined,
		name: "Header",
		elements: {
			/**
			 * CTA (modular_content)
			 *
			 * Select a Link to use for the top-right CTA, which is shown as a big button in the site's primary colour (usually red for BRC sites). This is the only place that ALL CAPS text is allowed.
			 */
			cta: {
				codename: "cta",
				id: "7f999a40-22d4-4c3b-a886-79a07cfe0adc",
				externalId: undefined,
				name: "CTA",
				required: false,
				type: "modular_content",
			},

			/**
			 * Hosted by link (text)
			 *
			 * If this site uses the 'Hosted by site logo', insert the URL for the main website's homepage. Leave blank if you don't want the 'hosted by' part to be shown.
			 */
			hosted_by_link: {
				codename: "hosted_by_link",
				id: "754ee3de-44c7-4c25-958a-001c84fa8686",
				externalId: undefined,
				name: "Hosted by link",
				required: false,
				type: "text",
			},

			/**
			 * Hosted by site logo (asset)
			 *
			 * If this site uses the 'hosted by' secondary logo, select the logo here. Leave blank if you don't want the 'hosted by' part to be shown.
			 */
			hosted_by_site_logo: {
				codename: "hosted_by_site_logo",
				id: "b1a94757-17a5-4fb8-b4ce-6363334eedc1",
				externalId: undefined,
				name: "Hosted by site logo",
				required: false,
				type: "asset",
			},

			/**
			 * Navigation (modular_content)
			 *
			 * Select the Navigation component for this site. You can use either a 'Navigation 1 Level' or a 'Navigation 2 Levels', but not a combination.
			 */
			navigation: {
				codename: "navigation",
				id: "c8e31004-35df-4ccc-9aab-de8c86f93bd6",
				externalId: undefined,
				name: "Navigation",
				required: false,
				type: "modular_content",
			},

			/**
			 * Site logo (asset)
			 *
			 * Select the site's logo here. If it's the default logo, it'll be in the "Platform assets" folder in the Assets library. If it's a custom logo, upload it into the folder for this site.
			 */
			site_logo: {
				codename: "site_logo",
				id: "e8765ef5-6b45-4143-b9c6-b6ce3a689ab1",
				externalId: undefined,
				name: "Site logo",
				required: true,
				type: "asset",
			},

			/**
			 * Site search (multiple_choice)
			 *
			 * This is a temporary version of the site-wide content search button in the header. For now, it sends all searches to Sitecore.
			 */
			site_search: {
				codename: "site_search",
				id: "bf4fd0bb-445d-4a18-9d7d-0997dc7b56f3",
				externalId: undefined,
				name: "Site search",
				required: false,
				type: "multiple_choice",
				options: {
					true: {
						name: "Display site search",
						id: "20a6e170-6064-468e-9a27-441cf646764c",
						codename: "true",
						externalId: undefined,
					},
					false: {
						name: "Hide site search",
						id: "307ee3e3-cd6e-4238-b288-eb9142059781",
						codename: "false",
						externalId: undefined,
					},
				},
			},
		},
	},

	/**
	 * Homepage
	 */
	homepage: {
		codename: "homepage",
		id: "f1a487e6-e262-45b8-aaa7-a4fef48d93fe",
		externalId: undefined,
		name: "Homepage",
		elements: {
			/**
			 * Content (modular_content)
			 *
			 * This is the flexible Content area, where you can choose or create your various content items. Items are shown in the order you put them in – you can drag-and-drop to rearrange them.
			 */
			content: {
				codename: "content",
				id: "8a7a614f-bd7e-4175-8dcd-6e97a7a08c51",
				externalId: undefined,
				name: "Content",
				required: true,
				type: "modular_content",
			},

			/**
			 * Dictionaries (modular_content)
			 */
			dictionaries: {
				codename: "dictionaries",
				id: "5fff4682-ac16-4a34-9beb-36b4386567fd",
				externalId: undefined,
				name: "Dictionaries",
				required: false,
				type: "modular_content",
			},

			/**
			 * Hero (modular_content)
			 *
			 * Choose or create an existing Homepage Hero. This will almost certainly be the first thing a user sees, so keep it relevant, easily understandable, and compelling. The Homepage Hero has a much larger size for the image on desktops, up to 2,000 pixels, so use a larger image if possible.
			 */
			hero: {
				codename: "hero",
				id: "cfbd0282-1744-469a-b302-b1cf2d2ad644",
				externalId: undefined,
				name: "Hero",
				required: true,
				type: "modular_content",
			},

			/**
			 * Meta Description (text)
			 *
			 * A page's Meta Description shows in search engines when users search for a related topic or page. It also shows in online link previews. It should be between 50 and 160 characters long.
			 */
			seo_snippet__meta_description: {
				codename: "seo_snippet__meta_description",
				id: "05c83f65-1903-43ca-b332-a10f515fa345",
				externalId: undefined,
				name: "Meta Description",
				required: true,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Image (asset)
			 *
			 * A page's Meta Image is shown in search engine results pages and link previews. If you do not choose a Meta Image for this page, the site-wide default image will be used instead.
			 */
			seo_snippet__meta_image: {
				codename: "seo_snippet__meta_image",
				id: "ab059ac6-acdb-4d20-966c-79082ba73735",
				externalId: undefined,
				name: "Meta Image",
				required: false,
				type: "asset",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Title (text)
			 *
			 * A page's Meta Title is shown in the user's browser tab, in link previews, and in search results. We provide a default meta title of '[Page Title] | [Site Name]'. For the main British Red Cross website, this will always end with ‘British Red Cross’. We suggest that you don’t customise the meta title, but if you need to for some reason, try to keep the ' | [Site Name]' part in some form.The meta title should be at least 30 characters, and ideally 50 to 60 characters, including the website name and spaces. For example: "Work abroad | World War 1 volunteers | British Red Cross" (56 characters)
			 */
			seo_snippet__meta_title: {
				codename: "seo_snippet__meta_title",
				id: "5524b125-4704-4b3c-bf8a-d56f4fa18744",
				externalId: undefined,
				name: "Meta Title",
				required: false,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Site Settings (modular_content)
			 */
			site_settings: {
				codename: "site_settings",
				id: "a499acbb-e786-4d15-9a3f-c0b46e5fdc40",
				externalId: undefined,
				name: "Site Settings",
				required: true,
				type: "modular_content",
			},

			/**
			 * Subpages (subpages)
			 *
			 * Subpages are Pages that directly below this Page in the content architecture. They are often section/landing pages, depending on the site’s size and architecture.
			 */
			subpages: {
				codename: "subpages",
				id: "2f1da24e-392f-4cf2-a5ce-0f68340b63b6",
				externalId: undefined,
				name: "Subpages",
				required: false,
				type: "subpages",
			},

			/**
			 * Title (text)
			 *
			 * This Title element is used in various SEO related meta-tags, and is combined with the Site name to generate the page's default <title> meta-tag. It is more public-facing than the 'Content item name', which is only used within the CMS to identify content items.
			 */
			title: {
				codename: "title",
				id: "2a6b6f98-1ee4-4398-aba7-605117255e38",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Homepage Hero
	 */
	homepage_hero: {
		codename: "homepage_hero",
		id: "78edcade-2bbd-43d0-92fb-838161b6fbfa",
		externalId: undefined,
		name: "Homepage Hero",
		elements: {
			/**
			 * Large image (asset)
			 *
			 * We ask you to provide your chosen image as in three sizes, Large, Medium, and Small, so we can use the best size for the viewer's screen. You can use the same item from the Asset library for all three. Use the image editor to get the best picture in all three sizes.Don’t use images that include text – that’s what the Text element is for.We ask for 2000x1125px to suit the most common desktop screen size, but we may stretch or shrink the image for the best fit.
			 */
			large_image: {
				codename: "large_image",
				id: "0b89abc4-06a1-49b9-9d39-9bbae88cc826",
				externalId: undefined,
				name: "Large image",
				required: true,
				type: "asset",
			},

			/**
			 * Link (modular_content)
			 *
			 * A link to another page or external URL. This will be a 'Primary CTA' link (which looks like a button) at the bottom of the Hero.
			 */
			link: {
				codename: "link",
				id: "aa4940ea-6f6c-4082-ac68-b183306d94b2",
				externalId: undefined,
				name: "Link",
				required: false,
				type: "modular_content",
			},

			/**
			 * Medium image (asset)
			 *
			 * A medium-sized image for tablets and smaller desktop browser windows. We ask for a width of 1023x575px.
			 */
			medium_image: {
				codename: "medium_image",
				id: "ebbd35c6-e3e5-4245-9c78-63d198db717c",
				externalId: undefined,
				name: "Medium image",
				required: true,
				type: "asset",
			},

			/**
			 * Small image (asset)
			 *
			 * The smallest image, for mobile devices and other small screens. We ask for a width of 768x576px.
			 */
			small_image: {
				codename: "small_image",
				id: "5e650826-6077-4c84-91e5-d57d4e546061",
				externalId: undefined,
				name: "Small image",
				required: true,
				type: "asset",
			},

			/**
			 * Text (text)
			 *
			 * Use this space for the text that will go in the red text box that appears on the hero. It will appear larger than normal text and should be brief and relevant. It may cover what the whole site is about for smaller sites, or relate to an appeal or campaign for larger sites. It will show as a slightly-larger-than-normal text, known as a <p> element.
			 */
			text: {
				codename: "text",
				id: "eb317ff0-6a88-4305-b45f-77ff581fe6c2",
				externalId: undefined,
				name: "Text",
				required: false,
				type: "text",
			},

			/**
			 * Text box alignment (multiple_choice)
			 *
			 * You can choose if your text box should align left or right when viewed on larger screens. Choose the side that will avoid having the text box cover up important parts of the image. (This does not apply for medium or small screens, where the text box is below the image.)
			 */
			text_box_alignment: {
				codename: "text_box_alignment",
				id: "fde6dea3-c0c8-4139-b062-cfd8e9f1c62c",
				externalId: undefined,
				name: "Text box alignment",
				required: true,
				type: "multiple_choice",
				options: {
					left: {
						name: "Left",
						id: "3d0daeac-fdf4-4760-aee3-79712332bf3c",
						codename: "left",
						externalId: undefined,
					},
					right: {
						name: "Right",
						id: "16b4a3f9-f19c-4b92-adcb-59e0a05db8a7",
						codename: "right",
						externalId: undefined,
					},
				},
			},

			/**
			 * Title (text)
			 *
			 * Enter the name of the website, or the primary Call-To-Action, here. It will show as a <h1> heading (the first and largest heading on a page). A page should always have exactly one <h1>, and this is it. It doesn't have to be the exactly same as the page title but should briefly describe what the site is about.
			 */
			title: {
				codename: "title",
				id: "e86f5847-fec3-473f-944f-61795a65e6c4",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Image
	 */
	image: {
		codename: "image",
		id: "89485ce2-72f7-49db-9d85-995473e5c11a",
		externalId: undefined,
		name: "Image",
		elements: {
			/**
			 * Caption (text)
			 *
			 * A caption consists of a few words that describe the image. You don’t have to have a caption with every image, but you can add one if needed to explain what is happening in the image or to enhance content on the page. Keep the caption brief but long enough to improve a user’s understanding of the image and/or page content.
			 */
			caption: {
				codename: "caption",
				id: "5e879df0-6264-4da7-9d80-f0cbfe2089ea",
				externalId: undefined,
				name: "Caption",
				required: false,
				type: "text",
			},

			/**
			 * Credit (text)
			 *
			 * The name and/or organisation that produced or supplied the image. (We add the "Credit:" part, so don't add it yourself.) The Credit field is not required and should be used only when we need to credit an image that doesn’t belong to the Red Cross.
			 */
			credit: {
				codename: "credit",
				id: "d6bcfd20-103a-4451-87b2-ea5f2335f6d7",
				externalId: undefined,
				name: "Credit",
				required: false,
				type: "text",
			},

			/**
			 * Source (asset)
			 *
			 * Choose or upload an image to display on the page. If you're uploading an image, try to use the right folder for your site in the Asset library.An image should be 1080×608px (an aspect ratio of 16:9) and less than 2 MB in size. It must be in one of the following file formats: jpeg, png, gif, or webp (these are called 'adjustable images'). You can only upload one image file at a time in this component – create another image component if you have more than one image for the page.
			 */
			source: {
				codename: "source",
				id: "a136dda1-1765-4956-b721-7efb313b27e9",
				externalId: undefined,
				name: "Source",
				required: true,
				type: "asset",
			},
		},
	},

	/**
	 * Jotform
	 */
	jotform: {
		codename: "jotform",
		id: "565beb0e-59dc-4669-ac6a-a13157ab37fe",
		externalId: undefined,
		name: "Jotform",
		elements: {
			/**
			 * Form ID (number)
			 *
			 * The numerical ID - from Jotform - for the form you want to embed
			 */
			form_id: {
				codename: "form_id",
				id: "3d08778d-fa07-4c86-9792-c86755606c6e",
				externalId: undefined,
				name: "Form ID",
				required: true,
				type: "number",
			},

			/**
			 * Title (text)
			 *
			 * The title/header shown above the form, displayed as an <h2>
			 */
			title: {
				codename: "title",
				id: "0d295da1-8631-4d5e-ba84-d2e67c06445d",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * KeyValuePair
	 */
	keyvaluepair: {
		codename: "keyvaluepair",
		id: "3095b2a8-117e-4890-84ff-4b633c2bdb44",
		externalId: undefined,
		name: "KeyValuePair",
		elements: {
			/**
			 * Key (text)
			 */
			key: {
				codename: "key",
				id: "c1fa1fae-9444-4783-9162-71f71d350091",
				externalId: undefined,
				name: "Key",
				required: true,
				type: "text",
			},

			/**
			 * Value (text)
			 */
			value: {
				codename: "value",
				id: "12fc1ee9-6b80-44b8-ab83-b8730c61a1bd",
				externalId: undefined,
				name: "Value",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Link
	 */
	link: {
		codename: "link",
		id: "8041514a-d102-411e-96f0-5f1718b29924",
		externalId: undefined,
		name: "Link",
		elements: {
			/**
			 * Display (text)
			 *
			 * Enter the text you want to show for the link. It may be the same as the title of the page being linked to, or can be shorter and more direct if appropriate.
			 */
			display: {
				codename: "display",
				id: "f5e5d5e6-f042-46a4-a837-ba08784c0d5f",
				externalId: undefined,
				name: "Display",
				required: true,
				type: "text",
			},

			/**
			 * External Link (text)
			 *
			 * Enter the full URL you want to link to, including the "https://" part.
			 */
			external_link: {
				codename: "external_link",
				id: "f46f0757-34e5-447e-8444-86623d16f461",
				externalId: undefined,
				name: "External Link",
				required: false,
				type: "text",
			},

			/**
			 * Internal Link (modular_content)
			 *
			 * ⚠️ This element is a work-in-progress, and currently has no effect.Choose or create a Page (or the Homepage) you want to link to.
			 */
			internal_link: {
				codename: "internal_link",
				id: "81e62be7-e503-435b-b28d-7c848bf5f28f",
				externalId: undefined,
				name: "Internal Link",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * Link Group
	 */
	link_group: {
		codename: "link_group",
		id: "dc2a9561-88a2-445e-9b29-389ff2364321",
		externalId: undefined,
		name: "Link Group",
		elements: {
			/**
			 * Links (modular_content)
			 *
			 * Choose (or create) the Links you want to display in this Link Group. There must be at least two, and they will be presented in the order they're in here. You can drag-and-drop to rearrange them.
			 */
			links: {
				codename: "links",
				id: "f44ca1b7-6201-45a5-896f-6073943dabdc",
				externalId: undefined,
				name: "Links",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * A short, descriptive title that tells the visitor what this Link Group is about/for.
			 */
			title: {
				codename: "title",
				id: "c21498d5-c2d2-42cb-bc1b-57ea57659363",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Link List
	 */
	link_list: {
		codename: "link_list",
		id: "2b47e0cd-31d9-4665-a6a3-fb2561e5e869",
		externalId: undefined,
		name: "Link List",
		elements: {
			/**
			 * Links (modular_content)
			 *
			 * Choose (or create) the Links you want to display in this Link List. There must be at least two, and they will be presented in the order they're in here. You can drag-and-drop to rearrange them.
			 */
			links: {
				codename: "links",
				id: "3dd35790-3dbf-498b-9b56-417f6c35871b",
				externalId: undefined,
				name: "Links",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * A short, descriptive title that tells the visitor what this Link List is about/for.
			 */
			title: {
				codename: "title",
				id: "6d84fbd9-8f9c-49b2-af49-043910ed1e7d",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Link List With Groups
	 */
	link_list_with_groups: {
		codename: "link_list_with_groups",
		id: "cb0165c0-8e94-4af6-a12a-c4ad8d9111e0",
		externalId: undefined,
		name: "Link List With Groups",
		elements: {
			/**
			 * Link Groups (modular_content)
			 *
			 * Choose (or create) the Link Groups you want to display in this Link List With Groups. There must be at least two, and they will be presented in the order they're in here. You can drag-and-drop to rearrange them.
			 */
			link_groups: {
				codename: "link_groups",
				id: "7888fd01-94c7-4cf8-8f39-7260299cec21",
				externalId: undefined,
				name: "Link Groups",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * A short, descriptive title that tells the visitor what this Link List With Groups is about/for.
			 */
			title: {
				codename: "title",
				id: "48a9763b-b942-4239-8399-4bb9c210b35e",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Logo Row
	 */
	_logo_row: {
		codename: "_logo_row",
		id: "4e06b9b5-f418-4ce5-b50f-9e0ced228893",
		externalId: undefined,
		name: "Logo Row",
		elements: {
			/**
			 * Leading text (text)
			 *
			 * Enter the words that will show on the site above the logos.
			 */
			leading_text: {
				codename: "leading_text",
				id: "a7d885f2-cc70-4ac1-8fc2-522f0f1277c4",
				externalId: undefined,
				name: "Leading text",
				required: false,
				type: "text",
			},

			/**
			 * Logos (asset)
			 *
			 * Each logo should be a square image with a minimum width and height of 160px. Use compressed images to save space.
			 */
			logos: {
				codename: "logos",
				id: "f74ed9e7-821c-41ad-b22d-403c7bfcf251",
				externalId: undefined,
				name: "Logos",
				required: true,
				type: "asset",
			},

			/**
			 * Logos  old  (modular_content)
			 *
			 * Each logo should be a square image with a minimum width and height of 160px. Use compressed images to save space.
			 */
			logos_old: {
				codename: "logos_old",
				id: "259cfa96-84e9-4a0c-a009-966aae8f1614",
				externalId: undefined,
				name: "Logos (old)",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * Map  Static
	 */
	map_static: {
		codename: "map_static",
		id: "04e93a44-ca1c-4d96-874a-b2aac878a64d",
		externalId: undefined,
		name: "Map (Static)",
		elements: {
			/**
			 * Address (text)
			 *
			 * Copy and paste this directly from Google Maps by opening the location, hovering over the address line, and clicking "Copy address". That should get you an adress in this format: "44 Moorfields, London EC2Y 9AL".
			 */
			address: {
				codename: "address",
				id: "bb47fff1-78b2-4332-a98f-554fb2dde665",
				externalId: undefined,
				name: "Address",
				required: true,
				type: "text",
			},

			/**
			 * Link text (text)
			 *
			 * This text forms the link off to Google Maps that sits below the map image
			 */
			link_text: {
				codename: "link_text",
				id: "d6fb55e4-ac1b-4c24-8595-661b0a69dd23",
				externalId: undefined,
				name: "Link text",
				required: true,
				type: "text",
			},

			/**
			 * Map image alt text (text)
			 *
			 * Alt text for the map image, to be shown if the image can't be loaded for some reason
			 */
			map_image_alt_text: {
				codename: "map_image_alt_text",
				id: "5da3bf0e-db76-4c92-a550-b6ae8c895953",
				externalId: undefined,
				name: "Map image alt text",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Menu Group
	 */
	_menu_group: {
		codename: "_menu_group",
		id: "7274e4c5-a7c2-480c-b1bd-c4ec27c23509",
		externalId: undefined,
		name: "Menu Group",
		elements: {
			/**
			 * Menu Items (modular_content)
			 *
			 * Add the menu item(s) you want this group to contain.
			 */
			menu_items: {
				codename: "menu_items",
				id: "24f9ab03-f00d-488b-9806-c30fb8ea46ca",
				externalId: undefined,
				name: "Menu Items",
				required: true,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 *
			 * Enter the text you want to show in the navigation bar for this group.
			 */
			title: {
				codename: "title",
				id: "abc9213d-8b0c-48a5-b8f2-3b6ffd97a448",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Menu Item
	 */
	menu_item: {
		codename: "menu_item",
		id: "f350bd49-8a2a-4346-9177-a20d48ace1a2",
		externalId: undefined,
		name: "Menu Item",
		elements: {
			/**
			 * External link (text)
			 */
			external_link: {
				codename: "external_link",
				id: "afe017a2-fd56-4417-aab9-88451ef82800",
				externalId: undefined,
				name: "External link",
				required: false,
				type: "text",
			},

			/**
			 * Internal link (modular_content)
			 *
			 * The page you want this menu item to link to.
			 */
			internal_link: {
				codename: "internal_link",
				id: "6595cde6-d39d-4d39-8ecf-96ed0321e582",
				externalId: undefined,
				name: "Internal link",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * Multi Column Layout
	 */
	_multi_column_layout: {
		codename: "_multi_column_layout",
		id: "ab37a71e-5ebe-41c4-8176-8884aa619888",
		externalId: undefined,
		name: "Multi-Column Layout",
		elements: {
			/**
			 * Column 1 (modular_content)
			 *
			 * Put the first grouping of content items here. On desktop and tablet screens, this grouping will be shown on the left. On mobile screens, where the content is rearranged into a single column, this grouping will be shown above the content in Column 2.
			 */
			column_1: {
				codename: "column_1",
				id: "172ebd78-93ba-4458-a0f5-49f96ed0a334",
				externalId: undefined,
				name: "Column 1",
				required: true,
				type: "modular_content",
			},

			/**
			 * Column 2 (modular_content)
			 *
			 * Put the second grouping of content items here. On desktop and tablet screens, this grouping will be shown on the right. On mobile screens, where the content is rearranged into a single column, this grouping will be shown after the content in Column 1.
			 */
			column_2: {
				codename: "column_2",
				id: "98ad97a7-e94a-446f-b34a-a7be94abb916",
				externalId: undefined,
				name: "Column 2",
				required: true,
				type: "modular_content",
			},

			/**
			 * Show bottom border (multiple_choice)
			 *
			 * You can decide if add a border at the bottom of this item, or not. The default is 'No', meaning it is not shown. Be careful when adding lots of items with bottom borders to a page, especially if they're close together, as too many can look bad.
			 */
			show_bottom_border: {
				codename: "show_bottom_border",
				id: "5a5128a0-b8ac-4a92-937d-80ec55041722",
				externalId: undefined,
				name: "Show bottom border",
				required: false,
				type: "multiple_choice",
				options: {
					true: {
						name: "Yes",
						id: "185ffeb3-73f1-42c5-935b-be11fcffc095",
						codename: "true",
						externalId: undefined,
					},
					false: {
						name: "No",
						id: "4e4f82f4-b465-43b4-b78f-bcff53dafc6f",
						codename: "false",
						externalId: undefined,
					},
				},
			},
		},
	},

	/**
	 * Navigation 1 Level
	 */
	_navigation_1_level: {
		codename: "_navigation_1_level",
		id: "329136b7-110c-4c60-a15b-b200c432700b",
		externalId: undefined,
		name: "Navigation 1 Level",
		elements: {
			/**
			 * Menu Items (modular_content)
			 *
			 * Choose or create some Menu Item items. In this Navigation 1 Level item, you cannot use Menu Group items.
			 */
			menu_items: {
				codename: "menu_items",
				id: "43dd2349-ca62-4171-8de1-545dfcc7365e",
				externalId: undefined,
				name: "Menu Items",
				required: true,
				type: "modular_content",
			},
		},
	},

	/**
	 * Navigation 2 Levels
	 */
	_navigation_2_levels: {
		codename: "_navigation_2_levels",
		id: "8fa8ccbc-7e1a-45de-8a03-fabf067896dd",
		externalId: undefined,
		name: "Navigation 2 Levels",
		elements: {
			/**
			 * Menu Groups (modular_content)
			 *
			 * Choose or create some Menu Group items. These are like sections in the navigation, they have titles and can be clicked to expand/close the group, but they are not actually links themselves.
			 */
			menu_groups: {
				codename: "menu_groups",
				id: "ab387164-a50e-4a2e-bb86-ad730959fd17",
				externalId: undefined,
				name: "Menu Groups",
				required: true,
				type: "modular_content",
			},
		},
	},

	/**
	 * Onetrust Cookie List
	 */
	_onetrust_cookie_list: {
		codename: "_onetrust_cookie_list",
		id: "388e65cf-629d-45a0-a118-dc7f4c86fe6f",
		externalId: undefined,
		name: "Onetrust Cookie List",
		elements: {},
	},

	/**
	 * OneTrust Manage Cookies link
	 */
	_onetrust_manage_cookies_link: {
		codename: "_onetrust_manage_cookies_link",
		id: "3d2e5ffa-ae88-4adf-8a30-a5e90be9526f",
		externalId: undefined,
		name: "OneTrust Manage Cookies link",
		elements: {},
	},

	/**
	 * Page
	 */
	page: {
		codename: "page",
		id: "aa6c396a-f52b-4730-a714-4490a6f07e26",
		externalId: undefined,
		name: "Page",
		elements: {
			/**
			 * Content (modular_content)
			 *
			 * This is where you can choose or create your various content items. Items are shown in the order you put them in, and you can drag and drop to rearrange them.
			 */
			content: {
				codename: "content",
				id: "272f7296-82ae-4b42-b3b0-c114c6d21e03",
				externalId: undefined,
				name: "Content",
				required: false,
				type: "modular_content",
			},

			/**
			 * External search visibility (multiple_choice)
			 */
			page_visibility__external_search: {
				codename: "page_visibility__external_search",
				id: "11e23157-fc44-45f8-8801-8c4ed8a4add5",
				externalId: undefined,
				name: "External search visibility",
				required: false,
				type: "multiple_choice",
				options: {
					true: {
						name: "Include in external search results",
						id: "e1183744-8f93-4ec1-b6a4-5f19dddbaa49",
						codename: "true",
						externalId: undefined,
					},
					false: {
						name: "Don t include in external search results",
						id: "bc508a81-f050-498f-9128-b44b20ecff1c",
						codename: "false",
						externalId: undefined,
					},
				},
				snippetCodename: "page_visibility",
			},

			/**
			 * Hero (modular_content)
			 *
			 * Choose or create an existing Basic or Section Hero. This will almost certainly be the first thing the user sees, so keep it relevant, easily understandable, and compelling.
			 */
			hero: {
				codename: "hero",
				id: "250bddb9-584f-49d0-8fce-93d05171775f",
				externalId: undefined,
				name: "Hero",
				required: true,
				type: "modular_content",
			},

			/**
			 * Internal search description (text)
			 *
			 * Leave blank to use the original page description.
			 */
			internal_search__description: {
				codename: "internal_search__description",
				id: "6aab341e-3985-4f94-a001-178a99559d81",
				externalId: undefined,
				name: "Internal search description",
				required: false,
				type: "text",

				snippetCodename: "internal_search",
			},

			/**
			 * Internal search title (text)
			 *
			 * Leave blank to use the original page title.
			 */
			internal_search__title: {
				codename: "internal_search__title",
				id: "ab8d7e05-b0ea-41a1-b258-5acb7015e5c9",
				externalId: undefined,
				name: "Internal search title",
				required: false,
				type: "text",

				snippetCodename: "internal_search",
			},

			/**
			 * Internal search visibility (multiple_choice)
			 */
			page_visibility__internal_search: {
				codename: "page_visibility__internal_search",
				id: "7999f1f9-d987-48b6-85fd-781d6bcda604",
				externalId: undefined,
				name: "Internal search visibility",
				required: false,
				type: "multiple_choice",
				options: {
					true: {
						name: "Include in internal search",
						id: "34aba784-a41a-4e34-9665-435e252f5f84",
						codename: "true",
						externalId: undefined,
					},
					false: {
						name: "Don t include in internal search",
						id: "afdb185f-8d12-404e-9110-528504aa85ab",
						codename: "false",
						externalId: undefined,
					},
				},
				snippetCodename: "page_visibility",
			},

			/**
			 * Meta Description (text)
			 *
			 * A page's Meta Description shows in search engines when users search for a related topic or page. It also shows in online link previews. It should be between 50 and 160 characters long.
			 */
			seo_snippet__meta_description: {
				codename: "seo_snippet__meta_description",
				id: "05c83f65-1903-43ca-b332-a10f515fa345",
				externalId: undefined,
				name: "Meta Description",
				required: true,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Image (asset)
			 *
			 * A page's Meta Image is shown in search engine results pages and link previews. If you do not choose a Meta Image for this page, the site-wide default image will be used instead.
			 */
			seo_snippet__meta_image: {
				codename: "seo_snippet__meta_image",
				id: "ab059ac6-acdb-4d20-966c-79082ba73735",
				externalId: undefined,
				name: "Meta Image",
				required: false,
				type: "asset",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Title (text)
			 *
			 * A page's Meta Title is shown in the user's browser tab, in link previews, and in search results. We provide a default meta title of '[Page Title] | [Site Name]'. For the main British Red Cross website, this will always end with ‘British Red Cross’. We suggest that you don’t customise the meta title, but if you need to for some reason, try to keep the ' | [Site Name]' part in some form.The meta title should be at least 30 characters, and ideally 50 to 60 characters, including the website name and spaces. For example: "Work abroad | World War 1 volunteers | British Red Cross" (56 characters)
			 */
			seo_snippet__meta_title: {
				codename: "seo_snippet__meta_title",
				id: "5524b125-4704-4b3c-bf8a-d56f4fa18744",
				externalId: undefined,
				name: "Meta Title",
				required: false,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Review date (taxonomy)
			 */
			common_taxonomies__review_date: {
				codename: "common_taxonomies__review_date",
				id: "ec391564-51c1-4c39-9dd1-d14ec03eef26",
				externalId: undefined,
				name: "Review date",
				required: false,
				type: "taxonomy",

				snippetCodename: "common_taxonomies",
			},

			/**
			 * Sitemap page visibility (multiple_choice)
			 */
			page_visibility__sitemap_page: {
				codename: "page_visibility__sitemap_page",
				id: "a720d5d2-bfdd-4c0c-bf42-0187941234d0",
				externalId: undefined,
				name: "Sitemap page visibility",
				required: false,
				type: "multiple_choice",
				options: {
					true: {
						name: "Show on Sitemap page",
						id: "437f7938-ec4a-4953-97e4-b702d365a0d8",
						codename: "true",
						externalId: undefined,
					},
					false: {
						name: "Hide on Sitemap page",
						id: "bce15b1a-5a5c-4168-859b-9f1ffb22bdf1",
						codename: "false",
						externalId: undefined,
					},
				},
				snippetCodename: "page_visibility",
			},

			/**
			 * Sitemap XML visibility (multiple_choice)
			 */
			page_visibility__sitemap_xml: {
				codename: "page_visibility__sitemap_xml",
				id: "ee9d915e-2f76-43ec-8442-dc90bb140c8c",
				externalId: undefined,
				name: "Sitemap XML visibility",
				required: false,
				type: "multiple_choice",
				options: {
					true: {
						name: "Include in sitemap xml",
						id: "6be089cf-17ca-4522-bf1d-20ab5564fa26",
						codename: "true",
						externalId: undefined,
					},
					false: {
						name: "Don t include in sitemap xml",
						id: "ecf6c77c-35da-4ca6-8009-4c3d660607e5",
						codename: "false",
						externalId: undefined,
					},
				},
				snippetCodename: "page_visibility",
			},

			/**
			 * Slug (url_slug)
			 *
			 * The Slug is the part of the web address specific to this item, which takes the form of: ‘example-page-slug’. It is automatically generated by the text in the Title element (above). The Slugs of parent items are not included here, as we calculate the full address when content is published. It can be overridden, but don't do that without checking with the Web Platform team first.
			 */
			slug: {
				codename: "slug",
				id: "ebe8ecdf-6fcf-4cfe-b85a-d53129ab29fc",
				externalId: undefined,
				name: "Slug",
				required: true,
				type: "url_slug",
			},

			/**
			 * Subpages (subpages)
			 *
			 * Subpages are the Pages directly below this Page in the content architecture. They often called child pages.
			 */
			subpages: {
				codename: "subpages",
				id: "55766f8a-fa8c-49d5-aca5-bd90a89a62dc",
				externalId: undefined,
				name: "Subpages",
				required: false,
				type: "subpages",
			},

			/**
			 * Teams (taxonomy)
			 */
			common_taxonomies__teams: {
				codename: "common_taxonomies__teams",
				id: "428a24bb-18be-4fc2-a51a-517f3d74ab19",
				externalId: undefined,
				name: "Teams",
				required: false,
				type: "taxonomy",

				snippetCodename: "common_taxonomies",
			},

			/**
			 * Title (text)
			 *
			 * Enter the page title here. It will be combined with the Site name to generate the page's URL and default <title> meta-tag. While the 'Content item name' is used only within the CMS to identify content items, the page title will be visible to users through the URL.
			 */
			title: {
				codename: "title",
				id: "6c3e7561-a0e9-4181-a411-8db145161765",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},

			/**
			 * Topics (taxonomy)
			 */
			common_taxonomies__topics: {
				codename: "common_taxonomies__topics",
				id: "d8911b28-e0f9-462f-a207-a58e441be8fc",
				externalId: undefined,
				name: "Topics",
				required: false,
				type: "taxonomy",

				snippetCodename: "common_taxonomies",
			},
		},
	},

	/**
	 * Page Hero
	 */
	hero_page: {
		codename: "hero_page",
		id: "ba7b1d15-7333-4093-838c-4c686a6c05a2",
		externalId: undefined,
		name: "Page Hero",
		elements: {
			/**
			 * Image (asset)
			 *
			 * Choose an image that illustrates or complements the text going on this page. (If uploading an image to the Asset library, please try to use the appropriate folder for your site.)
			 */
			image: {
				codename: "image",
				id: "132133eb-f821-43af-a6f4-b18f019c9eee",
				externalId: undefined,
				name: "Image",
				required: false,
				type: "asset",
			},

			/**
			 * Subtitle (text)
			 *
			 * The Subtitle shows in smaller type under the title and is known as an <h2>. You have room for slightly more text here, but briefer is better. If it's too long, consider using the Text element instead.
			 */
			subtitle: {
				codename: "subtitle",
				id: "f2a4c1db-3efb-41aa-8f74-dd80545783b1",
				externalId: undefined,
				name: "Subtitle",
				required: false,
				type: "text",
			},

			/**
			 * Text (text)
			 *
			 * Use this section for some more short text, if needed. It is OK to leave this blank if you said enough in the title and subtitle. It will show as a slightly-larger-than-normal text, known as a <p> element. If your Subtitle is too long, consider putting it here instead.
			 */
			text: {
				codename: "text",
				id: "3cd8b932-20e8-4c54-a595-2b34fd3b3c21",
				externalId: undefined,
				name: "Text",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Enter the page headline here, which will show as a <h1> heading (the first and largest heading on a page). A page should always have exactly one <h1>, and this is it. It doesn't have to be the same as the page title, you can be creative - as long as it stays short, relevant and helpful!
			 */
			title: {
				codename: "title",
				id: "aacff9a5-e234-4587-bf61-0444549603ad",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Press Release
	 */
	press_release: {
		codename: "press_release",
		id: "0e294c29-69e8-4aef-9d80-1ac219416131",
		externalId: undefined,
		name: "Press Release",
		elements: {
			/**
			 * About (modular_content)
			 */
			about: {
				codename: "about",
				id: "9bfe6ed1-31c5-4030-b2a1-ccbfd8c69161",
				externalId: undefined,
				name: "About",
				required: false,
				type: "modular_content",
			},

			/**
			 * Body text (rich_text)
			 */
			body_text: {
				codename: "body_text",
				id: "5e5eaf08-0b75-48a1-993c-4ceb70e6881a",
				externalId: undefined,
				name: "Body text",
				required: false,
				type: "rich_text",
			},

			/**
			 * Date (text)
			 *
			 * Please enter the desired date in the format of YYYY-MM-DD, e.g. 2024-12-25
			 */
			date: {
				codename: "date",
				id: "2d417579-5dad-4805-abfb-1e7f15028306",
				externalId: undefined,
				name: "Date",
				required: true,
				type: "text",
			},

			/**
			 * Featured image (asset)
			 */
			featured_image: {
				codename: "featured_image",
				id: "0f922af4-5c8c-4d54-82ae-da929ca4fefe",
				externalId: undefined,
				name: "Featured image",
				required: false,
				type: "asset",
			},

			/**
			 * Featured image caption (text)
			 */
			featured_image_caption: {
				codename: "featured_image_caption",
				id: "5b0eeaba-52e7-4a3e-a73b-5a248814bce0",
				externalId: undefined,
				name: "Featured image caption",
				required: false,
				type: "text",
			},

			/**
			 * Featured image credit (text)
			 */
			featured_image_credit: {
				codename: "featured_image_credit",
				id: "65956bf7-bb3d-4a1e-ba22-ac80cd91d3e0",
				externalId: undefined,
				name: "Featured image credit",
				required: false,
				type: "text",
			},

			/**
			 * Hero Text (text)
			 */
			hero_text: {
				codename: "hero_text",
				id: "47b5c599-514a-4c39-941c-fafd7705cb98",
				externalId: undefined,
				name: "Hero Text",
				required: false,
				type: "text",
			},

			/**
			 * Hero Title (text)
			 */
			hero_title: {
				codename: "hero_title",
				id: "083348b2-1a97-4dec-8e76-984066cfffb5",
				externalId: undefined,
				name: "Hero Title",
				required: true,
				type: "text",
			},

			/**
			 * Internal search description (text)
			 *
			 * Leave blank to use the original page description.
			 */
			internal_search__description: {
				codename: "internal_search__description",
				id: "6aab341e-3985-4f94-a001-178a99559d81",
				externalId: undefined,
				name: "Internal search description",
				required: false,
				type: "text",

				snippetCodename: "internal_search",
			},

			/**
			 * Internal search title (text)
			 *
			 * Leave blank to use the original page title.
			 */
			internal_search__title: {
				codename: "internal_search__title",
				id: "ab8d7e05-b0ea-41a1-b258-5acb7015e5c9",
				externalId: undefined,
				name: "Internal search title",
				required: false,
				type: "text",

				snippetCodename: "internal_search",
			},

			/**
			 * Meta Description (text)
			 *
			 * A page's Meta Description shows in search engines when users search for a related topic or page. It also shows in online link previews. It should be between 50 and 160 characters long.
			 */
			seo_snippet__meta_description: {
				codename: "seo_snippet__meta_description",
				id: "05c83f65-1903-43ca-b332-a10f515fa345",
				externalId: undefined,
				name: "Meta Description",
				required: true,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Image (asset)
			 *
			 * A page's Meta Image is shown in search engine results pages and link previews. If you do not choose a Meta Image for this page, the site-wide default image will be used instead.
			 */
			seo_snippet__meta_image: {
				codename: "seo_snippet__meta_image",
				id: "ab059ac6-acdb-4d20-966c-79082ba73735",
				externalId: undefined,
				name: "Meta Image",
				required: false,
				type: "asset",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Title (text)
			 *
			 * A page's Meta Title is shown in the user's browser tab, in link previews, and in search results. We provide a default meta title of '[Page Title] | [Site Name]'. For the main British Red Cross website, this will always end with ‘British Red Cross’. We suggest that you don’t customise the meta title, but if you need to for some reason, try to keep the ' | [Site Name]' part in some form.The meta title should be at least 30 characters, and ideally 50 to 60 characters, including the website name and spaces. For example: "Work abroad | World War 1 volunteers | British Red Cross" (56 characters)
			 */
			seo_snippet__meta_title: {
				codename: "seo_snippet__meta_title",
				id: "5524b125-4704-4b3c-bf8a-d56f4fa18744",
				externalId: undefined,
				name: "Meta Title",
				required: false,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Slug (url_slug)
			 *
			 * The Slug is the part of the web address specific to this item, which takes the form of: ‘example-page-slug’. It is automatically generated by the text in the Title element (above). The Slugs of parent items are not included here, as we calculate the full address when content is published. It can be overridden, but don't do that without checking with the Web Platform team first.
			 */
			slug: {
				codename: "slug",
				id: "d755e793-52da-44e6-ab49-67b2b4fa75a7",
				externalId: undefined,
				name: "Slug",
				required: true,
				type: "url_slug",
			},

			/**
			 * Teams (taxonomy)
			 */
			teams: {
				codename: "teams",
				id: "b36bcf49-868c-48bc-b7c5-3de7a2eca1f7",
				externalId: undefined,
				name: "Teams",
				required: false,
				type: "taxonomy",
			},

			/**
			 * Title (text)
			 *
			 * Enter the page title here. It will be combined with the Site name to generate the page's URL and default <title> meta-tag. While the 'Content item name' is used only within the CMS to identify content items, the page title will be visible to users through the URL.
			 */
			title: {
				codename: "title",
				id: "c0a2c4e1-05c8-4bf3-a711-f2ddda65c897",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},

			/**
			 * Topics (taxonomy)
			 */
			topics: {
				codename: "topics",
				id: "6833f5e4-2405-4d49-96b8-c2276d9a5b87",
				externalId: undefined,
				name: "Topics",
				required: false,
				type: "taxonomy",
			},
		},
	},

	/**
	 * Quote
	 */
	quote: {
		codename: "quote",
		id: "b1e8e018-56bd-4756-9767-a1f92f5f57f4",
		externalId: undefined,
		name: "Quote",
		elements: {
			/**
			 * Attribution (text)
			 */
			attribution: {
				codename: "attribution",
				id: "5dbd82bd-4bb4-4e7f-b2fc-9bd472b586f9",
				externalId: undefined,
				name: "Attribution",
				required: false,
				type: "text",
			},

			/**
			 * Quote (rich_text)
			 */
			quote: {
				codename: "quote",
				id: "b3b72a2d-4d44-4642-a090-c6ce4a585ebd",
				externalId: undefined,
				name: "Quote",
				required: false,
				type: "rich_text",
			},
		},
	},

	/**
	 * Redirect
	 */
	redirect: {
		codename: "redirect",
		id: "59b51239-a9cb-4ec8-b687-2ef6f13b906a",
		externalId: undefined,
		name: "Redirect",
		elements: {
			/**
			 * From (text)
			 *
			 * Enter the slug from page that you want to redirect from in this field. The slug is the last part of the URL, for instance, on this page, www.redcross.org.uk/first-aid/learn-first-aid, the slug would be learn-first-aid.
			 */
			slug: {
				codename: "slug",
				id: "cb8c7b17-9c41-41ce-bae8-737bb453513f",
				externalId: undefined,
				name: "From",
				required: true,
				type: "text",
			},

			/**
			 * Is Permanent (multiple_choice)
			 *
			 * Is this redirect permanent or temporary? Think carefully and choose intentionally, as the two are treated differently by search engines. For permanent redirects, search engines will eventually start to pay less attention to the "From" URL and more to the "To" URL that is the new destination. For temporary redirects, search engines will hold-off on that shift because they expect the "From" URL to be used again, or for the "To" URL to change.
			 */
			is_permanent: {
				codename: "is_permanent",
				id: "ce2a7b6d-3f86-4ff1-b75e-a9bbfb1d7216",
				externalId: undefined,
				name: "Is Permanent",
				required: true,
				type: "multiple_choice",
				options: {
					yes: {
						name: "Yes",
						id: "cb1a0643-00df-413e-bd94-c1ea861cc22e",
						codename: "yes",
						externalId: undefined,
					},
					no: {
						name: "No",
						id: "e06170ec-ad37-48d1-b0a4-41cebeffb537",
						codename: "no",
						externalId: undefined,
					},
				},
			},

			/**
			 * To (modular_content)
			 *
			 * Enter a link for the page you want to redirect to in this field. This may also be called the destination. If you don’t already have a link for this page, you will need to make one.
			 */
			to: {
				codename: "to",
				id: "b3f51168-e954-4a8b-8286-96922fbafca2",
				externalId: undefined,
				name: "To",
				required: true,
				type: "modular_content",
			},
		},
	},

	/**
	 * Rich Text
	 */
	rich_text: {
		codename: "rich_text",
		id: "dd664270-be88-451c-bb41-09d604f12170",
		externalId: undefined,
		name: "Rich Text",
		elements: {
			/**
			 * Text (rich_text)
			 *
			 * You can add some formatting, bulleted and numerical lists, and basic links.
			 */
			text: {
				codename: "text",
				id: "fbee5939-ff08-47ee-a49e-f4adc2705614",
				externalId: undefined,
				name: "Text",
				required: true,
				type: "rich_text",
			},
		},
	},

	/**
	 * Search
	 */
	search: {
		codename: "search",
		id: "e8e4627c-7560-4630-8ed8-8b96ee6c3828",
		externalId: undefined,
		name: "Search",
		elements: {
			/**
			 * Aria label (text)
			 */
			aria_label: {
				codename: "aria_label",
				id: "bd0b9921-ab36-4e20-b3ae-827da7c528a2",
				externalId: undefined,
				name: "Aria label",
				required: false,
				type: "text",
			},

			/**
			 * Description (text)
			 */
			description: {
				codename: "description",
				id: "64892e41-11b7-4f0e-92f4-705c8b6cedd8",
				externalId: undefined,
				name: "Description",
				required: false,
				type: "text",
			},

			/**
			 * Error text (text)
			 */
			error_text: {
				codename: "error_text",
				id: "9c76968e-0d4d-490d-929b-092c53cb9458",
				externalId: undefined,
				name: "Error text",
				required: false,
				type: "text",
			},

			/**
			 * Error title (text)
			 */
			error_title: {
				codename: "error_title",
				id: "1367aa53-1040-4ee3-8142-8e902425d32d",
				externalId: undefined,
				name: "Error title",
				required: false,
				type: "text",
			},

			/**
			 * Input Required error (text)
			 */
			input_required_error: {
				codename: "input_required_error",
				id: "fab2d37c-4588-4a30-bf3e-db606e9c8226",
				externalId: undefined,
				name: "Input Required error",
				required: false,
				type: "text",
			},

			/**
			 * No Results text (text)
			 */
			no_results_text: {
				codename: "no_results_text",
				id: "207fbe96-7777-4d9e-aaa6-b0695931e8fd",
				externalId: undefined,
				name: "No Results text",
				required: false,
				type: "text",
			},

			/**
			 * No Results title (text)
			 */
			no_results_title: {
				codename: "no_results_title",
				id: "923d286c-3b2a-4506-a293-b2b857c72f78",
				externalId: undefined,
				name: "No Results title",
				required: false,
				type: "text",
			},

			/**
			 * Search Button Text (text)
			 */
			search_button_text: {
				codename: "search_button_text",
				id: "cdd8e218-a8fc-4c62-9b9f-cba141b41d7f",
				externalId: undefined,
				name: "Search Button Text",
				required: false,
				type: "text",
			},

			/**
			 * Search Filters (modular_content)
			 */
			search_filters: {
				codename: "search_filters",
				id: "772d4752-de69-4f89-84f1-f06595668974",
				externalId: undefined,
				name: "Search Filters",
				required: false,
				type: "modular_content",
			},

			/**
			 * Title (text)
			 */
			title: {
				codename: "title",
				id: "50a1b8df-49ed-4c84-a7d3-1324aede4640",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},

			/**
			 * What type of content do you want to search  (multiple_choice)
			 */
			content_type_search: {
				codename: "content_type_search",
				id: "0324b2c4-6ce1-4cdd-ac0e-af00bbc6ad96",
				externalId: undefined,
				name: "What type of content do you want to search?",
				required: true,
				type: "multiple_choice",
				options: {
					all: {
						name: "All",
						id: "fc42b664-ceae-4e5a-9ec2-6dc81542f60a",
						codename: "all",
						externalId: undefined,
					},
					press_release: {
						name: "Press Releases",
						id: "7264db8c-8266-489e-9385-6b6ef4a26ce7",
						codename: "press_release",
						externalId: undefined,
					},
					shop: {
						name: "Shops",
						id: "a1b12462-d746-45ea-8554-aae453002017",
						codename: "shop",
						externalId: undefined,
					},
				},
			},
		},
	},

	/**
	 * Search Box
	 */
	search_box: {
		codename: "search_box",
		id: "d329a81b-df3c-420c-94e9-983cafa9527e",
		externalId: undefined,
		name: "Search Box",
		elements: {
			/**
			 * Button text (text)
			 *
			 * Text in the button that starts the search.
			 */
			button_text: {
				codename: "button_text",
				id: "bb4f468c-196d-4628-88c4-9cfff97cd0e2",
				externalId: undefined,
				name: "Button text",
				required: false,
				type: "text",
			},

			/**
			 * Input label (text)
			 *
			 * A user-facing label for the text input field in the Search Box. This should tell users what the input field will do.
			 */
			input_label: {
				codename: "input_label",
				id: "159aad95-57ab-460c-841b-cae7a0a9a244",
				externalId: undefined,
				name: "Input label",
				required: false,
				type: "text",
			},

			/**
			 * Search Url (text)
			 *
			 * The endpoint for the search service that the requests sent through this Search Box should go to.
			 */
			search_url: {
				codename: "search_url",
				id: "0bec7950-54cb-426d-8b77-b5661721c7eb",
				externalId: undefined,
				name: "Search Url",
				required: true,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Type in the title for the search bar here, keeping in mind that it should be short and clear. The Title is shown as an <h2> in the Search Box.
			 */
			title: {
				codename: "title",
				id: "61422833-3ca0-440a-8ab8-ee4bccef2b04",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Search Filter Select
	 */
	search_filter_select: {
		codename: "search_filter_select",
		id: "e44b89d2-7ddf-4f17-a98d-4e8d9679c67d",
		externalId: undefined,
		name: "Search Filter Select",
		elements: {
			/**
			 * Query String Key (text)
			 *
			 * The key used to pass the entered value to search. In the below URL the key value "Category" at the end would be used as the Query String Key. https://www.redcross.org.uk/shop/find-a-charity-shop?location=&suppress_validation=&location_long=&location_lat=&Category=books
			 */
			query_string_key: {
				codename: "query_string_key",
				id: "0daedfb5-eab3-4c2f-a55d-b77c898a5e5b",
				externalId: undefined,
				name: "Query String Key",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Title for the drop-downIn this filter used to limit the type of charity shop, the title would be Shop Typehttps://www.redcross.org.uk/shop/find-a-charity-shop
			 */
			title: {
				codename: "title",
				id: "2b01f9e0-d5ee-41c9-811b-fbdf86cee770",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},

			/**
			 * Values (modular_content)
			 *
			 * A list of key/value pairs used to generate the drop-down list. The key is shown in the drop-down list and the value is what's passed into the query stringIn the below URL the value "books" at the end would be stored as the value of the key/pair. https://www.redcross.org.uk/shop/find-a-charity-shop?location=&suppress_validation=&location_long=&location_lat=&Category=books
			 */
			values: {
				codename: "values",
				id: "011c7417-f39c-44e7-b5ae-c949e205e8ef",
				externalId: undefined,
				name: "Values",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * Search Filter Text
	 */
	search_filter_text: {
		codename: "search_filter_text",
		id: "836e529d-8c19-4fbd-bef3-b9573b86886a",
		externalId: undefined,
		name: "Search Filter Text",
		elements: {
			/**
			 * Placeholder (text)
			 *
			 * The placeholder used in text fieldIn this example, it would be "Search by keyword"https://www.redcross.org.uk/about-us/news-and-media/media-centre/press-releases
			 */
			placeholder: {
				codename: "placeholder",
				id: "4f5ca60a-af4b-4597-b8dd-25b4865af78d",
				externalId: undefined,
				name: "Placeholder",
				required: false,
				type: "text",
			},

			/**
			 * Query String Key (text)
			 *
			 * The key used to pass the entered value to search. In the below URL, we can see that the word "aid" has been searched for. The Query String Key in this example is "q"https://www.redcross.org.uk/about-us/news-and-media/media-centre/press-releases?q=aid
			 */
			query_string_key: {
				codename: "query_string_key",
				id: "222b745f-0698-4d8e-8b79-5c5a56187821",
				externalId: undefined,
				name: "Query String Key",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * The title above the text fieldIn this example, it would be "Search by keyword"https://www.redcross.org.uk/about-us/news-and-media/media-centre/press-releases
			 */
			title: {
				codename: "title",
				id: "ec50bf05-f4c3-47cd-ad4b-d658db4b30fb",
				externalId: undefined,
				name: "Title",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Shop
	 */
	shop: {
		codename: "shop",
		id: "af5e52a3-3cec-45c0-a35e-be6e8c4534c8",
		externalId: undefined,
		name: "Shop",
		elements: {
			/**
			 * Address (rich_text)
			 *
			 * The full address, to be presented to visitors.
			 */
			address: {
				codename: "address",
				id: "dafcd67c-ba74-4554-8dca-4d981fd3761d",
				externalId: undefined,
				name: "Address",
				required: true,
				type: "rich_text",
			},

			/**
			 * Content (modular_content)
			 */
			content: {
				codename: "content",
				id: "e742fcd8-8383-461a-b9b2-47e6c18862a8",
				externalId: undefined,
				name: "Content",
				required: false,
				type: "modular_content",
			},

			/**
			 * Email (text)
			 */
			email: {
				codename: "email",
				id: "8bb093d8-5bfc-41ef-8166-6cdbf11338fd",
				externalId: undefined,
				name: "Email",
				required: true,
				type: "text",
			},

			/**
			 * Facebook link (text)
			 */
			facebook_link: {
				codename: "facebook_link",
				id: "87024513-aa7f-4d36-9808-171704a3afbd",
				externalId: undefined,
				name: "Facebook link",
				required: false,
				type: "text",
			},

			/**
			 * Friday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			friday_open_hours: {
				codename: "friday_open_hours",
				id: "cbb2feda-1dc5-436c-b1b7-70cbbc1ea9df",
				externalId: undefined,
				name: "Friday open hours",
				required: false,
				type: "text",
			},

			/**
			 * Hero Text (text)
			 */
			hero_text: {
				codename: "hero_text",
				id: "04e758f5-27d0-493e-9d45-c90de16dbbd1",
				externalId: undefined,
				name: "Hero Text",
				required: false,
				type: "text",
			},

			/**
			 * Hero Title (text)
			 */
			hero_title: {
				codename: "hero_title",
				id: "7b652339-fb4c-47d8-ac9e-a9bad089a276",
				externalId: undefined,
				name: "Hero Title",
				required: true,
				type: "text",
			},

			/**
			 * Map  Static  address (text)
			 *
			 * Copy and paste this directly from Google Maps by opening the location, hovering over the address line, and clicking "Copy address". That should get you an adress in this format: "44 Moorfields, London EC2Y 9AL".
			 */
			map__static__address: {
				codename: "map__static__address",
				id: "65934fe5-d3ce-41e6-8a98-fec0a4e3590b",
				externalId: undefined,
				name: "Map (Static) address",
				required: true,
				type: "text",
			},

			/**
			 * Meta Description (text)
			 *
			 * A page's Meta Description shows in search engines when users search for a related topic or page. It also shows in online link previews. It should be between 50 and 160 characters long.
			 */
			seo_snippet__meta_description: {
				codename: "seo_snippet__meta_description",
				id: "05c83f65-1903-43ca-b332-a10f515fa345",
				externalId: undefined,
				name: "Meta Description",
				required: true,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Image (asset)
			 *
			 * A page's Meta Image is shown in search engine results pages and link previews. If you do not choose a Meta Image for this page, the site-wide default image will be used instead.
			 */
			seo_snippet__meta_image: {
				codename: "seo_snippet__meta_image",
				id: "ab059ac6-acdb-4d20-966c-79082ba73735",
				externalId: undefined,
				name: "Meta Image",
				required: false,
				type: "asset",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Meta Title (text)
			 *
			 * A page's Meta Title is shown in the user's browser tab, in link previews, and in search results. We provide a default meta title of '[Page Title] | [Site Name]'. For the main British Red Cross website, this will always end with ‘British Red Cross’. We suggest that you don’t customise the meta title, but if you need to for some reason, try to keep the ' | [Site Name]' part in some form.The meta title should be at least 30 characters, and ideally 50 to 60 characters, including the website name and spaces. For example: "Work abroad | World War 1 volunteers | British Red Cross" (56 characters)
			 */
			seo_snippet__meta_title: {
				codename: "seo_snippet__meta_title",
				id: "5524b125-4704-4b3c-bf8a-d56f4fa18744",
				externalId: undefined,
				name: "Meta Title",
				required: false,
				type: "text",

				snippetCodename: "seo_snippet",
			},

			/**
			 * Monday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			monday_open_hours: {
				codename: "monday_open_hours",
				id: "32d52f36-0b1c-43f4-841b-d8a71cc8de97",
				externalId: undefined,
				name: "Monday open hours",
				required: false,
				type: "text",
			},

			/**
			 * Open hours supporting text (rich_text)
			 *
			 * Unfortunately, we cannot set a default message in a Rich Text element. We recommend "Occasionally opening hours may change - please call us before making a special trip to the shop." for business-as-usual.
			 */
			open_hours_supporting_text: {
				codename: "open_hours_supporting_text",
				id: "0aea7cbc-39a3-487e-b739-e91e3cbdbf82",
				externalId: undefined,
				name: "Open hours supporting text",
				required: false,
				type: "rich_text",
			},

			/**
			 * Phone number (text)
			 */
			phone_number: {
				codename: "phone_number",
				id: "b6221262-4fd5-46c8-888a-d7e6a3b6ed47",
				externalId: undefined,
				name: "Phone number",
				required: false,
				type: "text",
			},

			/**
			 * Saturday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			saturday_open_hours: {
				codename: "saturday_open_hours",
				id: "cbadfe13-47de-42c1-b6b3-073efea44ee4",
				externalId: undefined,
				name: "Saturday open hours",
				required: false,
				type: "text",
			},

			/**
			 * Shop status override (multiple_choice)
			 *
			 * If a shop is temporarily closed, choose the "Temporarily closed" option and we will change the 'Open hours' banner shown to visitors. Leave blank for business-as-usual.
			 */
			shop_status_override: {
				codename: "shop_status_override",
				id: "469105c6-2b0c-4fd5-bc36-276fe7bbb231",
				externalId: undefined,
				name: "Shop status override",
				required: false,
				type: "multiple_choice",
				options: {
					temporarily_closed: {
						name: "Temporarily closed",
						id: "f3934f63-b8d8-4d21-bfcb-8e9ff0a8f36c",
						codename: "temporarily_closed",
						externalId: undefined,
					},
				},
			},

			/**
			 * Shop type (multiple_choice)
			 */
			shop_type: {
				codename: "shop_type",
				id: "c69f95e9-8b68-40f9-9d9f-d480fb6c4770",
				externalId: undefined,
				name: "Shop type",
				required: true,
				type: "multiple_choice",
				options: {
					general: {
						name: "General",
						id: "66c6f810-3579-4c12-97d5-0ebbe4893e69",
						codename: "general",
						externalId: undefined,
					},
					books: {
						name: "Books",
						id: "8d4f9986-9196-405f-9e2d-81f179697361",
						codename: "books",
						externalId: undefined,
					},
					bridal: {
						name: "Bridal",
						id: "412cf9ad-f69d-4ac5-9566-f2ff870a3380",
						codename: "bridal",
						externalId: undefined,
					},
					discount: {
						name: "Discount",
						id: "58efe4fc-88b3-4b36-918e-7ea650fac6b7",
						codename: "discount",
						externalId: undefined,
					},
					furniture___electrical: {
						name: "Furniture   electrical",
						id: "5f44e498-c188-450b-9ac6-0aaf7d2aaee9",
						codename: "furniture___electrical",
						externalId: undefined,
					},
					vintage___designer: {
						name: "Vintage   designer",
						id: "f582b31b-188d-4d45-9f16-8efbff0d3abe",
						codename: "vintage___designer",
						externalId: undefined,
					},
				},
			},

			/**
			 * Slug (url_slug)
			 *
			 * The Slug is the part of the web address specific to this item, which takes the form of: ‘example-page-slug’. It is automatically generated by the text in the Title element (above). The Slugs of parent items are not included here, as we calculate the full address when content is published. It can be overridden, but don't do that without checking with the Web Platform team first.
			 */
			slug: {
				codename: "slug",
				id: "dbe2e9db-a51b-4d55-aa26-eef44a47592c",
				externalId: undefined,
				name: "Slug",
				required: true,
				type: "url_slug",
			},

			/**
			 * Sunday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			sunday_open_hours: {
				codename: "sunday_open_hours",
				id: "762e20a0-bb0d-4429-ba13-01b0f69cfcf2",
				externalId: undefined,
				name: "Sunday open hours",
				required: false,
				type: "text",
			},

			/**
			 * Thursday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			thursday_open_hours: {
				codename: "thursday_open_hours",
				id: "1cdc5b90-8118-43cd-8bd5-0571ea6a028c",
				externalId: undefined,
				name: "Thursday open hours",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 *
			 * Enter the page title here. It will be combined with the Site name to generate the page's URL and default <title> meta-tag. While the 'Content item name' is used only within the CMS to identify content items, the page title will be visible to users through the URL.
			 */
			title: {
				codename: "title",
				id: "f962d877-7a5b-40d5-84d2-994f0ebda5cc",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},

			/**
			 * Tuesday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			tuesday_open_hours: {
				codename: "tuesday_open_hours",
				id: "b12d6898-6618-4545-aa48-4676be969547",
				externalId: undefined,
				name: "Tuesday open hours",
				required: false,
				type: "text",
			},

			/**
			 * Wednesday open hours (text)
			 *
			 * E.g. "9am - 5pm"
			 */
			wednesday_open_hours: {
				codename: "wednesday_open_hours",
				id: "97720fec-68b4-47f8-ae10-e91a104f8d13",
				externalId: undefined,
				name: "Wednesday open hours",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Shops dictionary
	 */
	shops_dictionary: {
		codename: "shops_dictionary",
		id: "b5a114d0-fb01-4b46-9f05-8f1b1ff983f9",
		externalId: undefined,
		name: "Shops dictionary",
		elements: {
			/**
			 * Address label (text)
			 */
			address_label: {
				codename: "address_label",
				id: "5a37cb34-6a64-404a-b14f-c0f6b12146b5",
				externalId: undefined,
				name: "Address label",
				required: true,
				type: "text",
			},

			/**
			 * Banner Closed text (text)
			 */
			banner_closed_text: {
				codename: "banner_closed_text",
				id: "cbc519c9-3e35-463b-a5a4-fc8b36a3db0f",
				externalId: undefined,
				name: "Banner Closed text",
				required: true,
				type: "text",
			},

			/**
			 * Banner Open text (text)
			 */
			banner_open_text: {
				codename: "banner_open_text",
				id: "c48d6d66-7292-474b-8655-be520aeeb9d4",
				externalId: undefined,
				name: "Banner Open text",
				required: true,
				type: "text",
			},

			/**
			 * Banner Temporarily Closed text (text)
			 */
			banner_temporarily_closed_text: {
				codename: "banner_temporarily_closed_text",
				id: "da7cea3c-7e9a-4822-b868-3b088a51eb3e",
				externalId: undefined,
				name: "Banner Temporarily Closed text",
				required: true,
				type: "text",
			},

			/**
			 * Email label (text)
			 */
			email_label: {
				codename: "email_label",
				id: "dc0a36fb-c745-4067-8715-b67e21989ecd",
				externalId: undefined,
				name: "Email label",
				required: true,
				type: "text",
			},

			/**
			 * Facebook Link label (text)
			 */
			facebook_link_label: {
				codename: "facebook_link_label",
				id: "21effbc3-724b-4c49-b586-034f79db469b",
				externalId: undefined,
				name: "Facebook Link label",
				required: true,
				type: "text",
			},

			/**
			 * Friday label (text)
			 */
			friday_label: {
				codename: "friday_label",
				id: "bd16da78-e85a-4bac-bc9f-92bece11375c",
				externalId: undefined,
				name: "Friday label",
				required: true,
				type: "text",
			},

			/**
			 * Monday label (text)
			 */
			monday_label: {
				codename: "monday_label",
				id: "c57a0c9f-2112-46a1-beb8-91beb467673e",
				externalId: undefined,
				name: "Monday label",
				required: true,
				type: "text",
			},

			/**
			 * Opening Hours Close (text)
			 */
			opening_hours_close: {
				codename: "opening_hours_close",
				id: "6674e10e-cb1e-4424-b755-5ddd52d7e8a3",
				externalId: undefined,
				name: "Opening Hours Close",
				required: true,
				type: "text",
			},

			/**
			 * Opening Hours label (text)
			 */
			opening_hours_label: {
				codename: "opening_hours_label",
				id: "496635dd-f60c-4d30-ba59-06f375c34639",
				externalId: undefined,
				name: "Opening Hours label",
				required: true,
				type: "text",
			},

			/**
			 * Phone Number label (text)
			 */
			phone_number_label: {
				codename: "phone_number_label",
				id: "fe8ff4b6-cdd1-4255-a668-b12473b94dcb",
				externalId: undefined,
				name: "Phone Number label",
				required: true,
				type: "text",
			},

			/**
			 * Saturday label (text)
			 */
			saturday_label: {
				codename: "saturday_label",
				id: "1c6d717b-9838-42aa-9142-357038b1c985",
				externalId: undefined,
				name: "Saturday label",
				required: true,
				type: "text",
			},

			/**
			 * Shop Contact Details label (text)
			 */
			shop_contact_details_label: {
				codename: "shop_contact_details_label",
				id: "7cdb636b-851b-4a87-90df-deb7e8089477",
				externalId: undefined,
				name: "Shop Contact Details label",
				required: true,
				type: "text",
			},

			/**
			 * Sunday label (text)
			 */
			sunday_label: {
				codename: "sunday_label",
				id: "5cf1e841-db44-4d38-94db-76b6a32577ee",
				externalId: undefined,
				name: "Sunday label",
				required: true,
				type: "text",
			},

			/**
			 * Thursday label (text)
			 */
			thursday_label: {
				codename: "thursday_label",
				id: "17f4ba24-05d1-4bd9-83ec-ce980f111d1b",
				externalId: undefined,
				name: "Thursday label",
				required: true,
				type: "text",
			},

			/**
			 * Tuesday label (text)
			 */
			tuesday_label: {
				codename: "tuesday_label",
				id: "b05cc9da-d407-48d7-ad03-25bdc42fe2d9",
				externalId: undefined,
				name: "Tuesday label",
				required: true,
				type: "text",
			},

			/**
			 * Wednesday label (text)
			 */
			wednesday_label: {
				codename: "wednesday_label",
				id: "7b6bae62-eede-4564-bc4c-8fc32adc05a2",
				externalId: undefined,
				name: "Wednesday label",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Site Settings
	 */
	site_settings: {
		codename: "site_settings",
		id: "9df62aa4-e58c-4c0a-8ee5-b37a8475c687",
		externalId: undefined,
		name: "Site Settings",
		elements: {
			/**
			 * 404 Error (modular_content)
			 *
			 * Select a Page to be used when a visitor encounters a 404 ('page not found') error.
			 */
			not_found_error: {
				codename: "not_found_error",
				id: "12847717-5477-4cbf-9809-45416f602414",
				externalId: undefined,
				name: "404 Error",
				required: true,
				type: "modular_content",
			},

			/**
			 * 500 Error (modular_content)
			 *
			 * Select a Page to be used when a visitor encounters a 500 error.
			 */
			internal_server_error: {
				codename: "internal_server_error",
				id: "87deb020-6480-4c15-8b96-c17452761bf5",
				externalId: undefined,
				name: "500 Error",
				required: true,
				type: "modular_content",
			},

			/**
			 * Apple touch icons (asset)
			 */
			site_favicon_snippet__n180x180: {
				codename: "site_favicon_snippet__n180x180",
				id: "820b2a70-6a88-4547-af5a-91c1ec77bf67",
				externalId: undefined,
				name: "Apple touch icons",
				required: true,
				type: "asset",

				snippetCodename: "site_favicon_snippet",
			},

			/**
			 * Browser favicons (asset)
			 */
			site_favicon_snippet__browser_favicons: {
				codename: "site_favicon_snippet__browser_favicons",
				id: "63d0b8c2-f420-43c8-807c-339dc58d3815",
				externalId: undefined,
				name: "Browser favicons",
				required: true,
				type: "asset",

				snippetCodename: "site_favicon_snippet",
			},

			/**
			 * Default meta image (asset)
			 *
			 * This will be used as the page preview or thumbnail when you share a link to a page, unless that page provides one itself.
			 */
			default_meta_image: {
				codename: "default_meta_image",
				id: "e4c74020-d648-43b7-a095-9ce72b2c2423",
				externalId: undefined,
				name: "Default meta image",
				required: false,
				type: "asset",
			},

			/**
			 * Desktop shortcut icons (asset)
			 */
			site_favicon_snippet__desktop_shortcut_icons: {
				codename: "site_favicon_snippet__desktop_shortcut_icons",
				id: "94307ef8-275e-480c-b1ef-cc20b11dc595",
				externalId: undefined,
				name: "Desktop shortcut icons",
				required: true,
				type: "asset",

				snippetCodename: "site_favicon_snippet",
			},

			/**
			 * Footer (modular_content)
			 *
			 * Select or create the 'Header' item for this site.
			 */
			footer: {
				codename: "footer",
				id: "c2c78cb6-72ec-4a20-aa9a-e674a9b89065",
				externalId: undefined,
				name: "Footer",
				required: true,
				type: "modular_content",
			},

			/**
			 * Google Tag Manager ID (text)
			 *
			 * Enter the Google Tag Manager ID only. The platform will wrap it in the necessary code.
			 */
			google_tag_manager_id: {
				codename: "google_tag_manager_id",
				id: "8b05c16d-6ec3-4df0-8486-6396744cccb9",
				externalId: undefined,
				name: "Google Tag Manager ID",
				required: false,
				type: "text",
			},

			/**
			 * Header (modular_content)
			 *
			 * Select or create the 'Header' item for this site.
			 */
			header: {
				codename: "header",
				id: "a1814f76-7903-4d6e-8426-51ee76441db5",
				externalId: undefined,
				name: "Header",
				required: true,
				type: "modular_content",
			},

			/**
			 * OneTrust ID (text)
			 *
			 * Enter the OneTrust ID only. The platform will wrap it in the necessary code.
			 */
			onetrust: {
				codename: "onetrust",
				id: "3f61a8fa-7602-445e-a4c2-c48a5f3928fb",
				externalId: undefined,
				name: "OneTrust ID",
				required: false,
				type: "text",
			},

			/**
			 * Pre footer (modular_content)
			 *
			 * Select or create items to insert into the 'pre-footer' region of the page. Allowed content types are 'Rich Text' and 'Logo Row'.
			 */
			pre_footer: {
				codename: "pre_footer",
				id: "2b0f4de0-bf3d-4ade-86b3-4168f3e2f291",
				externalId: undefined,
				name: "Pre-footer",
				required: false,
				type: "modular_content",
			},

			/**
			 * Robots txt (text)
			 *
			 * Enter plain text here and it will be used to generate a robots.txt file.
			 */
			robots_txt: {
				codename: "robots_txt",
				id: "2a31824c-1f4a-486f-926f-b9e3e9e1ea0b",
				externalId: undefined,
				name: "Robots.txt",
				required: true,
				type: "text",
			},

			/**
			 * Security txt (text)
			 *
			 * Please use the format below with appropriate values.Contact: mailto:digitalsupport@redcross.org.ukExpires: 2023-03-31T00:00:00.000ZPreferred-Languages: enCanonical: https://www.redcross.org.uk/security.txt
			 */
			security_txt: {
				codename: "security_txt",
				id: "cfff4cef-c046-46e3-88ac-ff5b4af87984",
				externalId: undefined,
				name: "Security.txt",
				required: false,
				type: "text",
			},

			/**
			 * Site name (text)
			 *
			 * This is meta information that helps search engines and other tools identify this site.
			 */
			site_name: {
				codename: "site_name",
				id: "9f97e512-3ccc-40b3-b5d0-274e68fd4c93",
				externalId: undefined,
				name: "Site name",
				required: true,
				type: "text",
			},

			/**
			 * Site URL (text)
			 *
			 * Enter the hostname for this site, including the 'https://' part. E.g. "https://example.redcross.org.uk"
			 */
			site_url: {
				codename: "site_url",
				id: "a5f32dec-388b-4430-9663-1b631c1ab856",
				externalId: undefined,
				name: "Site URL",
				required: true,
				type: "text",
			},

			/**
			 * Taskbar shortcut icons (asset)
			 */
			site_favicon_snippet__taskbar_shortcut_icons: {
				codename: "site_favicon_snippet__taskbar_shortcut_icons",
				id: "f9c88785-c85e-4437-8647-9120af4ece8e",
				externalId: undefined,
				name: "Taskbar shortcut icons",
				required: true,
				type: "asset",

				snippetCodename: "site_favicon_snippet",
			},

			/**
			 * Twitter handle (text)
			 *
			 * Enter the most relevant Twitter handle for the whole site, or leave it empty to use @britishredcross by default. This will be used in the "twitter:site" meta property.
			 */
			twitter_handle: {
				codename: "twitter_handle",
				id: "d6fab088-4c7a-427e-8c40-c95ea0d30c1f",
				externalId: undefined,
				name: "Twitter handle",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Sitemap
	 */
	sitemap: {
		codename: "sitemap",
		id: "4e614b7a-f6e3-41c3-8090-174370e7943c",
		externalId: undefined,
		name: "Sitemap",
		elements: {
			/**
			 * Home label (text)
			 */
			home_label: {
				codename: "home_label",
				id: "874c1568-3f9b-4138-9cf6-bf784ede2d6c",
				externalId: undefined,
				name: "Home label",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Table
	 */
	_table: {
		codename: "_table",
		id: "b3ac0b83-3010-4dff-b7df-fa920a4d868e",
		externalId: undefined,
		name: "Table",
		elements: {
			/**
			 * Table Rows (modular_content)
			 */
			table_rows: {
				codename: "table_rows",
				id: "62dd2825-30e4-4d32-8e8f-39babb664248",
				externalId: undefined,
				name: "Table Rows",
				required: false,
				type: "modular_content",
			},
		},
	},

	/**
	 * Vad Record
	 */
	_vad_record: {
		codename: "_vad_record",
		id: "17f1442f-d134-484c-a97f-42c777e0eacb",
		externalId: undefined,
		name: "Vad Record",
		elements: {
			/**
			 * Config (modular_content)
			 */
			config: {
				codename: "config",
				id: "6c0f2ad6-1bee-4b56-85f9-8a5c0570030c",
				externalId: undefined,
				name: "Config",
				required: true,
				type: "modular_content",
			},

			/**
			 * Record Url (text)
			 */
			record_url: {
				codename: "record_url",
				id: "4559b2d2-57d9-4123-ad68-ba406f18d7f2",
				externalId: undefined,
				name: "Record Url",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Vad Search
	 */
	vad_search: {
		codename: "vad_search",
		id: "da1aafe7-8305-4802-b657-bb68dafd724a",
		externalId: undefined,
		name: "Vad Search",
		elements: {
			/**
			 * Button text (text)
			 */
			button_text: {
				codename: "button_text",
				id: "0ae19322-802e-45b1-9fca-c59f347d33da",
				externalId: undefined,
				name: "Button text",
				required: false,
				type: "text",
			},

			/**
			 * Filters (modular_content)
			 */
			filters: {
				codename: "filters",
				id: "3a24e9b2-2974-46c2-95d4-9025b89fa7fb",
				externalId: undefined,
				name: "Filters",
				required: false,
				type: "modular_content",
			},

			/**
			 * Input label (text)
			 */
			input_label: {
				codename: "input_label",
				id: "9897be89-947b-493c-8db6-a2ce373ce144",
				externalId: undefined,
				name: "Input label",
				required: false,
				type: "text",
			},

			/**
			 * Search Url (text)
			 */
			search_url: {
				codename: "search_url",
				id: "c2d13f89-ca23-4d7e-8243-c82b04242551",
				externalId: undefined,
				name: "Search Url",
				required: true,
				type: "text",
			},

			/**
			 * Title (text)
			 */
			title: {
				codename: "title",
				id: "b3335e4c-7f39-4d3c-a4ca-95de418b1804",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * Video
	 */
	video: {
		codename: "video",
		id: "970bb670-3966-45e8-97ac-5580bc9f02e5",
		externalId: undefined,
		name: "Video",
		elements: {
			/**
			 * Caption (text)
			 *
			 * A caption consists of a few words that describe the video. You don’t have to have a caption with every video, but you can add one if needed to explain what is happening in the video or to enhance content on the page. Keep the caption brief but long enough to improve a user’s understanding of the video and/or page content.
			 */
			caption: {
				codename: "caption",
				id: "09f600a5-0e57-43d7-94d3-eedd3e075e5a",
				externalId: undefined,
				name: "Caption",
				required: false,
				type: "text",
			},

			/**
			 * Credit (text)
			 *
			 * The name and/or organisation that produced or supplied the video. (We add the "Credit:" part, so don't add it yourself.) The Credit field is not required and should be used only when we need to credit an image that doesn’t belong to the Red Cross.
			 */
			credit: {
				codename: "credit",
				id: "1eb21283-85df-4d3d-ae50-4aa95a74a918",
				externalId: undefined,
				name: "Credit",
				required: false,
				type: "text",
			},

			/**
			 * Title attribute (text)
			 */
			title_attribute: {
				codename: "title_attribute",
				id: "a5a144d4-0855-4519-ba35-2b4ab42ade56",
				externalId: undefined,
				name: "Title attribute",
				required: false,
				type: "text",
			},

			/**
			 * Video ID (text)
			 *
			 * The video ID is a string of numbers and letters. In this URL - "https://youtube.com/watch?v=PCNTMIcOMpE" - the video ID is "PCNTMIcOMpE".
			 */
			video_id: {
				codename: "video_id",
				id: "ed5087a9-4d0f-494b-89c6-3901d62a06af",
				externalId: undefined,
				name: "Video ID",
				required: true,
				type: "text",
			},
		},
	},

	/**
	 * X Profile
	 */
	x_profile: {
		codename: "x_profile",
		id: "41e8216b-5e81-4673-9a9d-915ed9bfb4dc",
		externalId: undefined,
		name: "X Profile",
		elements: {
			/**
			 * Description (text)
			 */
			description: {
				codename: "description",
				id: "5fe5a248-2733-4e03-b68e-1df2e8e502a8",
				externalId: undefined,
				name: "Description",
				required: false,
				type: "text",
			},

			/**
			 * Posts By label (text)
			 */
			posts_by_label: {
				codename: "posts_by_label",
				id: "58992cb1-792a-4e98-8caf-7042c5f1d08e",
				externalId: undefined,
				name: "Posts By label",
				required: false,
				type: "text",
			},

			/**
			 * Skip label (text)
			 */
			skip_label: {
				codename: "skip_label",
				id: "540bbb01-5cb5-4e89-ac94-74bd145489c8",
				externalId: undefined,
				name: "Skip label",
				required: false,
				type: "text",
			},

			/**
			 * Title (text)
			 */
			title: {
				codename: "title",
				id: "91e87cce-90a6-4a5b-a058-0ad2ddd22dce",
				externalId: undefined,
				name: "Title",
				required: true,
				type: "text",
			},

			/**
			 * X username (text)
			 *
			 * The username of the profile you want to embed
			 */
			x_username: {
				codename: "x_username",
				id: "247704d8-3547-448a-a625-345eec1864d1",
				externalId: undefined,
				name: "X username",
				required: true,
				type: "text",
			},
		},
	},
} as const;
