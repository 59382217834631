import React from "react";
import { Link } from "../../atoms/link/link";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { isAbsoluteUrl } from "../../../utils/is-absolute-url";
import { type ResultsListItemProps } from "./results-list-item.types";

export function ResultsListItem({
	date,
	url,
	title,
	description,
}: ResultsListItemProps): React.JSX.Element {
	return (
		<li className="max-content mx-auto grid grid-cols-12 gap-4 border-b border-grey-20 lg:border lg:mb-8">
			<div className="col-span-12 col-start-1 px-4 py-6 md:px-6 md:text-base lg:col-span-8">
				{date ? (
					<time dateTime={date} className="block mb-2">
						{date}
					</time>
				) : null}
				<Link destination={isAbsoluteUrl(url) ? url : `/${url}`}>{title}</Link>
				<div className="mt-2">
					<PlainText size={PlainTextSize.Default}>{description}</PlainText>
				</div>
			</div>
		</li>
	);
}
