"use client";
import React, { createContext, useContext } from "react";
import type { Store } from "./store.types";
import { useVadApi } from "./use-vad-api";

const StoreContext = createContext<Store>(undefined!);

export const StoreProvider: React.FC<{
	children: any;
	vadSearchApiUrl?: string;
	vadRecordApiUrl?: string;
}> = ({ children, vadSearchApiUrl, vadRecordApiUrl }) => {
	const vadApi = useVadApi({
		searchUrl: vadSearchApiUrl,
		recordUrl: vadRecordApiUrl,
	});

	return (
		<StoreContext.Provider
			value={{
				vadApi,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};

export const useStore = () => useContext(StoreContext);
