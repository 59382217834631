"use client";
import React from "react";
import { Image as ImageBrc } from "@britishredcross/component-library/src/components/atoms/image/image";
import { HeroHomepage as HeroHomepageBrc } from "@britishredcross/component-library/src/components/molecules/hero-homepage/hero-homepage";
import { LinkType } from "@britishredcross/component-library/src/types";
import { ContentBoxPosition } from "@britishredcross/component-library/src/components/molecules/hero-homepage/hero-homepage.types";
import {
	useResizeImage,
	IMAGE_CONTEXT_HOME_HERO,
} from "@/hooks/use-resize-image";
import { resolveLink } from "@/components/header";

function HomepageHero(props) {
	const boxAlignment =
		props.linkedItems[0].elements.text_box_alignment.value[0].name;
	const contentBox = ContentBoxPosition[boxAlignment];

	let link;

	if (props.linkedItems[0].elements.link?.linkedItems[0]) {
		link = resolveLink(
			props.linkedItems[0].elements.link?.linkedItems[0],
			LinkType.ButtonLinkDarkBg,
			props.mappings,
			`key_${props.linkedItems[0].elements.link?.linkedItems[0]?.system?.codename}`
		);
	}

	const getImage = (size: "small" | "medium" | "large") => {
		const imageProp = props.linkedItems[0].elements[`${size}_image`].value[0];
		const resizedImage = useResizeImage(
			imageProp?.url ?? "",
			IMAGE_CONTEXT_HOME_HERO
		);
		return (
			<ImageBrc
				alt={imageProp?.description ?? ""}
				height={resizedImage.height}
				src={resizedImage.url}
				width={resizedImage.width}
				loading="eager"
			/>
		);
	};

	return (
		<HeroHomepageBrc
			title={props.linkedItems[0].elements.title.value}
			text={props.linkedItems[0].elements.text.value}
			{...(link && { link })}
			imageSm={getImage("small")}
			imageMd={getImage("medium")}
			imageLg={getImage("large")}
			contentBox={contentBox}
		/>
	);
}

export default HomepageHero;
