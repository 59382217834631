import React from "react";
import { type UseFormRegister } from "react-hook-form";
import { APIProvider } from "@vis.gl/react-google-maps";
import { TextInput } from "../../atoms/text-input/text-input";
import { FieldType } from "../../atoms/text-input/text-input.types";
import { SelectInput } from "../../atoms/select-input/select-input";
import { LocationInput } from "../../atoms/location-input/location-input";

export const getFilterElements = (
	filters: any[],
	registerFormField: UseFormRegister<Record<string, unknown>>,
	searchQuery?: Record<string, unknown>
): JSX.Element[] => {
	const elements: JSX.Element[] = [];

	filters.forEach((filter) => {
		const label = filter.elements.title.value;
		const isRequired =
			filter.elements.is_required?.value[0]?.codename === "required";
		const id = filter.system.id;
		const fieldName = filter.elements.query_string_key.value;
		const prepopValue = (searchQuery?.[fieldName] as string) ?? undefined;

		switch (filter.system.type) {
			case "search_filter_text":
				elements.push(
					<TextInput
						{...registerFormField(fieldName)}
						disabled={false}
						error={false}
						fieldType={FieldType.Text}
						key={id}
						label={label}
						required={isRequired}
						defaultValue={prepopValue}
						pattern={isRequired ? "^.*\\S.*$" : undefined}
						title={isRequired ? "Please enter a search query" : undefined}
					/>
				);
				break;

			case "search_filter_select":
				elements.push(
					<SelectInput
						key={id}
						label={label}
						options={filter.elements.values.linkedItems.map((option) => {
							return {
								value: option.elements.key.value,
								label: option.elements.value.value,
							};
						})}
						required={isRequired}
						onChange={(value) =>
							registerFormField(fieldName).onChange({ target: { value } })
						}
						onBlur={registerFormField(fieldName).onBlur}
						name={registerFormField(fieldName).name}
						ref={registerFormField(fieldName).ref}
						selectedValue={prepopValue ?? undefined}
						dir="ltr"
					/>
				);
				break;

			case "search_filter_location":
				elements.push(
					<APIProvider
						apiKey={process.env.NEXT_PUBLIC_GOOGLE_STATIC_MAPS_API_KEY ?? ""}
					>
						<LocationInput
							{...registerFormField(fieldName)}
							disabled={false}
							error={false}
							key={id}
							label={label}
							required={isRequired}
							defaultValue={prepopValue}
						/>
					</APIProvider>
				);
				break;
		}
	});

	return elements;
};
