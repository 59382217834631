import React from "react";
import { Link } from "../../atoms/link/link";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { isAbsoluteUrl } from "../../../utils/is-absolute-url";
import { type ShopListItemProps } from "./shop-list-item.types";

export function ShopListItem({
	title,
	distance,
	shopType,
	isTemporarilyClosed = false,
	temporarilyClosedLabel,
	todaysOpeningHours,
	shopDetailsLabel,
	shopUrl,
	milesLabel,
	closedLabel,
}: ShopListItemProps): React.JSX.Element {
	return (
		<li className="max-content mx-auto grid grid-cols-12 gap-4 lg:mb-8">
			<div className="col-span-12 col-start-1 px-4 py-6 border-b border-grey-20 md:text-base lg:p-6 lg:col-span-8 lg:border">
				<div className="flex items-center justify-between mb-3">
					<Heading level={HeadingLevel.H3}>{title}</Heading>
					{distance ? (
						<PlainText>
							<>
								{`${distance} ${milesLabel}`}
								<span className="sr-only">{" away"}</span>
							</>
						</PlainText>
					) : null}
				</div>
				{shopType !== "General" ? (
					<div className="flex items-center mb-3 [&>*:first-child]:mr-[10px] [&>*:first-child]:rtl:mr-0 [&>*:first-child]:rtl:ml-[10px]">
						<Icon
							name="local_offer"
							size={20}
							colour={IconColour.PrimaryBlack}
						/>
						<PlainText
							size={PlainTextSize.Default}
						>{`${shopType} shop`}</PlainText>
					</div>
				) : null}
				<div className="flex items-center mb-3 [&>*:first-child]:mr-[10px] [&>*:first-child]:rtl:mr-0 [&>*:first-child]:rtl:ml-[10px]">
					<Icon
						name="schedule"
						size={20}
						colour={IconColour.PrimaryBlack}
						aria-hidden="true"
					/>
					<PlainText size={PlainTextSize.Default}>
						{isTemporarilyClosed
							? temporarilyClosedLabel
							: todaysOpeningHours
								? todaysOpeningHours
								: closedLabel}
					</PlainText>
				</div>
				<Link destination={isAbsoluteUrl(shopUrl) ? shopUrl : `/${shopUrl}`}>
					{`${title} ${shopDetailsLabel}`}
				</Link>
			</div>
		</li>
	);
}
