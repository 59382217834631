"use client";
import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type VideoProps } from "./video.types";

export function Video({ youtubeId, caption, credit }: VideoProps) {
	const figCaption =
		caption +
		(caption && credit ? " " : "") +
		(credit ? `(Credit: ${credit})` : "");
	const viewTrackingParam = "enablejsapi=1";
	return (
		<div className="brc-video w-full max-content mx-auto px-4 mb-8 md:mb-12 lg:mb-16 lg:px-0">
			<figure>
				<div className="mb-4">
					<LiteYouTubeEmbed
						id={youtubeId}
						title="Play Video"
						params={viewTrackingParam}
					/>
				</div>
				<figcaption>
					<PlainText size={PlainTextSize.SmallPrint}>{figCaption}</PlainText>
				</figcaption>
			</figure>
		</div>
	);
}
