"use client";

import React, { forwardRef, useState } from "react";
import { PlainText } from "../plain-text/plain-text";
import { PlainTextSize } from "../plain-text/plain-text.types";
import { type SelectInputProps } from "./select-input.types";

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
	(
		{
			label,
			options,
			selectedValue,
			required,
			onChange,
			disabled,
			error,
			...rest
		},
		ref
	) => {
		const [currentValue, setCurrentValue] = useState<string>(
			selectedValue ?? ""
		);

		const labelCleaned = label.replace(/\s/g, "");

		return (
			<div className="w-full relative md:mr-6">
				<label htmlFor={labelCleaned} className="block text-sm">
					{label} {required ? "*" : null}
				</label>
				<select
					className="brc-form-select appearance-none bg-no-repeat w-full rounded-none focus:border-1 focus:border-green-secondary focus:brc-shadow-fieldfocus focus:outline-0 mb-4 h-12 p-y2.5 px-4 text-sm text-center border border-solid border-black-primary md:min-w-max md:text-left"
					disabled={disabled}
					id={labelCleaned}
					name={labelCleaned}
					onChange={(e) => {
						const value = e.target.value;
						setCurrentValue(value);
						onChange ? onChange(value) : null;
					}}
					ref={ref}
					value={currentValue ?? ""}
					required={required}
					{...rest}
					dir="ltr"
				>
					{options.map((option, index) => {
						return (
							<option
								className="text-xs"
								key={`${labelCleaned}_option_${index}`}
								value={option.value}
							>
								{option.label}
							</option>
						);
					})}
				</select>
				{error ? (
					<div className="mt-1 prose-p:text-xs prose-p:text-red-primary">
						<PlainText size={PlainTextSize.SmallPrint}>
							This field is required.
						</PlainText>
					</div>
				) : null}
			</div>
		);
	}
);
