"use client";
import { useEffect, useState } from "react";

export const useLanguageDirection = (): boolean => {
	const [isRTL, setIsRTL] = useState<boolean>(false);

	useEffect(() => {
		const direction = document.documentElement.getAttribute("dir");
		setIsRTL(direction === "rtl");
	}, []);

	return isRTL;
};
