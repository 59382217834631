"use client";
import React, { useState } from "react";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { RichText } from "../../atoms/rich-text/rich-text";
import { type AccordionProps, type AccordionSection } from "./accordion.types";

function AccordionSections({ section }: { section: AccordionSection }) {
	const [open, setOpen] = useState(false);
	const [iconColor, setIconColor] = useState(IconColour.NeutralGrey40);
	const [isButtonFocused, setIsButtonFocused] = useState(false);

	const toggleAccordion = () => {
		setOpen(!open);
	};

	const handleMouseEnter = () => {
		setIconColor(IconColour.NeutralWhite);
	};

	const handleMouseLeave = () => {
		if (isButtonFocused) {
			setIconColor(IconColour.SecondaryGreen);
		} else {
			setIconColor(IconColour.NeutralGrey40);
		}
	};

	const handleFocus = () => {
		setIconColor(IconColour.SecondaryGreen);
		setIsButtonFocused(true);
	};

	const handleBlur = () => {
		setIsButtonFocused(false);
		setIconColor(IconColour.NeutralGrey40);
	};

	return (
		<div className="border-b border-grey-40">
			<details>
				<summary
					onClick={toggleAccordion}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onFocus={handleFocus}
					onBlur={handleBlur}
					className="group list-none w-full pointer-events-auto py-4 px-6 text-left text-sm hover:text-white hover:bg-blue-secondary focus:text-white focus:bg-blue-secondary focus:outline-none md:text-base brc-no-summary-triangle"
				>
					<div className="flex w-full items-start justify-between px-1 py-0.5 border-2 border-transparent group-focus:border-green-primary">
						{section.sectionTitle}
						<span className="ml-6 flex h-7 items-center text-sm md:text-base rtl:ml-0 rtl:mr-6">
							<Icon
								name={open ? "chevron_up" : "chevron_down"}
								size={24}
								colour={iconColor ? iconColor : IconColour.NeutralGrey40}
							/>
						</span>
					</div>
				</summary>
				<div className="py-4 px-7 border-t border-grey-40 brc-column-layout brc-accordion-section-text">
					<RichText>{section.sectionContent}</RichText>
				</div>
			</details>
		</div>
	);
}

export function Accordion({ title, sections }: AccordionProps) {
	return (
		<div className="max-content grid grid-cols-12 gap-4 mx-4 md:mx-auto mb-8 md:mb-12 lg:mb-16">
			<div className="max-w-full col-span-12 col-start-1 md:col-span-10 lg:col-span-8">
				{title ? (
					<h2 className="text-3xl font-bold mb-4 md:text-4xl md:mb-6">
						{title}
					</h2>
				) : null}
				<section className="border-2 border-b-1 border-solid border-grey-40 focus:border-green-primary active:hover:border-green-primary focus-within:border-green-primary">
					{sections.map((section, index) => (
						<AccordionSections key={index} section={section} />
					))}
				</section>
			</div>
		</div>
	);
}
