import React from "react";
import { SearchResultsCount } from "@britishredcross/component-library/src/components/molecules/search-results-count/search-results-count";
import { useDictionary } from "@/app/context/dictionary.context";

interface ResultsCountProps {
	rangeMin: number;
	rangeMax: number;
	total: number;
}

export function ResultsCount({
	rangeMin,
	rangeMax,
	total,
}: ResultsCountProps): JSX.Element {
	const dictionaryData = useDictionary();

	return (
		<SearchResultsCount
			rangeMin={rangeMin}
			rangeMax={rangeMax}
			total={total}
			resultsCountLabel={
				dictionaryData?.elements.search_results_count_label.value ?? ""
			}
		/>
	);
}
