"use client";
import React from "react";
import { CardBasic as CardBrc } from "@britishredcross/component-library/src/components/molecules/card-basic/card-basic";
import { Image as ImageBrc } from "@britishredcross/component-library/src/components/atoms/image/image";
import { PlainText as PlainTextBrc } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text";
import { useResizeImage, IMAGE_CONTEXT_CARD } from "@/hooks/use-resize-image";

function Card(props) {
	const imageUrl: string = props.elements.image.value[0]?.url ?? "";
	const imageDescription: string =
		props.elements.image.value[0]?.description ?? "";
	const resizedImage = useResizeImage(imageUrl, IMAGE_CONTEXT_CARD);

	const selectedImage = props.elements.image?.value?.[0];
	const imageElement = (
		<ImageBrc
			alt={imageDescription}
			height={resizedImage.height}
			src={resizedImage.url}
			width={resizedImage.width}
		/>
	);

	if (props?.elements?.link?.linkedItems[0] && selectedImage) {
		const linkItem = props.elements.link.linkedItems[0].elements;
		let linkUrl = "";

		if (linkItem.external_link?.value?.length > 0) {
			linkUrl = linkItem.external_link.value;
		} else if (linkItem.internal_link?.linkedItems[0]) {
			linkUrl = linkItem.internal_link.linkedItems[0].elements.url;
		}

		return (
			<CardBrc
				destination={linkUrl}
				image={imageElement}
				text={<PlainTextBrc>{props.elements.text.value}</PlainTextBrc>}
				title={props.elements.title.value}
			/>
		);
	}

	// Return null if there's no link or selected image
	return null;
}

export default Card;
