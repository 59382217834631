"use client";
import { useEffect, useState } from "react";
import {
	useDeviceInfo,
	DEVICE_IDENTIFIER_SMALL,
	DEVICE_IDENTIFIER_MEDIUM,
	DEVICE_IDENTIFIER_LARGE,
} from "@britishredcross/component-library/src/hooks/use-device-info";
import { type ImageContext } from "@/components/content/image";

export const IMAGE_CONTEXT_STANDALONE = "standalone";
export const IMAGE_CONTEXT_MULTICOLUMN = "multicolumn";
export const IMAGE_CONTEXT_CARD = "card";
export const IMAGE_CONTEXT_HOME_HERO = "homehero";

const DEFAULT_HEIGHT = "auto";

const imageDimensionMapping = {
	[IMAGE_CONTEXT_STANDALONE]: {
		[DEVICE_IDENTIFIER_SMALL]: {
			width: "767",
			height: "431",
		},
		[DEVICE_IDENTIFIER_MEDIUM]: {
			width: "1023",
			height: "575",
		},
		[DEVICE_IDENTIFIER_LARGE]: {
			width: "1080",
			height: "608",
		},
	},
	[IMAGE_CONTEXT_MULTICOLUMN]: {
		[DEVICE_IDENTIFIER_SMALL]: {
			width: "735",
			height: "413",
		},
		[DEVICE_IDENTIFIER_MEDIUM]: {
			width: "484",
			height: "272",
		},
		[DEVICE_IDENTIFIER_LARGE]: {
			width: "528",
			height: "297",
		},
	},
	[IMAGE_CONTEXT_CARD]: {
		[DEVICE_IDENTIFIER_SMALL]: {
			width: "735",
			height: "551",
		},
		[DEVICE_IDENTIFIER_MEDIUM]: {
			width: "484",
			height: "363",
		},
		[DEVICE_IDENTIFIER_LARGE]: {
			width: "528",
			height: "396",
		},
	},
	[IMAGE_CONTEXT_HOME_HERO]: {
		[DEVICE_IDENTIFIER_SMALL]: {
			width: "768",
			height: "576",
		},
		[DEVICE_IDENTIFIER_MEDIUM]: {
			width: "1023",
			height: "575",
		},
		[DEVICE_IDENTIFIER_LARGE]: {
			width: "2000",
			height: "1125",
		},
	},
};
interface ResizeImage {
	height: string;
	url: string;
	width: string;
}

interface Dimensions {
	height?: string;
	width: string;
}

export const useResizeImage = (
	originalImgUrl: string,
	imageContext: ImageContext
): ResizeImage => {
	const [currentResizeImage, setCurrentResizeImage] = useState<ResizeImage>({
		height: "",
		url: "",
		width: "",
	});

	const deviceInfo = useDeviceInfo();

	useEffect(() => {
		const dimensions: Dimensions =
			imageDimensionMapping[imageContext][deviceInfo.identifier];

		const width = dimensions.width;
		const height = dimensions.height ?? DEFAULT_HEIGHT;

		let modifiedImageUrl = originalImgUrl;

		if (originalImgUrl.includes("?")) {
			modifiedImageUrl = originalImgUrl
				.replace(/(w=)[^&]*/, `w=${width}`)
				.replace(/(h=)[^&]*/, `h=${height}`);
		} else {
			modifiedImageUrl = `${originalImgUrl}?w=${width}&h=${height}`;
		}

		setCurrentResizeImage({
			height,
			url: `${modifiedImageUrl}&auto=format`,
			width,
		});
	}, [deviceInfo, imageContext, originalImgUrl]);

	return currentResizeImage;
};
