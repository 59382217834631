import React from "react";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type SearchResultsCountProps } from "./search-results-count.types";

export function SearchResultsCount({
	rangeMin,
	rangeMax,
	total,
	resultsCountLabel,
}: SearchResultsCountProps): React.JSX.Element {
	// parse results label and insert total value
	const resultsLabelWithTotal = resultsCountLabel.replace(
		"{{results_count}}",
		total.toString()
	);
	return (
		<div className="w-full md:max-content md:mx-auto px-4 lg:px-0 mb-2 md:mb-4">
			<PlainText size={PlainTextSize.Default} bold>
				{`${rangeMin} - ${rangeMax} ${resultsLabelWithTotal}`}
			</PlainText>
		</div>
	);
}
