import React from "react";
import { SearchResultsNoResults } from "@britishredcross/component-library/src/components/molecules/search-results-no-results/search-results-no-results";

export function NoResults(): JSX.Element {
	return (
		<SearchResultsNoResults
			title="There are no results matching your search."
			message="Please check your spelling, or try again with a different search term."
		/>
	);
}
