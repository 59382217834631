import { type SearchResponseHit } from "typesense/lib/Typesense/Documents";

// The SearchResponseHit type does not have the geo_distance_meters property
// so we need to add our own type which extends it.
export interface TypesenseSearchResponseHit extends SearchResponseHit<any> {
	geo_distance_meters?: Record<string, number>;
}

export interface WebSearchError {
	error: boolean;
}

export const isWebSearchError = (obj: any): obj is WebSearchError =>
	typeof obj.error === "boolean";
