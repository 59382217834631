import React from "react";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { VadRecordSearchResult } from "../../molecules/vad-record-search-result/vad-record-search-result";
import { type VadSearchResultsProps } from "./vad-search-results.types";

export function VadSearchResults({
	totalRecords = 0,
	vadRecords = [],
	loading,
}: VadSearchResultsProps) {
	if (loading) {
		return null;
	}

	return (
		<>
			{vadRecords.length ? (
				<section className="w-full max-content mx-auto mb-8 md:mb-12 lg:mb-16">
					<div className="border-b border-grey-20 my-2 pl-4 md:border-0 md:my-6  md:pl-0 prose-h4:text-grey-secondary">
						<h4>
							{totalRecords}
							{vadRecords.length !== 1 ? " Results" : " Result"}
						</h4>
					</div>
					<div>
						{vadRecords.map((record, key: number) => (
							<div key={key}>
								<VadRecordSearchResult {...record} />
							</div>
						))}
					</div>
				</section>
			) : (
				<section className="w-full max-content mx-auto py-8 md:py-16">
					<div>
						<Heading level={HeadingLevel.H2}>No results found</Heading>
						<PlainText size={PlainTextSize.Large}>
							Try searching again using different words.
						</PlainText>
					</div>
				</section>
			)}
		</>
	);
}
