"use client";
import React, { Suspense, useEffect } from "react";
import { HeroPage as HeroPageBrc } from "@britishredcross/component-library/src/components/molecules/hero-page/hero-page";
import { Image } from "@britishredcross/component-library/src/components/atoms/image/image";
import { RecordsTable } from "@britishredcross/component-library/src/components/molecules/records-table/records-table";
import { useSearchParams } from "next/navigation";
import type {
	TableModel,
	VadRecordModel,
} from "@britishredcross/kontent-integration/dist/esm/models";
import { useStore } from "../../hooks/use-store";
import { sanitizeRecordForUx } from "../../utils/record-sanitizer";

function VadRecord(props: VadRecordModel) {
	const configTable: TableModel = (props.elements.config.linkedItems[0] ||
		{}) as unknown as TableModel;

	const config: Record<string, string> = {};

	configTable.elements.table_rows.linkedItems.forEach((li) => {
		config[li.elements.key.value] = li.elements.value.value;
	});

	const searchParams = useSearchParams();
	const { vadApi } = useStore();

	const rowKey = searchParams.get("rowKey");

	useEffect(() => {
		if (rowKey) {
			vadApi.getRecord(rowKey);
		}
	}, [rowKey]);

	const showRecord = (record?: any) => {
		if (!record) {
			return (
				<>
					<HeroPageBrc title="" text="" />
					<RecordsTable rows={{}} />
				</>
			);
		}
		const { images, ...rest } = record;
		const newRecord = sanitizeRecordForUx(rest || {}, config);

		const title = `${record.christian_names} ${record.surname}`;

		return (
			<>
				<HeroPageBrc title={title} text="" />
				<>
					{(rowKey !== "" || Boolean(vadApi.record)) && (
						<RecordsTable rows={newRecord} />
					)}
				</>
				{images
					?.sort((a: any, b: any) => a.page_number - b.page_number)
					.map((img: any, key: number) => {
						return (
							<div
								className="w-full max-content mx-auto px-4 mb-8 md:mb-12 lg:mb-16 lg:px-0"
								key={key}
							>
								<Image alt={img.page_number} src={img.url} />
							</div>
						);
					})}
			</>
		);
	};

	return <>{showRecord(vadApi.record)}</>;
}

export default function VadRecordComponent(props: VadRecordModel) {
	return (
		<Suspense>
			<VadRecord {...props} />
		</Suspense>
	);
}
