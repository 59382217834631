import React from "react";
import { ResultsList } from "@britishredcross/component-library/src/components/organisms/results-list/results-list";
import { ShopList } from "@britishredcross/component-library/src/components/organisms/shop-list/shop-list";
import { contentTypes } from "@britishredcross/kontent-integration/src/models";
import { type TypesenseSearchResponseHit } from "@/actions/search/types";
import { useDictionary } from "@/app/context/dictionary.context";

interface ResultsProps {
	results: TypesenseSearchResponseHit[] | undefined;
	searchContentType: string;
}

export const getDistance = (distance: number): string | null => {
	if (!distance || distance < 1 || isNaN(distance)) return null;
	const ONE_METER_IN_MILES = 0.000621371;
	const distanceInMiles = distance * ONE_METER_IN_MILES;
	if (isNaN(distanceInMiles)) return null;
	return distanceInMiles.toFixed(1).toString();
};

export const getFormattedDate = (dateValue: string): string => {
	let formattedDate = "";
	const date = new Date(dateValue);
	const isValidDate = !isNaN(date.getTime());
	if (isValidDate) {
		const day = date.toLocaleString(undefined, { day: "numeric" });
		const month = date.toLocaleString(undefined, { month: "long" });
		const year = date.getFullYear();
		formattedDate = `${day} ${month} ${year}`;
	}
	return formattedDate;
};

export function Results({
	results,
	searchContentType,
}: ResultsProps): JSX.Element {
	const dictionaryData = useDictionary();

	const getResultsList = (): JSX.Element => {
		switch (searchContentType) {
			case contentTypes.shop.codename:
				return (
					<ShopList
						shopListItems={
							results?.map((result) => ({
								openingHours: result.document.opening_hours ?? [],
								title: result.document.title ?? "",
								distance: result.geo_distance_meters?.location
									? getDistance(result.geo_distance_meters.location)
									: "",
								shopType:
									result.document.shop_type.map((type) => type.name) ?? "",
								isTemporarilyClosed:
									result.document.shop_status_override?.codename ===
									"temporarily_closed",
								temporarilyClosedLabel:
									result.document.shop_status_override?.name,
								shopDetailsLabel:
									dictionaryData?.elements.list_see_details_label.value ?? "",
								shopUrl: result.document.url ?? "",
								milesLabel:
									dictionaryData?.elements.list_miles_label.value ?? "",
								closedLabel:
									dictionaryData?.elements.opening_hours_close.value ?? "",
							})) ?? []
						}
					/>
				);
			default:
				return (
					<ResultsList
						resultsListItems={
							results?.map((result) => ({
								date: getFormattedDate(result.document.date),
								url: result.document.url ?? "",
								title: result.document.title ?? "",
								description: result.document.subtitle ?? "",
							})) || []
						}
					/>
				);
		}
	};

	return <div aria-label="Search results">{getResultsList()}</div>;
}
