import React from "react";
import { type PaginationProps } from "./pagination.types";

const currentPageLinkProps: { className: string; "aria-current": "page" } = {
	className:
		"w-12 h-12 inline-flex justify-center items-center border border-red-primary text-base text-white no-underline mx-1 bg-red-primary",
	"aria-current": "page",
};

const otherPageLinkProps = {
	className:
		"w-12 h-12 inline-flex justify-center items-center border border-grey-20 text-base text-black-primary no-underline mx-1 hover:bg-grey-20 hover:underline hover:border-red-primary",
};

const createLinks = ({ currentPage, totalPages, onClick }: PaginationProps) => {
	const links: JSX.Element[] = [];
	if (totalPages === 1) {
		const singlePageLink = (
			<a
				href="#"
				key="pagination-link-1"
				{...currentPageLinkProps}
				aria-label="Single page of results, Reload Link"
			>
				1
			</a>
		);
		links.push(singlePageLink);
	} else {
		const firstLink =
			currentPage === 1 ? (
				<a
					href="#"
					key="pagination-link-1"
					{...currentPageLinkProps}
					aria-label="Current Page, Page 1, Reload Link"
				>
					1
				</a>
			) : (
				<a
					href="#"
					key="pagination-link-1"
					{...otherPageLinkProps}
					onClick={() => {
						onClick(1);
					}}
					aria-label="Go to Page 1"
				>
					1
				</a>
			);

		links.push(firstLink);

		const lastLink =
			currentPage === totalPages ? (
				<a
					href="#"
					key={`pagination-link-${totalPages}`}
					{...currentPageLinkProps}
					aria-label={`Current and Last Page, Page ${totalPages}, Reload Link`}
				>
					{totalPages}
				</a>
			) : (
				<a
					href="#"
					key={`pagination-link-${totalPages}`}
					{...otherPageLinkProps}
					onClick={() => {
						onClick(totalPages);
					}}
					aria-label={`Go to Last Page number ${totalPages} `}
				>
					{totalPages}
				</a>
			);

		if (totalPages <= 7) {
			for (let linkCount = 2; linkCount <= totalPages - 1; linkCount++) {
				const link =
					linkCount === currentPage ? (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...currentPageLinkProps}
							aria-label={`Current Page, Page ${linkCount}, Reload Link`}
						>
							{linkCount}
						</a>
					) : (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...otherPageLinkProps}
							onClick={() => {
								onClick(linkCount);
							}}
							aria-label={`Go to Page ${linkCount} `}
						>
							{linkCount}
						</a>
					);
				links.push(link);
			}
		} else if (currentPage < 4) {
			for (let linkCount = 2; linkCount <= 5; linkCount++) {
				const link =
					linkCount === currentPage ? (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...currentPageLinkProps}
							aria-label={`Current Page, Page ${linkCount}, Reload Link`}
						>
							{linkCount}
						</a>
					) : (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...otherPageLinkProps}
							onClick={() => {
								onClick(linkCount);
							}}
							aria-label={`Go to Page ${linkCount} `}
						>
							{linkCount}
						</a>
					);
				links.push(link);
			}

			const ellipsisEndLink = (
				<a
					href="#"
					key={`pagination-link-${totalPages - 1}`}
					{...otherPageLinkProps}
					onClick={() => {
						onClick(6);
					}}
					aria-label="Display More Pages"
				>
					...
				</a>
			);

			links.push(ellipsisEndLink);
		} else if (currentPage > totalPages - 3) {
			const ellipsisStartLink = (
				<a
					href="#"
					key="pagination-link-2"
					{...otherPageLinkProps}
					onClick={() => {
						onClick(totalPages - 4);
					}}
					aria-label="Display More Pages"
				>
					...
				</a>
			);

			links.push(ellipsisStartLink);

			for (
				let linkCount = totalPages - 4;
				linkCount <= totalPages - 1;
				linkCount++
			) {
				const link =
					linkCount === currentPage ? (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...currentPageLinkProps}
							aria-label={`Current Page, Page ${linkCount}, Reload Link`}
						>
							{linkCount}
						</a>
					) : (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...otherPageLinkProps}
							onClick={() => {
								onClick(linkCount);
							}}
							aria-label={`Go to Page ${linkCount} `}
						>
							{linkCount}
						</a>
					);
				links.push(link);
			}
		} else {
			const ellipsisStartLink = (
				<a
					href="#"
					key="pagination-link-2"
					{...otherPageLinkProps}
					onClick={() => {
						onClick(currentPage - 2);
					}}
					aria-label="Display More Pages"
				>
					...
				</a>
			);

			links.push(ellipsisStartLink);

			for (
				let linkCount = currentPage - 1;
				linkCount <= currentPage + 1;
				linkCount++
			) {
				const link =
					linkCount === currentPage ? (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...currentPageLinkProps}
							aria-label={`Current Page, Page ${linkCount}, Reload Link`}
						>
							{linkCount}
						</a>
					) : (
						<a
							href="#"
							key={`pagination-link-${linkCount}`}
							{...otherPageLinkProps}
							onClick={() => {
								onClick(linkCount);
							}}
							aria-label={`Go to Page ${linkCount} `}
						>
							{linkCount}
						</a>
					);
				links.push(link);
			}

			const ellipsisEndLink = (
				<a
					href="#"
					key={`pagination-link-${totalPages - 1}`}
					{...otherPageLinkProps}
					onClick={() => {
						onClick(currentPage + 2);
					}}
					aria-label="Display More Pages"
				>
					...
				</a>
			);

			links.push(ellipsisEndLink);
		}

		links.push(lastLink);
	}

	return links;
};

export { createLinks };
