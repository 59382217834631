import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type HeroPageProps } from "./hero-page.types";

export function HeroPage({ title, subTitle, text, image }: HeroPageProps) {
	return (
		<section
			className={`brc-hero border-0 border-grey-20 border-b bg-white mx-auto px-4 mb-7 ${image ? "brc-hero-with-image md:border md:max-content-hero" : "md:max-content"}  md:px-5 md:mb-8 lg:px-0`}
		>
			<div className="relative mx-auto max-content grid gap-4 grid-cols-12 lg:gap-6">
				<div
					className={`relative col-span-12 pt-8 pb-3 prose-h2:text-4xl prose-h2:mb-2 prose-h2:md:mb-4 prose-h1:mb-2 prose-h1:md:mb-4 ${image ? "md:pb-12 lg:col-span-6" : "md:pb-8 md:col-span-8 lg:col-span-8"} `}
				>
					<Heading level={HeadingLevel.H1}>{title}</Heading>
					{subTitle ? (
						<Heading level={HeadingLevel.H2}>{subTitle}</Heading>
					) : null}
					<PlainText size={PlainTextSize.Large}>{text}</PlainText>
				</div>

				{image ? (
					<div className="hidden absolute inset-y-0 left-[32px] w-full lg:block lg:col-start-7 lg:col-span-6">
						{image}
					</div>
				) : null}
			</div>
		</section>
	);
}
