"use client";
import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { RichText } from "../../atoms/rich-text/rich-text";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { type ShopOpeningHoursProps } from "./shop-opening-hours.types";

export function ShopOpeningHours({
	monday,
	tuesday,
	wednesday,
	thursday,
	friday,
	saturday,
	sunday,
	mondayLabel,
	tuesdayLabel,
	wednesdayLabel,
	thursdayLabel,
	fridayLabel,
	saturdayLabel,
	sundayLabel,
	openingHoursNote,
	temporarilyClosedLabel,
	isTemporarilyClosed,
	openingHoursLabel,
}: ShopOpeningHoursProps) {
	// Determine the current day of the week (0 - Sunday, 1 - Monday, ..., 6 - Saturday).
	//  Hardcode to 1 (Monday) for snapshot testing purposes
	const today = process.env.NODE_ENV === "test" ? 1 : new Date().getDay();

	// Helper function to check if a day is today
	const isToday = (day: number): boolean => today === day;

	// Define a mapping of days to their corresponding data
	const daysMapping = [
		{ day: mondayLabel, data: monday },
		{ day: tuesdayLabel, data: tuesday },
		{ day: wednesdayLabel, data: wednesday },
		{ day: thursdayLabel, data: thursday },
		{ day: fridayLabel, data: friday },
		{ day: saturdayLabel, data: saturday },
		{ day: sundayLabel, data: sunday },
	];

	return (
		<div className="w-full bg-grey-10 px-4 py-8 mb-8 md:mb-12 md:px-0 md:py-12 lg:mb-16 lg:py-16">
			<div className="w-full max-content mx-auto">
				<div className="pb-3 md:pb-4">
					<Heading level={HeadingLevel.H2}>{openingHoursLabel}</Heading>
				</div>
				<div className="grid grid-cols-12 gap-4">
					{isTemporarilyClosed ? (
						<div className="col-start-1 col-span-12 md:col-span-8">
							<div className="flex items-center prose-p:font-bold">
								<span className="mr-2">
									<Icon
										name="error"
										size={24}
										colour={IconColour.PrimaryBlack}
									/>
								</span>
								<PlainText size={PlainTextSize.Default}>
									{temporarilyClosedLabel}
								</PlainText>
							</div>
						</div>
					) : (
						<dl className="flex flex-wrap col-start-1 col-span-12 bg-white md:col-span-8 border-l border-r overflow-hidden border-t">
							{daysMapping.map(({ day, data }, index) => (
								<React.Fragment key={day}>
									<dt
										className={`border-b py-4 px-2 w-4/12 md:w-3/12 ${
											isToday(index + 1)
												? "bg-blue-secondary prose-p:text-white prose-p:font-bold"
												: ""
										}`}
										id={day.toLowerCase()}
										aria-current={isToday(index + 1) ? "date" : undefined}
									>
										<PlainText size={PlainTextSize.Default}>{day}</PlainText>
									</dt>
									<dd
										dir="ltr"
										className={`border-b py-4 px-2 w-8/12 md:w-9/12 rtl:text-right ${
											isToday(index + 1)
												? "bg-blue-secondary prose-p:text-white prose-p:font-bold"
												: ""
										}`}
										aria-labelledby={day.toLowerCase()}
									>
										<PlainText size={PlainTextSize.Default}>{data}</PlainText>
									</dd>
								</React.Fragment>
							))}
						</dl>
					)}

					{openingHoursNote ? (
						<div className="col-start-1 col-span-12 [&_.brc-richtext]:p-0 [&_.brc-richtext]:m-0">
							<RichText>{openingHoursNote}</RichText>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}
