import { type InputHTMLAttributes } from "react";

export enum FieldType {
	Text = "text",
}

export type LocationInputProps = {
	label: string;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	placeholder?: string;
	defaultValue?: string;
} & InputHTMLAttributes<HTMLInputElement>;
