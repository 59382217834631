/**
 * Generated by '@kontent-ai/model-generator@7.4.0'
 */
export const contentTypeSnippets = {
	/**
	 * Common taxonomies
	 */
	common_taxonomies: {
		codename: "common_taxonomies",
		id: "55fa4822-a926-4eb5-98a4-24469d3562dd",
		externalId: undefined,
		name: "Common taxonomies",
		elements: {
			/**
			 * Teams (taxonomy)
			 */
			common_taxonomies__teams: {
				codename: "common_taxonomies__teams",
				id: "428a24bb-18be-4fc2-a51a-517f3d74ab19",
				externalId: undefined,
				name: "Teams",
				required: false,
				type: "taxonomy",
			},

			/**
			 * Topics (taxonomy)
			 */
			common_taxonomies__topics: {
				codename: "common_taxonomies__topics",
				id: "d8911b28-e0f9-462f-a207-a58e441be8fc",
				externalId: undefined,
				name: "Topics",
				required: false,
				type: "taxonomy",
			},

			/**
			 * Review date (taxonomy)
			 */
			common_taxonomies__review_date: {
				codename: "common_taxonomies__review_date",
				id: "ec391564-51c1-4c39-9dd1-d14ec03eef26",
				externalId: undefined,
				name: "Review date",
				required: false,
				type: "taxonomy",
			},
		},
	},

	/**
	 * Internal search
	 */
	internal_search: {
		codename: "internal_search",
		id: "43c62fbb-d353-49e9-8afb-d4ff49e00e3f",
		externalId: undefined,
		name: "Internal search",
		elements: {
			/**
			 * Internal search title (text)
			 *
			 * Leave blank to use the original page title.
			 */
			internal_search__title: {
				codename: "internal_search__title",
				id: "ab8d7e05-b0ea-41a1-b258-5acb7015e5c9",
				externalId: undefined,
				name: "Internal search title",
				required: false,
				type: "text",
			},

			/**
			 * Internal search description (text)
			 *
			 * Leave blank to use the original page description.
			 */
			internal_search__description: {
				codename: "internal_search__description",
				id: "6aab341e-3985-4f94-a001-178a99559d81",
				externalId: undefined,
				name: "Internal search description",
				required: false,
				type: "text",
			},
		},
	},

	/**
	 * Page visibility
	 */
	page_visibility: {
		codename: "page_visibility",
		id: "30e11a25-131e-42d8-a362-2441aa003314",
		externalId: undefined,
		name: "Page visibility",
		elements: {
			/**
			 * Sitemap page visibility (multiple_choice)
			 */
			page_visibility__sitemap_page: {
				codename: "page_visibility__sitemap_page",
				id: "a720d5d2-bfdd-4c0c-bf42-0187941234d0",
				externalId: undefined,
				name: "Sitemap page visibility",
				required: false,
				type: "multiple_choice",
			},

			/**
			 * Sitemap XML visibility (multiple_choice)
			 */
			page_visibility__sitemap_xml: {
				codename: "page_visibility__sitemap_xml",
				id: "ee9d915e-2f76-43ec-8442-dc90bb140c8c",
				externalId: undefined,
				name: "Sitemap XML visibility",
				required: false,
				type: "multiple_choice",
			},

			/**
			 * External search visibility (multiple_choice)
			 */
			page_visibility__external_search: {
				codename: "page_visibility__external_search",
				id: "11e23157-fc44-45f8-8801-8c4ed8a4add5",
				externalId: undefined,
				name: "External search visibility",
				required: false,
				type: "multiple_choice",
			},

			/**
			 * Internal search visibility (multiple_choice)
			 */
			page_visibility__internal_search: {
				codename: "page_visibility__internal_search",
				id: "7999f1f9-d987-48b6-85fd-781d6bcda604",
				externalId: undefined,
				name: "Internal search visibility",
				required: false,
				type: "multiple_choice",
			},
		},
	},

	/**
	 * SEO Snippet
	 */
	seo_snippet: {
		codename: "seo_snippet",
		id: "2871fe65-d304-4ca9-adcb-339e2db81959",
		externalId: undefined,
		name: "SEO Snippet",
		elements: {
			/**
			 * Meta Title (text)
			 *
			 * A page's Meta Title is shown in the user's browser tab, in link previews, and in search results. We provide a default meta title of '[Page Title] | [Site Name]'. For the main British Red Cross website, this will always end with ‘British Red Cross’. We suggest that you don’t customise the meta title, but if you need to for some reason, try to keep the ' | [Site Name]' part in some form.The meta title should be at least 30 characters, and ideally 50 to 60 characters, including the website name and spaces. For example: "Work abroad | World War 1 volunteers | British Red Cross" (56 characters)
			 */
			seo_snippet__meta_title: {
				codename: "seo_snippet__meta_title",
				id: "5524b125-4704-4b3c-bf8a-d56f4fa18744",
				externalId: undefined,
				name: "Meta Title",
				required: false,
				type: "text",
			},

			/**
			 * Meta Description (text)
			 *
			 * A page's Meta Description shows in search engines when users search for a related topic or page. It also shows in online link previews. It should be between 50 and 160 characters long.
			 */
			seo_snippet__meta_description: {
				codename: "seo_snippet__meta_description",
				id: "05c83f65-1903-43ca-b332-a10f515fa345",
				externalId: undefined,
				name: "Meta Description",
				required: true,
				type: "text",
			},

			/**
			 * Meta Image (asset)
			 *
			 * A page's Meta Image is shown in search engine results pages and link previews. If you do not choose a Meta Image for this page, the site-wide default image will be used instead.
			 */
			seo_snippet__meta_image: {
				codename: "seo_snippet__meta_image",
				id: "ab059ac6-acdb-4d20-966c-79082ba73735",
				externalId: undefined,
				name: "Meta Image",
				required: false,
				type: "asset",
			},
		},
	},

	/**
	 * Site Favicon snippet
	 */
	site_favicon_snippet: {
		codename: "site_favicon_snippet",
		id: "60e91716-e8f5-4fcb-9353-ccb545be1e0b",
		externalId: undefined,
		name: "Site Favicon snippet",
		elements: {
			/**
			 * Apple touch icons (asset)
			 */
			site_favicon_snippet__n180x180: {
				codename: "site_favicon_snippet__n180x180",
				id: "820b2a70-6a88-4547-af5a-91c1ec77bf67",
				externalId: undefined,
				name: "Apple touch icons",
				required: true,
				type: "asset",
			},

			/**
			 * Desktop shortcut icons (asset)
			 */
			site_favicon_snippet__desktop_shortcut_icons: {
				codename: "site_favicon_snippet__desktop_shortcut_icons",
				id: "94307ef8-275e-480c-b1ef-cc20b11dc595",
				externalId: undefined,
				name: "Desktop shortcut icons",
				required: true,
				type: "asset",
			},

			/**
			 * Taskbar shortcut icons (asset)
			 */
			site_favicon_snippet__taskbar_shortcut_icons: {
				codename: "site_favicon_snippet__taskbar_shortcut_icons",
				id: "f9c88785-c85e-4437-8647-9120af4ece8e",
				externalId: undefined,
				name: "Taskbar shortcut icons",
				required: true,
				type: "asset",
			},

			/**
			 * Browser favicons (asset)
			 */
			site_favicon_snippet__browser_favicons: {
				codename: "site_favicon_snippet__browser_favicons",
				id: "63d0b8c2-f420-43c8-807c-339dc58d3815",
				externalId: undefined,
				name: "Browser favicons",
				required: true,
				type: "asset",
			},
		},
	},
} as const;
