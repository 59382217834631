import classNames from "classnames";
import { type TextInputProps, FieldType } from "./text-input.types";

export const FieldClasses = ({ fieldType }: TextInputProps): string => {
	switch (fieldType as FieldType) {
		case FieldType.Text:
			return classNames(
				"block w-full appearance-none border border-solid border-black-primary py-[7px] px-3 leading-8 text-sm hover:brc-shadow-fieldhover focus:border-1 focus:border-green-secondary focus:brc-shadow-fieldfocus focus:outline-0 focus-visible:outline-none"
			);
	}
};
