"use client";
import { useState, useEffect, useCallback } from "react";
import { tailwindConfig } from "@britishredcross/config-styling/tailwind.config";
import { debounce } from "../utils/debounce";

export const DEVICE_IDENTIFIER_SMALL = "SM";
export const DEVICE_IDENTIFIER_MEDIUM = "MD";
export const DEVICE_IDENTIFIER_LARGE = "LG";
const DEFAULT_DEVICE_IDENTIFIER = DEVICE_IDENTIFIER_LARGE;

type DeviceIdentifier =
	| typeof DEVICE_IDENTIFIER_SMALL
	| typeof DEVICE_IDENTIFIER_MEDIUM
	| typeof DEVICE_IDENTIFIER_LARGE;

interface DeviceInfo {
	height: number;
	identifier: DeviceIdentifier;
	isBrowser: boolean;
	width: number;
}

export const useDeviceInfo = (): DeviceInfo => {
	const [currentDeviceInfo, setCurrentDeviceInfo] = useState<DeviceInfo>({
		height: 0,
		identifier: DEFAULT_DEVICE_IDENTIFIER,
		isBrowser: false,
		width: 0,
	});

	const isBrowser = typeof window !== "undefined";

	const updateDeviceInfo = useCallback(() => {
		const { screens } = tailwindConfig.theme;

		const getWindowHeight = (): number => (isBrowser ? window.innerHeight : 0);
		const getWindowWidth = (): number => (isBrowser ? window.innerWidth : 0);

		const height = getWindowHeight();
		const width = getWindowWidth();

		const getDeviceIdentifier = (): DeviceIdentifier => {
			if (width < parseInt(screens.sm, 10)) return DEVICE_IDENTIFIER_SMALL;
			if (
				(width > parseInt(screens.sm, 10) &&
					width < parseInt(screens.md, 10)) ||
				width === parseInt(screens.sm, 10)
			)
				return DEVICE_IDENTIFIER_SMALL;
			if (
				(width > parseInt(screens.md, 10) &&
					width < parseInt(screens.lg, 10)) ||
				width === parseInt(screens.md, 10)
			)
				return DEVICE_IDENTIFIER_MEDIUM;
			return DEFAULT_DEVICE_IDENTIFIER;
		};

		setCurrentDeviceInfo({
			height,
			identifier: getDeviceIdentifier(),
			isBrowser,
			width,
		});
	}, [isBrowser]);

	useEffect(() => {
		// Set initial values on load
		updateDeviceInfo();

		if (isBrowser) {
			window.addEventListener(
				"resize",
				debounce(() => {
					updateDeviceInfo();
				})
			);
		}

		// Cleanup the event listener on unmount
		return () => {
			if (isBrowser) {
				window.removeEventListener("resize", updateDeviceInfo);
			}
		};
	}, [isBrowser, updateDeviceInfo]);

	return currentDeviceInfo;
};
