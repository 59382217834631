import React from "react";
import {
	CardBasic,
	CardStandalone,
} from "../../molecules/card-basic/card-basic";
import { type CardContainerProps } from "./card-container.types";

export function CardContainer({ title, subtitle, cards }: CardContainerProps) {
	// Count the number of cards
	const numberOfCards = React.Children.count(cards);

	return (
		<section className="w-full max-content mx-auto pb-8 px-4 md:pb-16 lg:px-0">
			{title?.props.children ? (
				<div className="grid grid-cols-12 gap-6 text-center">
					<div className="col-start-1 col-span-12 lg:col-start-3 pb-6 lg:col-span-8 md:pb-12">
						{title}
						{subtitle ? subtitle : ""}
					</div>
				</div>
			) : null}

			{/* Grid style base on amount of cards */}
			<div
				className={`grid grid-cols-1 gap-6 content-between ${
					numberOfCards <= 1
						? "md:grid-cols-1"
						: numberOfCards === 2
							? "md:grid-cols-2"
							: numberOfCards >= 3
								? "md:grid-cols-3"
								: null
				} `}
			>
				{Array.isArray(cards) &&
					cards.map((card, key: number) => (
						<div className="contents" key={key}>
							{numberOfCards === 1 ? (
								<CardStandalone {...card.props} />
							) : (
								<CardBasic {...card.props} />
							)}
						</div>
					))}
			</div>
		</section>
	);
}
