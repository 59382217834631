import React from "react";
import { SearchResultsError } from "@britishredcross/component-library/src/components/molecules/search-results-error/search-results-error";

export function Error(): JSX.Element {
	return (
		<SearchResultsError
			title="There has been an error performing your search."
			message="Please try again later."
		/>
	);
}
