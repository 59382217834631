import React, { type ReactElement } from "react";
import {
	type LinkProps,
	LinkType,
} from "@britishredcross/component-library/src/components/atoms/link/link.types";
import { type MenuLinkTwoLevel } from "@britishredcross/component-library/src/components/molecules/header/header.types";
import { Header as HeaderComponent } from "@britishredcross/component-library/src/components/molecules/header/header";
import { Image } from "@britishredcross/component-library/src/components/atoms/image/image";
import { Link } from "@britishredcross/component-library/src/components/atoms/link/link";
import {
	type HeaderModel,
	type LinkModel,
} from "@britishredcross/kontent-integration/src/models/content-types";
import { type SitemapMapping } from "@britishredcross/kontent-integration/dist/esm/services/settings-service";
import { getSlugFromMapping } from "@/utils/path";

export default function Header({
	headerProps,
	mappings,
	isSingleLevelNavigation,
	menuItems,
	mobileNavMenuLabel,
	mobileNavCloseLabel,
	mobileNavBackLabel,
	mobileNavSearchLabel,
}: {
	headerProps: HeaderModel;
	mappings: SitemapMapping[];
	isSingleLevelNavigation: boolean;
	menuItems:
		| LinkProps[]
		| Record<
				string,
				{
					header: {
						title: string;
					};
					links: MenuLinkTwoLevel[] | JSX.Element[];
				}
		  >
		| null
		| undefined;
	mobileNavMenuLabel: string;
	mobileNavCloseLabel: string;
	mobileNavBackLabel: string;
	mobileNavSearchLabel: string;
}) {
	const ctaLink = (
		headerProps.elements.cta.linkedItems[0]
			? resolveLink(
					headerProps.elements.cta.linkedItems[0],
					LinkType.HeaderCta,
					mappings,
					headerProps.elements.cta.linkedItems[0]?.system.id ||
						"cta-header-link"
				)
			: null
	) as any;

	const logoWidth = headerProps.elements.hosted_by_site_logo.value[0]?.url
		? 190
		: 232;
	const logo = (
		<Image
			alt={headerProps.elements.site_logo.value[0]?.description || ""}
			loading="eager"
			src={`${headerProps.elements.site_logo.value[0]?.url}?auto=format` || ""}
			width={logoWidth.toString()}
			height="48"
		/>
	);

	const hostedByLogo = headerProps.elements.hosted_by_site_logo.value ? (
		<Image
			alt={headerProps.elements.hosted_by_site_logo.value[0]?.description || ""}
			loading="eager"
			src={
				`${headerProps.elements.hosted_by_site_logo.value[0]?.url}?auto=format` ||
				""
			}
			width="190"
			height="48"
		/>
	) : undefined;

	const hostedByLinkUrl = headerProps.elements.hosted_by_link.value
		? headerProps.elements.hosted_by_link.value
		: undefined;

	const onSearch =
		headerProps.elements.site_search.value[0]?.codename === "true";

	return (
		<header className="flex flex-col">
			<HeaderComponent
				homeLinkUrl="/"
				{...(ctaLink && { ctaLink })}
				{...(logo && { logo })}
				{...(hostedByLogo && { hostedByLogo })}
				hostedByLinkUrl={hostedByLinkUrl}
				menuLinksOneLevel={
					isSingleLevelNavigation && Array.isArray(menuItems)
						? menuItems.map((item: LinkProps, i: React.Key) => (
								<Link
									destination={item.destination}
									key={i}
									linkType={LinkType.MenuHeader}
								>
									{item.children}
								</Link>
							))
						: null
				}
				menuLinksTwoLevel={!isSingleLevelNavigation ? menuItems : null}
				onSearch={onSearch}
				mobileNavMenuLabel={mobileNavMenuLabel}
				mobileNavCloseLabel={mobileNavCloseLabel}
				mobileNavBackLabel={mobileNavBackLabel}
				mobileNavSearchLabel={mobileNavSearchLabel}
			/>
		</header>
	);
}

export function resolveLink(
	link: LinkModel,
	linkType: LinkType,
	mappings: SitemapMapping[],
	key: string
): ReactElement<LinkProps> {
	if (link.elements === undefined) {
		throw new Error("Link elements are undefined");
	}

	if (link.elements.internal_link.value.length) {
		// resolve internal link
		const slug = getSlugFromMapping(
			mappings,
			link.elements.internal_link.value[0] || ""
		);

		return (
			<Link destination={`/${slug}`} key={key} linkType={linkType}>
				{link.elements.display.value}
			</Link>
		);
	}
	return (
		<Link
			destination={link.elements.external_link.value}
			key={key}
			linkType={linkType}
		>
			{link.elements.display.value}
		</Link>
	);
}
