export const tailwindConfig = {
	theme: {
		fontFamily: {
			// Default for Latin, Cyrillic, and Arabic scripts
			sans: ["Helvetica Neue", "helvetica", "arial", "sans-serif"],

			// Arabic for: Arabic, Dari, Farsi, Kurdish Sorani, Pashto and Urdu
			arabic: ["Geeza Pro", "Segoe UI", "helvetica", "arial", "sans-serif"],

			// Ethiopic for: Amharic and Tigrinya LTR, TODO possible extra to add "Noto Sans"
			ethiopic: ["Kefa", "Nyala", "arial", "sans-serif"],

			// font-chinese: Cantonese, Mandarin, Simplified Chinese, Traditional Chinese TODO possible extra to add "Noto Sans TC"
			chinese: ["PingFang TC", "Microsoft JhengHei", "arial", "sans-serif"],

			// Hindi - TODO posible to add "Noto Sans Devanagari"
			devanagari: [
				"Kohinoor Devanagari",
				"Devanagari MT",
				"Mangal",
				"Nirmala UI",
				"arial",
				"sans-serif",
			],
		},
		fontSize: {
			xxs: ["0.75rem", "0.75rem"], // 12px
			xs: ["0.875rem", "1.25rem"], // 14px, lineheight 20px
			sm: ["1rem", "1.5rem"], // 16px, lineheight 24px
			base: ["1.125rem", "1.75rem"], // 18px, lineheight 28px
			lg: ["1.25rem", "1.75rem"], // 20px, lineheight 28px
			xl: ["1.375rem", "2rem"], // 22px, lineheight 32px
			"2xl": ["1.5rem", "2rem"], // 24px, lineheight 32px
			"3xl": ["1.625rem", "2rem"], // 26px, lineheight 32px
			"4xl": ["1.75rem", "2rem"], // 28px, lineheight 32px
			"5xl": ["1.875rem", "2.25rem"], // 30px, lineheight 36px
			"6xl": ["2.375rem", "3.5rem"], // 38px, lineheight 56px
			"7xl": ["2.75rem", "3.125rem"], // 44px, lineheight 50px
		},
		screens: {
			sm: "320px",
			md: "768px",
			lg: "1024px",
		},
		colors: {
			transparent: "transparent",
			current: "currentColor",
			red: {
				primary: "#D0021B",
				secondary: "#AD1220",
				tint: "#CC434F",
				system: "#D0021B",
			},
			green: {
				primary: "#40A22A",
				secondary: "#05853A",
				tint: "#44A46C",
				system: "#40A22A",
			},
			black: {
				primary: "#262626",
			},
			teal: {
				primary: "#2B7586",
				tint: "#6A9EAA",
				system: "#6A9EAA",
			},
			blue: {
				secondary: "#193351",
				tint: "#475C74",
				link: "#1D70B8",
				linkHover: "#003078",
			},
			grey: {
				secondary: "#5C747A",
				tint: "#9CAAAE",
				10: "#F6F6F6",
				20: "#E0E0E0",
				30: "#BBBBBB",
				40: "#717171",
			},
			white: {
				DEFAULT: "#FFFFFF",
			},
			mustard: {
				system: "#F1B13B",
			},
		},
		textDecorationThickness: {
			0: "0",
			1: "1px",
			2: "2px",
			3: "3px",
			4: "4px",
			5: "5px",
			6: "6px",
			7: "7px",
			8: "8px",
		},
		textUnderlineOffset: {
			0: "0",
			1: "1px",
			2: "2px",
			3: "3px",
			4: "4px",
			5: "5px",
			6: "6px",
			7: "7px",
			8: "8px",
		},
	},
	plugins: [require("@tailwindcss/typography")],
};
