"use client";
import React, { createContext, useContext } from "react";
import { type DictionaryModel } from "@britishredcross/kontent-integration/src/models";

export const DictionaryContext: React.Context<DictionaryModel | null> =
	createContext(null) as React.Context<DictionaryModel | null>;

export function DictionaryProvider({
	children,
	initialState,
}: {
	children: JSX.Element;
	initialState: DictionaryModel;
}): JSX.Element {
	return (
		<DictionaryContext.Provider value={initialState}>
			{children}
		</DictionaryContext.Provider>
	);
}

export function useDictionary(): DictionaryModel | null {
	const context = useContext(DictionaryContext);
	if (!context) {
		throw new Error("useDictionary must be used within a DictionaryProvider");
	}
	return context;
}
