"use client";

import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { TextInput } from "../text-input/text-input";
import { FieldType, type LocationInputProps } from "./location-input.types";

export const LocationInput = forwardRef<HTMLInputElement, LocationInputProps>(
	(
		{ label, required, disabled, error, placeholder, defaultValue, ...rest },
		ref
	): JSX.Element => {
		const places = useMapsLibrary("places");

		const [placeAutocomplete, setPlaceAutocomplete] =
			useState<google.maps.places.Autocomplete | null>(null);
		const [value, setValue] = useState<string | undefined>();

		const inputRef = useRef<HTMLInputElement>(null);
		useImperativeHandle(ref, () => inputRef.current!);

		useEffect(() => {
			if (!places || !inputRef.current) return;
			const options = {
				componentRestrictions: { country: "gb" },
				types: ["geocode"],
				fields: ["formatted_address"],
			};
			setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
		}, [places]);

		useEffect(() => {
			setValue(defaultValue ?? "");
		}, [defaultValue]);

		useEffect(() => {
			if (!placeAutocomplete) return;
			inputRef.current?.addEventListener("keydown", (e) => {
				if (e.key === "Enter") e.preventDefault(); // Prevent form submission when keyboard user selects autocomplete suggestion
			});
		}, [placeAutocomplete]);

		return (
			<TextInput
				autoComplete="off"
				label={label}
				fieldType={FieldType.Text}
				required={required}
				disabled={disabled || typeof value === undefined}
				error={error}
				ref={inputRef}
				placeholder={placeholder}
				defaultValue={value}
				{...rest}
			/>
		);
	}
);
