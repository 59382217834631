import { HeroHomeProps, ContentBoxPosition } from "./hero-homepage.types";
import classNames from "classnames";

export const HeroClasses = ({ contentBox }: HeroHomeProps): string => {
	switch (contentBox as ContentBoxPosition) {
		case ContentBoxPosition.Left:
			return classNames("justify-start");
		case ContentBoxPosition.Right:
			return classNames("justify-end");
	}
};
