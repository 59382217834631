"use client";
import React from "react";
import { Link } from "../../atoms/link/link";
import { MapStatic } from "../map-static/map-static";
import { RichText } from "../../atoms/rich-text/rich-text";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { useDeviceInfo } from "../../../hooks/use-device-info";
import { type ShopContactDetailsProps } from "./shop-contact-details.types";

export function ShopContactDetails({
	address,
	mapAddress,
	phoneNumber,
	emailAddress,
	facebookLink,
	shopContactDetailsLabel,
	addressLabel,
	phoneNumberLabel,
	emailAddressLabel,
	facebookLinkLabel,
	socialMediaLabel,
}: ShopContactDetailsProps): JSX.Element {
	const isPhoneNumberValid = phoneNumber && /^[0-9\s]+$/.test(phoneNumber); // only allow numbers and spaces
	const phoneNumberWithoutSpaces = phoneNumber.replace(/\s/g, ""); // remove spaces so it can be used in a tel link
	const isEmailAddressValid =
		emailAddress &&
		/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailAddress); // basic email validation

	const deviceInfo = useDeviceInfo();

	let imageSize = "532x299"; // default size lg

	if (deviceInfo.width >= 1024) {
		imageSize = "532x299"; // lg 16:9
	} else if (deviceInfo.width >= 768) {
		imageSize = "464x348"; // md 4:3
	} else if (deviceInfo.width >= 450) {
		imageSize = "735x490"; // sm 3:2
	} else if (deviceInfo.width < 450) {
		imageSize = "417x278"; // xs 3:2
	}

	const [imageWidth, imageHeight] = imageSize
		.split("x")
		.map((dim) => parseInt(dim, 10));

	return (
		<div className="w-full max-content mx-auto flex flex-col md:flex-row ">
			<div className="mx-4 mb-8 basis-1/2 lg:ml-0">
				<Heading level={HeadingLevel.H2}>{shopContactDetailsLabel}</Heading>
				<div className="font-bold mt-4">
					<PlainText
						size={PlainTextSize.Default}
					>{`${addressLabel}:`}</PlainText>
				</div>
				<address className="md:text-base not-italic">
					<bdi className="brc-no-rich-text-spacing brc-no-child-p-margins rtl:text-right">
						<RichText>{address}</RichText>
					</bdi>

					<div className="font-bold mt-4">
						<PlainText
							size={PlainTextSize.Default}
						>{`${phoneNumberLabel}:`}</PlainText>
					</div>
					{isPhoneNumberValid ? (
						<bdi>
							<Link destination={`tel:${phoneNumberWithoutSpaces}`}>
								{phoneNumber}
							</Link>
						</bdi>
					) : null}
					<div className="font-bold mt-4">
						<PlainText
							size={PlainTextSize.Default}
						>{`${emailAddressLabel}:`}</PlainText>
					</div>
					{isEmailAddressValid ? (
						<Link destination={`mailto:${emailAddress}`}>{emailAddress}</Link>
					) : null}

					{facebookLink ? (
						<>
							<div className="font-bold mt-4">
								<PlainText size={PlainTextSize.Default}>
									{`${socialMediaLabel}:`}
								</PlainText>
							</div>
							<p>
								<Link destination={facebookLink}>{facebookLinkLabel}</Link>
							</p>
						</>
					) : null}
				</address>
			</div>
			<div className="basis-1/2">
				<MapStatic
					address={mapAddress}
					mapWidth={imageWidth}
					mapHeight={imageHeight}
				/>
			</div>
		</div>
	);
}
