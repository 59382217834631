import React from "react";
import { ResultsListItem } from "../../molecules/results-list-item/results-list-item";
import { type ResultsListProps } from "./results-list.types";

export function ResultsList({
	resultsListItems,
}: ResultsListProps): React.JSX.Element {
	return (
		<ul className="mb-8 md:mb-12 lg:mb-16">
			{resultsListItems.length > 0
				? resultsListItems.map((item, index) => (
						<ResultsListItem
							key={`${item.url}-${index}`}
							date={item.date}
							url={item.url}
							title={item.title}
							description={item.description}
						/>
					))
				: null}
		</ul>
	);
}
