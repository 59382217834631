"use client";
import React, { useEffect, useState } from "react";
import { JotformEmbed } from "nextjs-jotform-embed";
import { type JotformProps } from "./jotform.types";

export function Jotform({ formId }: JotformProps) {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return isLoaded ? (
		<JotformEmbed src={`https://redcross.jotform.com/${formId.toString()}`} />
	) : null;
}
