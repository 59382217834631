import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type HeroHomeProps } from "./hero-homepage.types";
import { HeroClasses } from "./hero-homepage.style";

export function HeroHomepage({
	title,
	text,
	link,
	imageSm,
	imageMd,
	imageLg,
	contentBox,
}: HeroHomeProps) {
	const classes = HeroClasses({
		title,
		text,
		link,
		imageSm,
		imageMd,
		imageLg,
		contentBox,
	});

	return (
		<section className="brc-hero bg-red-primary mb-12 lg:mb-16">
			<div className="relative lg:h-[calc(100vh-145px)] w-full truncate">
				{/* Small screen size uses the mobile (Sm 4:3) image if one is not provided it uses the desktop (Lg) image */}
				<div className="block w-full h-auto prose-img:aspect-[4/3] prose-img:object-cover md:hidden">
					{imageSm ? imageSm : imageLg}
				</div>

				{/* Medium screen size uses the tablet (Md 16:9) image if one is not provided it uses the desktop (Lg) image */}
				<div className="hidden w-full h-auto prose-img:aspect-video prose-img:object-cover md:block lg:hidden">
					{imageMd ? imageMd : imageLg}
				</div>
				{/* Large screen uses the desktop (Lg 16:9) image on a CSS background */}
				<div
					className="hidden bg-no-repeat bg-center bg-cover w-full h-full lg:block"
					style={{ backgroundImage: `url(${imageLg.props.src})` }}
				/>
				<div className="top-0 left-0 w-full h-full lg:absolute">
					<div
						className={`${
							classes ? classes : "justify-start"
						} flex items-start relative top-0 left-0 w-full h-full lg:items-center lg:mx-auto lg:my-0 max-w-[1600px] `}
					>
						<div className="flex justify-center flex-wrap w-full whitespace-normal pt-6 px-4 pb-12 text-center text-white bg-red-primary prose-p:text-white prose-p:mb-4 prose-h1:text-white prose-h1:mb-4 md:px-6 md:py-16 md:block md:justify-end md:flex-nowrap md:text-left lg:max-w-[640px] lg:pt-16 lg:pb-16 lg:px-[60px] rtl:text-right">
							<Heading level={HeadingLevel.H1}>{title}</Heading>
							<PlainText size={PlainTextSize.Large}>{text}</PlainText>
							<div className="prose-a:mx-auto prose-a:md:mx-0">{link}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
