import React from "react";
import { type CardBasicProps } from "./card-basic.types";

export function CardBasic({ title, text, destination, image }: CardBasicProps) {
	return (
		<div className="group relative bg-white shadow-lg transition ease-in-out delay-150 duration-300 hover:scale-105 hover:shadow-xl focus:shadow-xl rtl:text-right">
			<div className="w-full prose-img:w-full prose-img:aspect-[43/30] prose-img:object-cover">
				{image}
			</div>
			<div className="w-full px-6 pt-6 pb-8 prose-h3:mb-4">
				<h3>
					<a className="no-underline" href={destination}>
						<span aria-hidden="true" className="absolute inset-0" />
						{title}
					</a>
				</h3>

				<div className="prose-p:line-clamp-4">{text}</div>
			</div>
		</div>
	);
}

export function CardStandalone({
	title,
	text,
	destination,
	image,
	contentBox,
}: CardBasicProps) {
	// Get image src for background image on mid and larger screens
	const imageSrc = image?.props.src;

	return (
		<div>
			<div className="group block mx-auto my-0 max-content relative w-full bg-white shadow-lg transition ease-in-out delay-150 duration-300 hover:scale-105 hover:shadow-xl focus:shadow-xl md:flex">
				{/* Small screen size uses the HTML image */}
				<div className="prose-img:w-full prose-img:aspect-[43/30] prose-img:object-cover block md:hidden">
					{image}
				</div>
				{/* MD & LG screen uses the image on a inline CSS background */}
				<div
					className="hidden bg-no-repeat bg-center bg-cover w-full aspect-video md:block"
					style={{ backgroundImage: `url(${imageSrc})` }}
				/>
				<div className="flex top-0 left-0 w-full h-full md:absolute">
					<div
						className={`${
							contentBox === "Left" ? "justify-start" : "justify-end"
						} flex items-start top-0 left-0 w-full h-auto md:items-center md:mx-auto md:grid md:grid-cols-12 md:gap-6 md:relative `}
					>
						<div
							className={`flex text-left flex-wrap w-full px-6 pb-8 pt-6 text-black bg-white md:px-8 md:pb-8 md:pt-6 ${
								contentBox === "Left"
									? "md:col-start-1 md:col-span-5"
									: "md:col-start-8 md:col-span-5"
							}`}
						>
							<div>
								<h3 className="mb-4">
									<a className="no-underline" href={destination}>
										<span aria-hidden="true" className="absolute inset-0" />
										{title}
									</a>
								</h3>
							</div>
							<div className="whitespace-normal prose-p:line-clamp-5 prose-p:mb-0">
								{text}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
