export const debounce = (fn: () => void, ms = 500): (() => void) => {
	let timer: number | null;
	return () => {
		if (timer !== null) clearTimeout(timer);
		timer = window.setTimeout(() => {
			timer = null;
			fn();
		}, ms);
	};
};
