"use client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { TextInput } from "../../atoms/text-input/text-input";
import { FieldType } from "../../atoms/text-input/text-input.types";
import { Button } from "../../atoms/button/button";
import { ButtonStyle } from "../../atoms/button/button.types";
import { type SearchFormProps } from "./search-form.types";
import { getFilterElements } from "./utils";

export function SearchForm({
	title,
	inputLabel,
	buttonText,
	searchValue,
	filterList,
	onSearch,
	filterElements,
	searchQuery,
}: SearchFormProps): JSX.Element {
	const [filters, setFilters] = useState(filterList || []);

	const isVadSearch = !filterElements || filterElements.length === 0;

	const {
		handleSubmit,
		// formState: { errors, isDirty, isValid },
		register,
		reset,
	} = useForm<Record<string, unknown>>({
		defaultValues: { search: searchValue || "" },
		mode: "onSubmit",
		reValidateMode: "onBlur",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false,
		shouldUseNativeValidation: false,
	});

	useEffect(() => {
		reset({ search: searchValue });

		// Check if The Hero is present to remove margin bottom of the Hero
		function sibling() {
			const ifHero = document.getElementById("brc-search-form");
			if (ifHero) {
				const isHeroSibling = ifHero.previousElementSibling;
				if (isHeroSibling) {
					if (isHeroSibling.classList.contains("brc-hero")) {
						isHeroSibling.classList.add("brc-hero-and-search");
					}
				}
			}
		}
		sibling();
	}, [searchValue, filterList]);

	const onSubmit = (data: Record<string, any>): void => {
		if (isVadSearch) {
			//get filters
			const mappedFilters = filters
				? filters.map((f) => {
						return {
							name: f.name,
							value: f.selectedValue || "",
						};
					})
				: [];
			onSearch(data.search, mappedFilters);
		} else {
			const searchData = {};
			for (const key in data) {
				if (data[key]) searchData[key] = data[key];
			}
			onSearch(searchData);
		}
	};

	const setFilterValue = (name: string, value: string) => {
		const filter = filters.find((f) => f.name === name);

		if (filter) {
			filter.selectedValue = value;

			setFilters([...filters]);
		}
	};

	return (
		<div
			id="brc-search-form"
			className="p-6 pb-8 mb-6 bg-grey-10 md:px-4 md:pt-8 md:pb-12 lg:mb-12 last:mb-0"
		>
			<div className="mb-4 w-full text-center md:mb-8">
				<Heading level={HeadingLevel.H2}>{title}</Heading>
			</div>
			<form
				className="flex flex-col w-full mx-auto md:flex-row md:max-content"
				onSubmit={handleSubmit(onSubmit)}
			>
				{isVadSearch ? (
					<>
						<div className="flex flex-col w-full mr-0 rtl:ml-0 mb-4 md:mr-6 rtl:md:mr-0 rtl:md:ml-6 md:mb-0">
							<TextInput
								{...register("search")}
								label={inputLabel || "Search"}
								fieldType={FieldType.Text}
								required
								error={false}
								disabled={false}
								pattern="^.*\S.*$"
								title="Please enter a search query"
							/>
						</div>

						{filters.map((f) => {
							const selectName = f.name.replace(/\s/g, "");
							return (
								<div
									key={f.name}
									className="w-full relative rtl:md:mr-0 rtl:md:ml-6 md:mr-6"
								>
									<label htmlFor={selectName} className="block text-sm">
										{f.label} *
									</label>
									<select
										className="brc-form-select appearance-none bg-no-repeat w-full rounded-none focus:border-1 focus:border-green-secondary focus:brc-shadow-fieldfocus focus:outline-0 mb-4 h-12 p-y2.5 px-4 text-sm text-center border border-solid border-black-primary md:min-w-max md:text-left"
										onChange={(e) => {
											setFilterValue(f.name, e.target.value);
										}}
										id={selectName}
										aria-label={f.name}
										value={f.selectedValue}
										dir="ltr"
									>
										{f.options.map((o) => {
											return (
												<option
													className="text-xs"
													key={o.value}
													value={o.value}
												>
													{o.title}
												</option>
											);
										})}
									</select>
								</div>
							);
						})}
					</>
				) : (
					<>
						{getFilterElements(filterElements, register, searchQuery).map(
							(filterElement) => {
								return (
									<div
										key={filterElement.key}
										className="flex flex-col w-full mr-0 mb-4 md:mr-6 md:mb-0"
									>
										{filterElement}
									</div>
								);
							}
						)}
					</>
				)}
				<div className="pt-0 md:pt-6">
					<Button buttonStyle={ButtonStyle.Primary} buttonType="submit">
						{buttonText ? buttonText : "Search"}
					</Button>
				</div>
			</form>
		</div>
	);
}
